import React from 'react';
import SidebarNotificationWrapper from "../../../notifications/src/Notifications.web";
import ReviewsController from "../ReviewsController";
import { Avatar, Box, IconButton, styled, TextField, Typography, withStyles } from '@material-ui/core';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CustomStyledButton from '../../../../components/src/CustomStyledButton.web';
import { Star } from '../assets';
import StarIcon from '@material-ui/icons/Star';

export class Review extends ReviewsController {
    render() {
        const { classes } = this.props;
        return (
            <SidebarNotificationWrapper {...this.props}>
                <Box className={classes.container}>
                    <Box className={classes.pageTitleWrapper}>
                        <IconButton data-test-id="backBtn" onClick={() => this.goToBlock('PatientOrderManagement')}>
                            <Box className={classes.backButton}>
                                <ArrowBackIosIcon className={classes.arrowIcon} />
                            </Box>
                        </IconButton>
                        <Typography className={classes.profileHospitalHeading} variant="h6">
                            Write a Review
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box className={classes.profileWrapper}>
                            <Avatar className={classes.avatar} />
                            <Box sx={{width:'100%',overflow:'auto'}}>
                                <Typography className={classes.experienceText}>How was your experience with</Typography>
                                <Typography className={classes.doctorName}>Dr. Torsten Paulsson</Typography>
                                <Box className={classes.starsContainer}>
                                    {Array.from({ length: 5 }, (_, indx) => {
                                        const checkedStar = (
                                            <StarIcon
                                                data-test-id='checked'
                                                className={classes.checkedStar}
                                                onClick={() => this.handleRating(indx + 1)}
                                            />
                                        );
                                        const uncheckedStar = (
                                            <img
                                                src={Star}
                                                data-test-id='unchecked'
                                                alt="start_unchecked"
                                                onClick={() => this.handleRating(indx + 1)}
                                            />
                                        );
                                        return this.state.rating >= indx + 1 ? checkedStar : uncheckedStar;
                                    })}
                                </Box>
                            </Box>
                        </Box>

                        <Typography className={classes.commentTitle}>Write a comment</Typography>
                        <StyledInputField
                            multiline
                            className={classes.inputField}
                            variant="outlined"
                            minRows={7}
                            inputProps={{ maxLength: 250 }}
                            placeholder="Tell people about your experience"
                            helperText={"Max. 250 words"}
                        />
                        <CustomStyledButton style={webStyle.submitButton} label="Submit a Review" />
                    </Box>
                </Box>
            </SidebarNotificationWrapper>
        );
    }
}




const StyledInputField = styled(TextField)({
    fontFamily: "SF Pro Text Regular",
    border: "none",
    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
        borderRadius: "8px",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
        borderRadius: "8px",
        fontFamily: "SF Pro Text Regular",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
        border: "none",
        borderRadius: "8px",
        paddingLeft: "25px",
        paddingTop: '25px',
        fontFamily: "SF Pro Text Regular",
        backgroundColor: '#F4F4F4'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: "none",
    },
    "& .MuiFormHelperText-root": {
        marginLeft: 'auto',
        color: '#717171',
        fontSize: '14px',
        fontFamily: "SF Pro Text Regular",
        marginRight: '0',
        marginTop: '12px'
    },
    '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '25px'
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
        paddingLeft: '25px'
    },
    "& .MuiInputBase-input::placeholder": {
        color: "#808080",
        fontSize: "16px",
        opacity: 1,
        fontFamily: 'SF Pro Text Regular',
    },
    "& .MuiInputBase-input.Mui-disabled": {
        cursor: 'not-allowed',
    }
});

const webStyle = {
    profileWrapper: {
        display: 'flex',
        alignItems: 'center',
        gridColumnGap: '24px',
        marginBottom: '80px',
        '@media(max-width:600px)': {
            marginBottom: '50px'
        }
    },
    pageTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
        gridColumnGap: '30px',
        marginBottom: '50px'
    },
    arrowIcon: {
        height: "16px",
        color: '#000000'
    },
    hospitalEditBackButtons: {
        display: "flex",
        alignItems: "center",
        width: "50px",
        height: "50px",
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        border: "1px solid #E9E9E9",
        padding: "10px",
        justifyContent: "center",
        borderRadius: "8px",
        boxSizing: 'border-box' as const
    },
    profileHospitalHeading: {
        width: "100%",
        fontWeight: 600,
        fontSize: "28px",
        fontFamily: "SF Pro Text Bold",
        caretColor: "transparent",
        '@media(max-width:600px)': {
            fontSize: '18px'
        }
    },
    container: {
        padding: '48px 60px',
        '@media(max-width:600px)': {
            padding: '24px'
        }
    },
    avatar: {
        width: '138px',
        height: '138px',
        '@media(max-width:600px)': {
            width: "85px",
            height: '85px'
        }
    },
    experienceText: {
        fontFamily: 'SF Pro Text Regular',
        fontSize: '22px',
        lineHeight: '30px',
        color: '#292929',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as const,
        '&:hover': {
            whiteSpace: 'normal',
            overflow: 'visible'
        },
        '@media(max-width:600px)': {
            fontSize: '18px'
        }
    },
    doctorName: {
        fontFamily: 'SF Pro Text Semibold',
        fontSize: '22px',
        lineHeight: '30px',
        color: '#292929',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as const,
        '&:hover': {
            whiteSpace: 'normal',
            overflow: 'visible'
        },
        '@media(max-width:600px)': {
            fontSize: '20px'
        }
    },
    starsContainer: {
        marginTop: '12px',
        width: '100%',
        display: 'flex',
        gridColumnGap: '24px',
        alignItems: 'center',
        '@media(max-width:600px)': {
            gridColumnGap: '12px',
        }
    },
    commentTitle: {
        color: '#292929',
        fontSize: '20px',
        fontFamily: 'SF Pro Text Medium',
        marginBottom: '20px',
        '@media(max-width:600px)':{
            fontSize:"16px"
        }
    },
    inputField: {
        maxWidth: '710px',
        marginBottom: '150px',
        '@media(max-width:600px)':{
            marginBottom:'70px'
        }
    },
    submitButton: {
        height: '56px',
        width: '220px'
    },
    checkedStar: {
        color: '#ffb400',
        height: '34px',
        width: '34px'
    }
}

export default withStyles(webStyle)(Review);