import * as React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core";
import CustomisableUserProfilesController, {
    Props
} from "./CustomisableUserProfilesController";

import { styles } from "./styles/CustomisableUserProfiles";
import { PatientHealthDetails } from "../../email-account-registration/src/PatientHealthDetails.web";
// Customizable Area End

export class CustomisableHealthDetailsUserProfiles extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End 
    }

    // Customizable Area Start
    async componentDidMount() {
        await this.fetchDiseasesDetails();
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End 
        return (
            // Customizable Area Start
            <>
               <PatientHealthDetails classes={this.props.classes} navigation={this.props.navigation} step={2} id={""} google={undefined} diseasesList={this.state.diseasesList} userLogin={true} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(CustomisableHealthDetailsUserProfiles);
// Customizable Area End