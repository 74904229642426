import * as React from "react";
// Customizable Area Start
import { Box, Grid, Typography, withStyles, Button } from "@material-ui/core";
import HpOrderManagementController, { Props } from "../HpOrderManagementController";
import SidebarNotificationWrapper from  '../../../notifications/src/Notifications.web'
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CommonMessage from "../../../../components/src/CommonMessage";
// Customizable Area End

export class OrderManagementPrescription extends HpOrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getPrescription()
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {classes} = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
        <>
            <Box>
                <Grid container className={classes.leftHPWrapper}>
                   <SidebarNotificationWrapper {...this.props} hideNotification >
                        <Box className={classes.righHPtWrapper}>
                            <Box>
                                <Box className={classes.headerHPContainer}>
                                    <Box className={classes.orderHeading}>
                                        <ArrowBackIosIcon
                                            className={classes.calendarHPBackBtn}
                                            data-test-id="backToPreviousScreenfromPrecription"
                                            onClick={() => this.backToPrevPage()}
                                        />
                                        <Typography variant="h4" className={classes.profileHPHeading}>Prescriptions</Typography>
                                    </Box>
                                </Box>
                                {this.state.prescriptions !== null && this.state.prescriptions.length > 0 ? (
                                    this.state.prescriptions.map((data: any, index: number) => (
                                        <Box className={classes.docList} key={index}>
                                            <Box className={classes.container}>
                                                
                                                <Box className={classes.blockOne}>
                                                <img
                                                    alt="Profile"
                                                    className={classes.avatar}
                                                    src={this.state.profileUrl}
                                                />
                                                </Box>
                                                <Box style={{width:"100%"}}>
                                                    <Box style={{width:"100%",display:'flex',height:"85px",justifyContent:'space-between'}}>
                                                        <Box className={classes.blockTwo}>
                                                            <Typography variant="subtitle1" className={classes.userName}>
                                                                {data.attributes.healthcare_personnel_name}
                                                            </Typography>
                                                        </Box>

                                                        <Box className={classes.blockThree}>
                                                            <Typography variant="subtitle1" className={classes.day}>
                                                                {this.getDayOfWeek(data.attributes.date)}
                                                            </Typography>
                                                            <Typography variant="caption" className={classes.time}>
                                                                {data.attributes.time}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Button
                                                        data-test-id="openPrescriptionPrevirew"
                                                        variant="outlined"
                                                        className={classes.button}
                                                        onClick={() => this.callPrescription(data)}
                                                    >
                                                        Open File
                                                    </Button>
                                                </Box>
                                            </Box>
                                            
                                        </Box>
                                    ))
                                ) : (
                                    <Typography variant="body1" className={classes.noData}>
                                        No prescriptions available.
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <CommonMessage 
                            open={this.state.openMessage}
                            onClose={this.handleCloseMessage}
                            type={this.state.type} 
                            title={this.state.messageTitle}
                            subTitle={this.state.messageSubTitle}
                        />
                    </SidebarNotificationWrapper>
                </Grid>
            </Box>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
    leftHPWrapper: {
        overflowX: "hidden" as "hidden",
        height: "100%",
    },
    righHPtWrapper: {
        padding:"50px",
        overflowY: "scroll" as "scroll",
        scrollbarColor: "#fff #fff",
        scrollbarWidth: "thin" as "thin",
        "& ::-webkit-scrollbar-button": {
            display: "none"
        },
        '@media(max-width:600px)':{
            padding:'24px'
        }
    },
    headerHPContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    orderHeading: {
        display: "flex",
        alignItems: "center",
        marginBottom:"40px",
        justifyContent: "flex-start",
        gap: "25px",
    },
    calendarHPBackBtn: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        padding: "13px 9px 13px 17px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
        '@media(max-width:600px)':{
            padding:'11px 6px 10px 15px'
        }
    },
    profileHPHeading: {
        width: "100%",
        caretColor: "transparent",
        fontSize: "28px",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
        '@media(max-width:600px)':{
            fontSize:'18px'
        }
    },
    docList:{
        marginTop: "12px",
        borderBottom: "1px solid #e8e7e7",
        borderRadius: "10px",
        padding: "20px",
        maxWidth: "600px",
        height: "135px",
        marginBottom: "10px"
    },
    container: {
        display: "flex",
        gap:'24px'
    },
    blockOne: {
        display: "block",
        
    },
    blockTwo: {
        display: "block",
        
    },
    blockThree: {
        display: "block",
        float: "right",
        textAlign: "right",
        
    } as any,
    userName: {
        fontWeight: 600,
        fontSize: "16px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Regular",
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap' as const,
        '&:hover':{
            whiteSpace:'normal',
            overflow:'visible'
        },
    },
    day: {
        fontWeight: 600,
        fontSize: "12px",
        fontFamily: "SF Pro Text Regular",
        color: "#014866",
        caretColor: "transparent",
    },
    time: {
        caretColor: "transparent",
        fontSize: "12px",
        fontFamily: "SF Pro Text Regular",
        color: "#AC8E68"
    },
    date: {
        width: "75%",
        display: "flex",
        justifyContent: "center",
        fontWeight: 600,
        fontSize: "15px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Regular",
        color: "#e3e3e3"
    },
    button: {
        color: "#2196f3",
        border: "1px solid #2196f3",
        padding: "8px 30px",
        borderRadius: "8px",
        textTransform: "capitalize" as "capitalize",
        fontWeight: 600,
        fontSize: "14px"
    },
    avatar: {
        width: "80px",
        height: "80px",
        borderRadius: "50%"
    },
}

export default withStyles(styles)(OrderManagementPrescription);
// Customizable Area End