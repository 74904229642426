import React from 'react'
import CustomisableUserProfilesController, { configJSON } from "./CustomisableUserProfilesController";
import HospitalProfileWrapper from "./HospitalProfileWrapper.web";
import { Box, Grid, withStyles } from '@material-ui/core';
import CustomInputField from '../../../components/src/CustomInputField.web';
import CustomStyledButton from '../../../components/src/CustomStyledButton.web';
import CommonModal from '../../../components/src/CommonModal.web';
import { receiveMail } from './assets';
import Loader from '../../../components/src/Loader.web';
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";
import CommonMessage from '../../../components/src/CommonMessage';

export class HelpCenter extends CustomisableUserProfilesController {
    async componentDidMount() {
        await this.fetchHospitalProfileDatas();
        await this.fetchUserProfileDatas();
        await this.fetchDoctorDetails();
    }
    render() {
        const { classes } = this.props;
        return (
            <Box sx={webStyle.container}>
                <SidebarNotificationWrapper hideNotification {...this.props} >
                    <div style={webStyle.rightWrapper}>
                        <HospitalProfileWrapper
                            {...this.props}
                            data-test-id='profile-wrapper'
                            profileBackBtn
                            profilePageTitle={configJSON.PageTitle.HelpCenter}
                        >
                            <div className={classes.contentWrapper}>
                                <p className={classes.headerText}>HomeCure</p>
                                <p className={classes.subHeaderText}>
                                    You can contact us on toll free number or on Email!
                                </p>
                                <Grid container className={classes.contactInfoWrapper}>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <CustomInputField
                                            fullWidth
                                            disabled
                                            value={this.state.phoneNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <CustomInputField
                                            fullWidth
                                            disabled
                                            value={this.state.email}
                                        />
                                    </Grid>
                                </Grid>
                                <p className={classes.contactUsText}>Contact Us</p>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <CustomInputField
                                            fullWidth
                                            disabled
                                            value={this.state.user_name}
                                        />
                                    </Grid>
                                </Grid>
                                <CustomInputField
                                    className={classes.messageField}
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    data-test-id='description'
                                    inputProps={{
                                        maxLength: 300
                                    }}
                                    onChange={this.handleChangeDescription}
                                    helperText={this.state.helpCenterDescriptionError}
                                    error={Boolean(this.state.helpCenterDescriptionError)}
                                    value={this.state.helpCenterDescription}
                                    placeholder="Write your message…"
                                />
                                <CustomStyledButton disabled={this.state.disableHelpCenterSubmit} data-test-id='submit' style={{ width: '250px' }} onClick={this.handleSubmitHelp} label="Submit" />
                            </div>
                            <Loader loading={this.state.isLoading} />
                        </HospitalProfileWrapper>
                    </div>
                     <CommonMessage 
                        data-test-id="message"
                        onClose={this.handleCloseMessage}
                        type={this.state.messageType} 
                        open={this.state.openMessage}
                        title={this.state.messageTitle}
                        subTitle={this.state.messageSubTitle}
                        />
                </SidebarNotificationWrapper>
                <CommonModal
                    data-test-id='modal'
                    open={this.state.openHelpCenterModal}
                    dialogStyle={webStyle.modalDialog}
                    classNameContent={classes.contentBox}
                    onClose={this.closeMailSentModal}
                >
                    <div className={classes.modalContent}>
                        <img src={receiveMail} className={classes.modalImage} alt="mail" />
                        <p className={classes.modalText}>
                            We have received your request
                        </p>
                        <CustomStyledButton
                            data-test-id='close-modal'
                            onClick={this.closeMailSentModal}
                            style={webStyle.modalButton}
                            label="Continue to Website"
                        />
                    </div>
                </CommonModal>
            </Box>
        );
    }
}

const webStyle = {
    contentBox: {
        '&.MuiDialogContent-root': {
            padding: '38px 53px',
            '@media(max-width:600px)': {
                padding: '24px'
            }
        }
    },
    container: {
        height: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: "100vh",
        overflowX: "hidden" as const,
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%',
    },
    contentWrapper: {
        padding: '94px 60px 72px 60px',
        maxWidth: '55%',
        '@media(max-width:991px)': {
            padding: '60px 48px 60px',
            maxWidth: 'none'
        },
        '@media(max-width:600px)': {
            padding: '24px'
        }
    },
    headerText: {
        color: '#014866',
        fontFamily: 'SF Pro Text Heavy',
        fontSize: '30px',
        lineHeight: '35px',
        marginBottom: '32px',
        marginTop: 0,
        '@media(max-width:600px)': {
            fontSize: '22px',
            marginBottom: '12px'
        }
    },
    subHeaderText: {
        color: '#292929',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Semibold',
        marginBottom: '20px',
        marginTop: 0,
        '@media(max-width:600px)': {
            fontSize: '15px'
        }
    },
    contactInfoWrapper: {
        display: 'flex',
        marginBottom: '40px',
        flexWrap: 'nowrap' as const,
        gap: '40px',
        '@media(max-width:600px)': {
            flexWrap: 'wrap' as const,
            gap: '20px',
            marginBottom: '20px'
        }
    },
    contactUsText: {
        fontFamily: 'SF Pro Text Bold',
        fontSize: '20px',
        color: '#292929',
        marginBottom: '20px',
        marginTop: '0',
        '@media(max-width:600px)': {
            fontSize: '17px'
        }
    },
    messageField: {
        '&.MuiFormControl-root': {
            marginBottom: '90px',
            marginTop: '20px',
            '@media(max-width:600px)': {
                marginBottom: '40px'
            }
        }
    },
    modalDialog: {
        borderRadius: '25px',
        backgroundColor: '#F2F1FA',
        maxWidth: '450px',
        width: '100%',
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
    },
    modalImage: {
        marginBottom: '30px',
        '@media(max-width:600px)': {
            width: '90px'
        }
    },
    modalText: {
        color: '#292929',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Bold',
        marginBottom: '50px',
        marginTop: '0',
        '@media(max-width:600px)': {
            fontSize: "16px",
            marginBottom:'25px'
        }
    },
    modalButton: {
        width: '100%',
    },
};

export default withStyles(webStyle)(HelpCenter);

