import * as React from "react";
// Customizable Area Start
import { Box, Typography, Card, CardContent, Button, withStyles } from "@material-ui/core";
import { homecureTransparent, physicianMedical, Account } from "../assets";
import CommoncontactusController, { Props } from "../CommoncontactusController";
// Customizable Area End

export class ContactUsSuccess extends CommoncontactusController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End

        return (
            // Customizable Area Start
            <Box
                component="div"
                sx={{
                    width: "100%", minHeight: "100vh", height: "100%",
                }}
                style={{
                    backgroundImage: `url(${physicianMedical})`,backgroundPosition: "top", backgroundSize: "cover", backgroundRepeat: "no-repeat",
                }}
            >
                <Box className={classes.homeCureContainer}>
                    <img src={homecureTransparent} alt='Logo'className={classes.homecureLogo} style={{ objectFit: 'contain' }}/>
                </Box>
                <Box>
                    <Card className={classes.transparentCard}>
                        <CardContent className={classes.cardContent} style={{ flexDirection: "column" }}>
                            <img src={Account} alt='registrationSuccess' className={classes.emailVerifiedImage} style={{ objectFit: 'contain' }} />
                            <Typography variant="h6" className={classes.message} style={{ textAlign: "center" }}>
                                Thank you for reaching out! We’ll respond to your query as soon as possible.
                            </Typography>
                            <Button
                                data-test-id="backToDefault"
                                style={{ textTransform: "capitalize" }}
                                variant="contained"
                                color="primary"
                                className={classes.contactUsSuccessBtns}
                                onClick={() => this.moveToNextScreen()}
                                >
                                Done
                            </Button>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    homeCureContainer: {
        display: "flex",
        alignItems: "center",
        caretColor: "transparent",
        margin: "20px"
    },
    homecureLogo: {
        width: 180,
        margin: 20,
        caretColor: "transparent",
        "@media (max-width:960px)": {
            width: 180,
            margin: 10
        },
    },
    transparentCard: {
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
        width: "350px",
        margin: "50px 120px",
        borderRadius: "25px",
        padding: "25px 35px",
        "@media (max-width:960px)": {
            width: "350px !important",
            margin: "100px auto",
            padding: "30px"
        },
        "@media (max-width:600px)": {
            width: "300px !important",
            margin: "80px auto",
            padding: "15px"
        },
    },
    cardContent: {
        display: "flex",
        padding:'0'
    },
    emailVerifiedImage: {
        width: "150px",
        margin: "auto",
        '@media(max-width:600px)':{
          width:'100px'
        }
    },
    message: {
        fontSize: "18px",
        color: "#292929",
        margin: "30px auto",
        fontWeight: 700,
        caretColor: "transparent",
        '@media(max-width:600px)': {
            fontSize: '16px'
        }
    },
    contactUsSuccessBtns: {
        width: "100%",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        borderRadius: "10px",
        padding: "16px",
        cursor: "pointer",
        fontFamily:"SF Pro Text Bold",
        fontSize: "18px",
        margin: "0px auto",
        "&:hover": {
          backgroundColor: "#014866",
          color: "#fff"
        }
      },
}

export default withStyles(styles)(ContactUsSuccess);
// Customizable Area End
