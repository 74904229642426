import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, Dialog, Button } from "@material-ui/core";
import VideoCallManagementController, { Props } from "./VideoCallManagementController";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SidebarNotificationWrapper from "../../../notifications/src/Notifications.web";
import { videoImg, micIcon, phoneIcon, soundIcon, zoomIcon, refreshIcon } from "../assets";

// Customizable Area End

export class VideoCall extends VideoCallManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box className={classes.videoCallWrapper}>
                    <SidebarNotificationWrapper {...this.props}>
                        <Box className={classes.wrapperContainer}>
                            <Box className={classes.root}>
                                <Box className={classes.headerContainer}>
                                    <Box className={classes.iconWrapper}>
                                        <ArrowBackIosIcon
                                            className={classes.backBtn} />
                                        <Typography variant="h4" className={classes.heading}>Dr. Brijamohan Mallick</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Typography variant="h4" className={classes.timing}>25:14</Typography>
                            <Box className={classes.container}>
                                <Box>
                                    <img src={videoImg} className={classes.callContainer} />
                                </Box>
                                <Box className={classes.btnContainer}>
                                    <img src={micIcon} className={classes.iconSize} />
                                    <img src={soundIcon} className={classes.iconSize} />
                                    <img src={phoneIcon} className={classes.iconSize} onClick={this.showHidePopup} />
                                    <img src={refreshIcon} className={classes.iconSize} />
                                    <img src={zoomIcon} className={classes.iconSize} />
                                </Box>
                            </Box>
                        </Box>
                    </SidebarNotificationWrapper>
                </Box>
                <Dialog open={!!this.state.showPopup} data-test-id="hidePopup" onClose={this.showHidePopup} className={classes.dialogBoxNew}>
                    <Box>
                        <Box className={classes.modalWrapper}>
                            <p className={classes.modalText}>Prescription will be uploaded by the doctor in sometime. Check your prescription section after 10 minutes for your prescription.</p>
                            <Button style={{
                                display: "flex",
                                borderRadius: "8px",
                                justifyContent: "center",
                                width: "100%",
                                height: "56px",
                                backgroundColor: "#014866",
                                color: "#fff",
                                border: "none",
                                padding: "16px",
                                cursor: "pointer",
                                fontFamily: "SF Pro Text Bold",
                                fontSize: "18px",
                                margin: "40px auto 15px",
                                textTransform: "capitalize" as "capitalize"
                            }}
                                data-test-id="selectConsultationType"
                                onClick={this.showCallEndPopup}>Okay</Button>
                        </Box>
                    </Box>
                </Dialog>

                <Dialog open={!!this.state.callEndPopup} data-test-id="hidePopup" onClose={this.showCallEndPopup} className={classes.dialogBoxNew}>
                    <Box>
                        <Box className={classes.modalSecondWrapper}>
                            <p className={classes.modalHeading}>Appointment End</p>
                            <img className={classes.avatarEditWrapper} src={videoImg} />
                            <Typography variant="h4" className={classes.userName}>Dr. Brijamohan Mallick</Typography>
                            <p className={classes.text}>Physiotherapy</p>
                            <Button style={{
                                display: "flex",
                                borderRadius: "8px",
                                justifyContent: "center",
                                width: "100%",
                                height: "56px",
                                backgroundColor: "#014866",
                                color: "#fff",
                                border: "none",
                                padding: "16px",
                                cursor: "pointer",
                                fontFamily: "SF Pro Text Bold",
                                fontSize: "18px",
                                margin: "40px auto 15px",
                                textTransform: "capitalize" as "capitalize"
                            }}
                                data-test-id="selectConsultationType"
                                onClick={this.redirectToReview}>Write a Review</Button>
                        </Box>
                    </Box>
                </Dialog>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    videoCallWrapper: {
        height: "100%",
        overflowX: "hidden" as "hidden"
    },
    wrapperContainer: {
        overflowY: "scroll" as "scroll",
        scrollbarColor: "#d9d5d5 #fff",
        scrollbarWidth: "thin" as "thin",
        "& ::-webkit-scrollbar-button": {
            display: "none"
        },
        padding: "50px",
        '@media(max-width:600px)': {
            padding: '24px'
        }
    },
    heading: {
        width: "100%",
        fontSize: "28px",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
        caretColor: "transparent",
        '@media(max-width:600px)': {
            fontSize: '18px'
        }
    },
    headerContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    iconWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "25px",
        marginBottom: "20px"
    },
    backBtn: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
        padding: "13px 9px 13px 17px",
    },
    btnContainer: {
        display: "flex",
        gap: "25px",
        justifyContent: "center",
        marginTop: "25px",
        maxWidth: "100%",
        '@media(max-width:959px)': {
            maxWidth: "100%",
            width: "100%",
        }
    },
    iconSize: {
        width: "70px",
        height: "70px",
        '@media(max-width:650px)': {
            width: "50px",
            height: "50px",
        }
    },
    callContainer: {
        maxWidth: "100%",
        width: "100%",
        maxHeight: "60%",
        '@media(max-width:959px)': {
            maxWidth: "100%",
            width: "100%",
        }
    },
    timing: {
        fontFamily: "SF Pro Text Regular",
        fontSize: "16px",
        color: "#292929",
        marginBottom: "20px"
    },
    container: {
        width: "75%",
        '@media(max-width:1250px)': {
            width: "100%"
        }
    },
    modalWrapper: {
        padding: "65px 45px",
        maxWidth: "385px",
        '@media(max-width:600px)': {
            padding: '24px'
        }
    },
    text: {
        fontSize: "18px",
        color: "#808080",
        margin: "auto auto 60px",
        textAlign: "center" as "center",
        lineHeight: "32px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Medium",
        '@media(max-width:600px)': {
            fontSize: '18px'
        }
    },
    modalText: {
        fontSize: "20px",
        color: "#292929",
        margin: "auto auto 60px",
        textAlign: "center" as "center",
        lineHeight: "32px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Medium",
        '@media(max-width:600px)': {
            fontSize: '18px'
        }
    },
    dialogBoxNew: {
        "& .MuiDialog-paperWidthSm": {
            borderRadius: "25px !important"
        }
    },
    modalSecondWrapper: {
        padding: "35px 45px",
        maxWidth: "350px",
        width: "350px",
        '@media(max-width:600px)': {
            padding: '24px'
        }
    },
    userName: {
        width: "100%",
        fontSize: "20px",
        margin: "auto",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
        textAlign: "center" as "center",
        caretColor: "transparent",
        '@media(max-width:600px)': {
            fontSize: '18px'
        }
    },
    avatarEditWrapper: {
        borderRadius: "50%",
        width: "170px",
        height: "170px",
        border: "1px solid #eeeaea",
        backgroundColor: "#eeeaea",
        zIndex: 1,
        cursor: "pointer",
        margin: "0px auto 25px",
        display: "block",
        '@media(max-width:991px)': {
            width: '150px',
            height: '150px'
        },
    },
    modalHeading: {
        fontSize: "20px",
        color: "#292929",
        margin: "auto auto 30px",
        textAlign: "center" as "center",
        lineHeight: "32px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Medium",
        '@media(max-width:600px)': {
            fontSize: '18px'
        }
    },
}

export default withStyles(styles)(VideoCall);
// Customizable Area End