import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config");
import { Message } from "../../../../framework/src/Message";

export interface Props {
    navigation: any;
    id: string;
    classes: any;
}

interface S {
    showPopup:boolean;
    callEndPopup:boolean;
}

interface SS {
    id: number;
}

export default class VideoCallManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
      ];
      this.state = {
        showPopup:false,
        callEndPopup:false,
      };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    showHidePopup = () => {
        this.setState({showPopup:!this.state.showPopup})
    }

    showCallEndPopup = () => {
        this.setState({callEndPopup:!this.state.callEndPopup,showPopup:false})
    }

    redirectToReview = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), 'Review');
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    }
}