import React from "react";
// Customizable Area Start
import Reservation2Controller, { configJSON, filters,Props } from "./Reservations2Controller";

import {
  Box,
  createTheme,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
  Button,
  Chip,
  withStyles,
  CardContent,
  ClickAwayListener,
  TableCell,
  Checkbox,
  TableRow,
  SvgIcon,
} from "@material-ui/core";
import { searchIcons, NoData, DownArrow, UserProfile, filterIcons, docBanner, medic } from "./assets";
import { PositionValue, floatType, objectFitType, textTransform, FlexDirectionProperty } from "./types";
import '../../../web/src/fonts/stylesheet.css';
import StarIcon from '@material-ui/icons/Star';
import Filter from "../../filteritems/src/Filter.web"
import { getStorageData } from "../../../framework/src/Utilities";
import CloseIcon from '@material-ui/icons/Close';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";
import CommonMessage from "../../../components/src/CommonMessage";
import CustomStyledButton from "../../../components/src/CustomStyledButton.web";

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    }
  }
});

const DoctorChecked = () => (<SvgIcon viewBox="0 0 18 18" fill="none" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" rx="1.5" width="15" height="15" stroke="#014866" /><path  d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></SvgIcon>);

const UnDoctorChecked = () => (<SvgIcon fill="none" width="16" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="15" height="15" rx="2.2" strokeWidth={'1.5'} opacity={'0.3'} stroke="#014866" /></SvgIcon>);

// Customizable Area End
export class Reservations2DoctorBooking extends Reservation2Controller {
  scrollContainerRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.scrollContainerRef = React.createRef();
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidUpdate(prevProps: Props, prevState: any) {
    const { currentPage } = this.state;
    if (prevState.currentPage !== currentPage) {
        try {
          if (!this.state.isDataEmpty && !this.state.loading) { await this.getDoctorList(); }
        } catch (error) {
          this.handleShowMessage('error',"Error fetching hospital data:", "Something went wrong")
        }
    }
  }

  onScroll = () => {
    const element = this.scrollContainerRef.current;
    if (element && !this.state.loading) {
      const { scrollTop, scrollHeight, clientHeight } = element;
      if (scrollTop > this.state.prevScrollTop && scrollTop + clientHeight >= scrollHeight - configJSON.TEN && this.state.doctorList?.length !== this.state.doctorCount) {
        this.setState(prevState => ({
          perPage: Math.max(10, prevState.perPage),
          currentPage: Math.max(1, prevState.currentPage + 1)
        }));
      }
      this.setState({ prevScrollTop: scrollTop });
    }
  };

  async componentDidMount() {
    const isConnectedHospital = JSON.parse(await getStorageData("isConnectedHospital") || "false");
    if(isConnectedHospital){
      this.setState({title:"Hospital"})
    }else{
      this.setState({title:"Home"})
    }

    let prevFilters= await getStorageData('hpFilters') as string;
    let parseFilters= JSON.parse(prevFilters);

    if (parseFilters) {
      this.setState((prevState) => ({
        ...prevState,
        selectedFilters: parseFilters,
      })); 
    }

    try {
      await Promise.all([this.getDoctorList()]);
    } catch (errors) {
      this.handleShowMessage("error","Error fetching data:", "Something went wrong");
    }
    const serviceType = await getStorageData("serviceType");
    this.setState({ serviceType: serviceType })
  }

  async componentWillUnmount() {
    const element = document.getElementById("test");
    if (element) {
      element.removeEventListener('scroll', this.onScroll);
    }
  }

  RenderBannerImage = () => {
    const {classes} = this.props;
    return (
      <Box className={classes.bannerImageWrapper}>
      <Box className={classes.gradient} ></Box>
      <img className={classes.docBanner} src={docBanner} alt="Banner" />
            <img src={medic} className={classes.medic} />
            </Box>
    )
  }

  RenderBannerDesc = (classes: any) => {
    return (
      <Box className={classes.bannerTextDiv}>
        <p className={classes.bannerTitle}>{this.state.serviceType}</p>
        <p className={classes.bannerDescription}>There are only two sorts of doctors those who practice with their brain.</p>
      </Box>
    )
  }
  
  renderDoctorSlotFilter = () => {
    return (filters[3]).map((selectedSlotFilter: { name: string, value: string, time: string }, index: number) => {
      const filterTypeObjectSlot = this.state.selectedFilters.length > 0 && this.state.selectedFilters.find((filter: any) => filter.slot !== undefined);
      const checkForSlots = filterTypeObjectSlot ? filterTypeObjectSlot.slot.some((i: any) => i.name === selectedSlotFilter.name) : false;
  
      return (
        <TableRow key={index} data-test-id="slotTableRow" className={this.props.classes.shiftSortOptionDiv}>
          <TableCell data-test-id="slotType" style={this.getSelectedField(index, checkForSlots, false)} className={this.props.classes.shiftSortBox}>
            <span style={{fontFamily: 'SF Pro Text Medium',fontSize: '18px',fontWeight: 500,lineHeight: '21.48px',color: '#292929'}}>{selectedSlotFilter.name}</span>
            <span style={{fontFamily: 'SF Pro Text Regular',fontSize: '16px',fontWeight: 400,lineHeight: '19.09px',color: '#717171'}}>{selectedSlotFilter.time}</span>
          </TableCell>
          <TableCell data-test-id="slotTableCell" style={this.getRowStyles(index, checkForSlots, false)}>
            <Checkbox className={this.props.classes.checkbox} data-test-id={`slot${index}`} checked={checkForSlots} onChange={() => this.handleFilterCheckboxChange(index, selectedSlotFilter.value, "slot", selectedSlotFilter.name)} icon={<UnDoctorChecked />} checkedIcon={<DoctorChecked />}/>
          </TableCell>
        </TableRow>
      )
    })
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
         <ThemeProvider theme={theme}>
          <Box style={webStyles.container}>
            <SidebarNotificationWrapper {...this.props}>
                <Box className={classes.availbleContainer}>
                  <Box style={webStyles.searchBarHolder}>
                    <Box style={{ width: "100%" }}>
                      <TextField
                        data-test-id="searchBarHP"
                        variant="outlined"
                        placeholder={`Search ${this.state.serviceType}...`}
                        onChange={(e) => this.setState({ searchDoctor: e.target.value })}
                        onKeyPress={(event) => this.searchDoctorByName(event)}
                        className={classes.searchDoctors}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={searchIcons}
                                alt="search"
                                style={webStyles.searchingIcon}
                              />
                            </InputAdornment>
                          ),
                          style: {
                            borderRadius: "8px",
                            color: "#999999 !important",
                            fontFamily: "SF Pro Text Regular !important",
                            fontSize: "16px !important",
                          }
                        }}
                      />
                    </Box>
                    <Box>
                    <Box
                        className={this.state.showPopup?classes.hpFilterIconSelected:classes.hpFilterIcon}
                      >
                        <img data-test-id="hpToggleButton" src={filterIcons} onClick={this.togglePopup} />
                      </Box>
                      <Box>
                        {this.state.showPopup && (
                          <Filter navigation={this.props.navigation}
                          selectedFilters={this.state.selectedFilters}
                          getHpList={this.getDoctorHpList} closePopup={this.togglePopup} id={""}/>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.bannerHolder}>                  
                    {this.RenderBannerImage()}
                    {this.RenderBannerDesc(classes)}
                  </Box>
                  <Box  style={{ width: "100%" }}>
                    <Typography variant="h6" className={classes.upcommingBookingText}>
                      {this.state.title} Visit Doctors
                    </Typography>
                    <Typography variant="h6" style={webStyles.subDescText}>
                      Please choose date and time slots to see available doctors
                    </Typography>
                    <Box style={webStyles.selectFilterHolder}>
                      <Box className={classes.selectParentContainer}>
                        <Box className={classes.customDatePickerName}>
                          <span className={classes.customDateInputName}>
                            <DatePicker
                              data-test-id="bookingdate"
                              popperPlacement="top-start"
                              popperModifiers={[
                                {
                                  name: "offset",
                                  options: {
                                    offset: [60, 0],
                                  },
                                },
                                {
                                  name: "preventOverflow",
                                  options: {
                                    rootBoundary: "viewport",
                                    tether: false,
                                    altAxis: true,
                                  },
                                },
                              ]}
                              wrapperClassName={classes.datepickerClassesFullWidth}
                              calendarClassName={classes.datepickerClasses}
                              onChange={this.formatNewCustomDate}
                              className={classes.dateField}
                              dateFormat="Select Date"
                              value={this.state.date}
                              placeholderText="Select Date"
                              minDate={new Date()}
                            />
                          </span>
                        </Box>
                      </Box>
                      <Box className={classes.selectParentContainer}>
                        <Typography
                          onClick={this.handleShiftSelect}
                          className={classes.selectMenu}
                          data-test-id="selectSlot">Select Slot
                          <img
                            src={DownArrow}
                            alt="DownArrow"
                            style={webStyles.hpDownArrowIcon}
                          /></Typography>
                        {
                          this.state.shiftsAnchorEl && (
                            <ClickAwayListener
                              data-test-id="handleSlotSelect"
                              onClickAway={() => this.handleShiftSelect()}
                            >
                              <Box
                                style={{ ...(this.state.shiftsAnchorEl ? webStyles.shiftsDropdownVisible : webStyles.dropdown) }}
                              >
                                {this.renderDoctorSlotFilter()}
                              </Box>
                            </ClickAwayListener>
                          )
                        }
                      </Box>
                    </Box>
                    <Box>
                      {this.extractNamedObjects(this.state?.selectedFilters,"doctor")?.map((element: any, index: number) => {
                      return (
                        <Chip
                          key={index}
                          data-test-id={`removeFilter${index}`}
                          label={element.name}
                          onDelete={() => this.handleRemoveChip(element,"doctor")}
                          deleteIcon={<CloseIcon style={{ width: "20px", color: "white" }} fontSize="small" />}
                          style={webStyles.chip}
                        />
                      )
                      })}
                    </Box>
                    <div className={classes.scrollContainer} ref={this.scrollContainerRef}  style={{ height: "50vh", overflow: this.state.doctorList?.length > 0 ? "auto" : "hidden", marginTop: "30px" }} data-test-id="scroll" onScroll={this.onScroll}>
                      {this.state.doctorList?.length > 0 ? (
                        <Box id="doctorListBox" style={webStyles.availableDoctorList}>
                          {this.state.doctorList?.map((user: any, index: number) => (
                            <Box key={index} className={classes.doctorRoot}>
                              <Box data-test-id={`navigateToDoctorProfile${index}`} onClick={() => this.navigateToNurseProfile(user.id)} className={classes.flexBox}>
                                <img style={webStyles.doctorCover} src={user.attributes?.profile_image_url?.url || UserProfile} title={user.name} />
                                <Box style={webStyles.doctorDetails}>
                                  <CardContent style={webStyles.doctorContent}>
                                    <Box style={webStyles.doctorBox}>
                                      <Box>
                                        <Typography className={classes.doctorName} component="h5" variant="h5">
                                        Dr. {user.attributes?.name}
                                        </Typography>
                                      </Box>
                                      <Box style={webStyles.doctorRating}>
                                        <StarIcon style={{width:"14px",height:"13px"}} />
                                        <Typography variant="body2" style={{ marginLeft: '4px' }}>
                                          <span style={webStyles.userRating}>{user?.rating || "4.2"}</span><span style={webStyles.userRatingOutOf}> / 5 </span>
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box style={webStyles.doctorBox}>
                                      <Box>
                                        <Box style={webStyles.doctorPriceShift}>
                                          {this.state.priceMode === configJSON.Appointment && (
                                            <>
                                              <Typography style={webStyles.doctorFees} variant="h6">
                                                {this.state.sign}{user.attributes?.profile_type_specific_data?.consultation_with_service_fee}
                                              </Typography>
                                              <Typography style={webStyles.doctorShift} variant="subtitle1" color="textSecondary">
                                                / appointment
                                              </Typography>
                                            </>
                                          )}
                                          {this.state.priceMode === "online" && (
                                            <>
                                              <Typography style={webStyles.doctorFees} variant="h6">
                                              {this.state.sign}{user.attributes?.profile_type_specific_data?.consultation_with_service_fee}
                                              </Typography>
                                              <Typography style={webStyles.doctorShift} variant="subtitle1" color="textSecondary">
                                                / online consultation
                                              </Typography>
                                            </>
                                          )}
                                        </Box>
                                      </Box>
                                      <Box>
                                        <Box style={webStyles.doctorRating}>
                                          <Typography variant="body2" style={webStyles.doctorSpecification}>
                                            <span style={webStyles.type}>{user.attributes?.profile_type_specific_data?.specialization?.name || ""}</span>
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box style={webStyles.doctorBox}>
                                      <Box>
                                        <Box style={webStyles.doctorPriceShift}>
                                          <Typography style={webStyles.doctorExperienceLabel} variant="h6">
                                            Experience: 
                                          </Typography>
                                          <Typography style={webStyles.doctorExperience} variant="h6" color="textSecondary">
                                            {user.attributes?.experience} years
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>  
                                  </CardContent>
                                </Box>
                              </Box>
                              <Box style={webStyles.btnContainer}>
                                <CustomStyledButton
                                  data-test-id={`bookNow${index}`}
                                  style={webStyles.BookNowBtn}
                                  onClick={() => this.goToBookDoctorNow(user.attributes?.shift_availabilities[0]?.healthcare_personnel_id)}
                                  label="Book Now"
                                />
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )
                        :
                        (
                          <>
                            {
                              this.state.loading ? (
                                <>
                                  <Box data-test-id="doctorListLoaderTest" id="doctorListLoader" style={webStyles.loadingText}>Loading...</Box>
                                </>) : (
                                <>
                                  <Box data-test-id="noDataBoxDoctor" id="noDataBox" style={webStyles.noDataContainer}>
                                    <img
                                      src={NoData}
                                      width={60}
                                      height={60}
                                      style={webStyles.noDataIcons}
                                    />
                                    <Box style={webStyles.noDataText}>No results found</Box>
                                    <Box style={webStyles.subText} >Try adjusting or clearing your filtes to</Box>
                                    <Box style={webStyles.subText} >display better results.</Box>
                                  </Box>
                                </>)
                            }</>
                        )}
                    </div>
                        {(this.state.doctorList?.length > 0 && this.state.loading) && (
                          <Box id="doctorListLoader" style={webStyles.loadingText}>Loading...</Box>
                        )}
                  </Box>
                </Box>
                <CommonMessage 
                  open={this.state.openMessage} 
                  type={this.state.messageType} 
                  hideImage={this.state.hideMessageImage}
                  onClose={this.handleCloseMessage} 
                  title={this.state.messageTitle} 
                  subTitle={this.state.messageSubTitle} 
                />
            </SidebarNotificationWrapper>
          </Box>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyles:any = {
  datepickerClassesFullWidth:{
    '@media(max-width:600px)':{
      '&.react-datepicker-wrapper':{width:'100%'},
    }
  },
  gradient:{
    background: "linear-gradient(165deg, rgba(2, 126, 159, 0.5) 0%, #014866 100%)",
    borderRadius:'10px',
    height:'150px',
    '@media(min-width:600px)':{
      display:'none'
    }
  },
  docBanner:{
    width:'100%',position:"absolute" as const,bottom:'0',
    '@media(max-width:1200px)':{
      height:'150px',
    },
    '@media(max-width:991px)':{
      position:'relative' as const,
      height:'auto',
    },
    '@media(max-width:720px)':{
      height:'114%',
    },
    '@media(max-width:600px)':{
      display:'none',
    }
  },
  medic:{
    position:'relative' as const,zIndex:1,display:'block',marginLeft:'auto',
    '@media(max-width:991px)':{
      display:'none'
    }
  },
  bannerImageWrapper:{
    position:"relative" as const,
  },
  container: {
    display: "flex",
    width:"auto",
    height: "100vh",
    overflowX: "hidden" as "hidden",
  },
  availbleContainer: {
      padding: "40px 50px",
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      flexDirection: "column" as FlexDirectionProperty,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "20px",
      boxSizing:'border-box' as const,
      '@media(max-width:600px)':{
        padding:'24px'
      }
  },
  bannerHolder: {
    position:'relative' as const,
    width: "100%",
    margin: "0px 0 20px",
    borderRadius: "14px",
    objectFit: "contain" as "contain",
    '@media (max-width: 950px)': {
      marginBottom: 0,
    },
  },
  bannerImg: {
    position: 'absolute' as PositionValue,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    borderRadius: "8px"
  },
  bannerTextDiv: {
    position: 'absolute' as PositionValue,
    left: '22px',
    zIndex: 1,
    color: 'white',
    top:'48%',
    '@media (max-width: 1280px)': {
      top: '58%',
    },
    '@media(max-width:1200px)':{
      top:'48%',
    },
    '@media(max-width:991px)':{
      top:"25%",
    },
    '@media(max-width:600px)':{
      top:'14%'
    }
  },
   bannerTitle: {
    fontFamily: 'SF Pro Text Bold',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '26.25px',
    marginTop: 0,
    marginBottom: "10px",
    letterSpacing: '-0.6285714507102966px',
    '@media(max-width:767px)':{
      fontSize:'18px'
    }
  },
  bannerDescription: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 400,
    margin: 0,
    letterSpacing: '-0.5142855048179626px',
    textAlign: 'left' as 'left',
    maxWidth: "80%",
    '@media (max-width: 1050px)': {
      maxWidth: "60%",
      },
      '@media(max-width:600px)':{
        maxWidth:"90%",
        fontSize:'16px'
      }
  },
  availableDoctorList: {
    display: "flex",
    flexDirection: "column" as FlexDirectionProperty,
    gap: "25px",
    maxHeight: "500px",
    minHeight: "300px",
    '@media (min-width: 1051px)': {
      width: "calc(100% - 100px) !important",
      },
      '@media (max-width: 1050px)': {
        width: '100% !important',
      },
  },
  searchBarHolder: {
    justifyContent: "space-between",
    display: "flex",
    width: "100%"
  },
  searchDoctors: {
    width: "93.5%",
    margin: "15px 0px",
    "& .MuiInputBase-root": {
      fontFamily: "SF Pro Text Regular !important",
      color: "#292929 !important",
      fontSize: "16px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px !important",
    },
    '&::placeholder': {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: '#999999 !important',
      opacity: 1,
    },
    '&::-webkit-input-placeholder': {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: '#999999 !important',
      opacity: 1,
    },
  },
  searchingIcon: {
    height: "25px",
    width: "25px",
    objectFit: "cover" as objectFitType,
    display: "flex",
    justifyContent: "center",
    margin: "0px 15px 0px 0px"
  },
  selectedIcon: {
    width: "18px",
    height: "18px",
    justifyContent: "center",
    display: "flex",
    margin: "20px auto 0px -45px",
    zIndex: 1000,
    backgroundColor: "white",
    padding: "13px",
    borderRadius: "8px",
    position:"absolute" as PositionValue,
    caretColor:"white"
  },
  settingIcon: {
    width: "18px",
    height: "18px",
    justifyContent: "center",
    display: "flex",
    margin: "20px auto 0px -45px",
    zIndex: 1000,
    backgroundColor: "white",
    padding: "13px",
    borderRadius: "8px",
    caretColor:"white"
  },
  upcommingBookingText: {
    color: "#4C4C4C",
    fontSize: "20px",
    fontFamily: "SF Pro Text Semibold",
    fontWeight: 600,
    marginBottom: "12px",
    caretColor:"transparent",
    '@media(max-width:600px)':{
      fontSize:'18px'
    }
  },
  subDescText: {
    fontFamily: 'SF Pro Text Regular',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19.09px',
    color: '#808080',
  },
  BookNowBtn: { 
    caretColor:"transparent",
    backgroundColor: "#014866",
    color: "#fff",
    borderRadius: "8px",
    border: "none",
    width:"170px",
    height:"52px",
    fontSize: "18px",
    cursor: "pointer",
    fontFamily:"SF Pro Text Bold",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    float:"right" as floatType,
    textTransform: "capitalize" as textTransform,
    '@media (max-width:1250px)': {
      width: "160px !important",
    },
  },
  chip: 
  {
    "& .MuiChip-label":{
      paddingRight: "8px !important",
      paddingLeft: "8px !important",
    },
    margin: "2px 25px 5px 0px",
    alignItems: "center",
    fontSize: "14px",
    padding: "20px 12px",
    fontWeight: 400,
    fontFamily:"SF Pro Text Regular",
    color: "white",
    background: "#FF0000",
    borderRadius: "7px",
    minWidth: "124px",
    height:"34px",
    caretColor:"transparent",
    lineHeight: "16.71px",
    "& .MuiChip-deletable:focus":{
      borderRadius: "7px",
      color: "white",
        caretColor:"transparent",
        backgroundColor: "#FF0000",
    },
    "& .MuiChip-deleteIcon":{
      height:"20px",
      width:"20px",
      fontFamily:"SF Pro Text Regular",
    },
  },
  noDataContainer:{
    minHeight: "200px",
    width:"362px",
    display:"block",
    margin:"40px auto 0px"
  },
  noDataIcons:{
    display:"flex",
    margin:"auto"
  },
  noDataText:{
    fontFamily:"SF Pro Text Medium",
    fontSize:"20px",
    fontWeight: 500,
    color:"#808080", 
    display:"flex",
    width:"100%",
    justifyContent:"center",
    margin:"8px auto",
  },
  loadingText: {
    fontSize:"20px",
    fontFamily:"SF Pro Text Medium",
    color:"#808080", 
    fontWeight: 500,
    width:"100%",
    display:"flex",
    justifyContent:"center",
    margin:"10px auto 0",
  },
  subText:{
    fontFamily:"SF Pro Text Medium",
    fontSize:"20px",
    color:"#808080", 
    width:"100%",
    display:"flex",
    justifyContent:"center", 
    margin:"auto",
    opacity: "0.29"
  },
  btnContainer:{
    
  },
  hpDownArrowIcon: {
    height: "14px",
    width: "22px",
    cursor: "pointer",
    objectFit: "cover" as objectFitType,
    transform: 'translate(-6px, -1px)'
  },
  selectMenu: {
    width: '155px',
    border: '1px solid #808080',
    height: '52px',
    borderRadius: '8px',
    color: '#808080',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'SF Pro Text Regular',
    padding: '0 15px',
    fontSize: '18px',
    lineHeight: '21.48px',
    fontWeight: 400,
    cursor: 'pointer',
    '@media(max-width:600px)':{
      width:'100%',
      boxSizing:'border-box'
    }
  },
  selectParentContainer: {
    position: 'relative' as PositionValue,
    '@media(max-width:600px)':{
      width:'100%',
    }
  },
  selectFilterHolder: {
    margin: "25px 0px 10px",
    lineHeight: '26.8px',
    display: 'flex',
    gap: '30px',
    padding: "10px 0px",
    alignItems: 'center',
    flexWrap:"wrap"
  },
  flexBox: {
    display: 'flex',
    boxShadow: '0px 0px 40px #B3B3B31F',
    border: '1px solid #80808021',
    borderRadius: "10px",
    alignItems: 'center',
    padding: "5px 24px",
    cursor: 'pointer',
    maxWidth:'538px',
    '@media (min-width:991px)': {
      width: '100%',
    },
    '@media(max-width:991px)':{
      width:"100%"
    },
    '@media(max-width:600px)':{
      width:'100%',
      maxWidth:'250px',
      flexDirection:'column'
    }
  },
  doctorRoot: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    
    cursor: 'pointer',
    flexWrap:'wrap',
    gap:'50px',
    '@media (max-width:991px)': {
      flexWrap:'nowrap'
    },
    '@media(max-width:767px)':{
      flexWrap:'wrap'
    },
    '@media (max-width:600px)': {
      flexDirection:'column',
      justifyContent:'center'
    },
  },
  doctorDetails: {
    display: 'flex',
    flexDirection: 'column' as FlexDirectionProperty,
    width: "100%",
    justifyContent: 'center',
    paddingRight:"20px"
  },
  doctorContent: {
    width: "100%",
    "& .MuiCardContent-root:last-child":{
      paddingBottom:"20px !important"
    }
  },
  doctorCover: {
    maxWidth: "85px",
    minWidth: "85px",
    maxHeight: "85px",
    minHeight: "85px",
    borderRadius: '50%',
    margin: theme.spacing(1),
  },
  doctorName: {
    fontFamily: 'SF Pro Text Bold',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '23.87px',
    letterSpacing: '-0.48235294222831726px',
    color: '#292929',
    textTransform: "capitalize" as "capitalize",
    '@media(max-width:600px)':{
      fontSize:'18px'
    }
  },
  doctorFees: {
    fontFamily: 'SF Pro Text Bold',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.09px',
    letterSpacing: '-0.38588234782218933px',
    color: '#191B1A',
    marginRight: "5px"
  },
  doctorExperienceLabel: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '14px',
    lineHeight: '19.09px',
    letterSpacing: '-0.38588234782218933px',
    color: '#292929',
    marginRight:"5px"
  },
  doctorExperience: {
    fontFamily: 'SF Pro Text Medium',
    fontSize: '14px',
    lineHeight: '19.09px',
    letterSpacing: '-0.38588234782218933px',
    color: '#292929',
  },
  doctorShift: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '19.09px',
    letterSpacing: '-0.38588234782218933px',
    color: '#191B1A',
    opacity: 0.5
  },
  type: {
    fontFamily: 'SF Pro Text Regular',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16.71px',
    letterSpacing: '-0.34px',
    color: '#AC8E68',
  },
  doctorRating: {
    display: 'flex',
    alignItems: 'center',
    color: '#4CAF50',
    fontWeight: 600,
  },
  doctorSpecification: {
    marginTop: "15px",
    color: '#808080',
    textTransform: "capitalize" as textTransform,
  },
  dropdown: {
    display: 'none',
  },
  shiftsDropdownVisible: {
    display: 'block',
    position: 'absolute' as PositionValue,
    top: '70px',
    left: '0',
    width: '155px',
    height: 'fit-content',
    background: 'white',
    zIndex: 10,
    boxShadow: '0px 2px 8px 0px #80808021',
    border: '1px solid #80808021',
    borderRadius: "12px",
    padding: "0px 16px"
  },
  dateDropdownVisible: {
    display: 'block',
    position: 'absolute' as PositionValue,
    top: '60px',
    left: '0',
    width: '170px',
    height: '200px',
    background: 'white',
    zIndex: 10,
    boxShadow: '0px 0px 40px rgba(0,0,0,0.2)',
    border: '1px solid #80808021',
    borderRadius: "10px",
  },
  doctorButton: {
    alignSelf: 'center',
    backgroundColor: '#00678F',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#00506F',
    },
  },
  doctorPriceShift: {
    display: 'flex',
    alignItems: 'center',
    marginTop: "12px",
  },
  doctorBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: "100%",
  },
  userRating: {
    fontFamily: 'SF Pro Text Medium',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16.71px',
    letterSpacing: '-0.02800000086426735px',
    color: '#014866',
    marginLeft: '4px',
  },
  userRatingOutOf: {
    fontFamily: 'SF Pro Text Medium',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16.71px',
    letterSpacing: '-0.02800000086426735px',
    color: '#3780E0',
  },
  hpFilterIconSelected: {
    width: "18px",
    height: "18px",
    justifyContent: "center",
    display: "flex",
    margin: "20px auto 0px 0px",
    backgroundColor: "white",
    zIndex: 1000,
    borderRadius: "8px",
    padding: "13px",
    position:"absolute" as PositionValue,
    caretColor:"white",
    "@media (max-width: 959px)": {
      margin: "10px auto 10px 0px",
    },
    "@media (max-width: 600px)": {
      margin: "20px auto 0px -30px",
    }
  },
  hpFilterIcon: {
    width: "18px",
    height: "18px",
    display: "flex",
    margin: "20px auto 0px 0px",
    justifyContent: "center",
    backgroundColor: "white",
    zIndex: 1000,
    padding: "13px",
    borderRadius: "8px",
    caretColor:"white"
  },
  scrollContainer: {
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&& ::-webkit-scrollbar": {
      display: "none"
    },
  },
  containFilter: {
    display:"flex",
    gap:"15px",
    marginTop:"18px"
  },
  allBookButton:{
    width:"75px",
    height:"45px",
    color:"#3780E0",
    fontFamily:"SF Pro Text Semibold",
    fontSize:"18px",
    border:"none",
    borderRadius:"8px",
    backgroundColor:"#C3D9F6",
    textTransform: "capitalize" as textTransform,
  },
  homeVisitBtn:{
    width:"212px",
    height:"45px",
    color:"#808080",
    fontFamily:"SF Pro Text Regular",
    fontSize:"18px",
    border:"1px solid #808080",
    borderRadius:"8px",
    backgroundColor:"#fff",
    textTransform: "capitalize" as textTransform,
  },
  hospitalVisitBtn:{
    width:"233px",
    height:"45px",
    color:"#808080",
    fontFamily:"SF Pro Text Regular",
    fontSize:"18px",
    border:"1px solid #808080",
    borderRadius:"8px",
    backgroundColor:"#fff",
    textTransform: "capitalize" as textTransform,
  },
  shiftSortBox: {
    display: 'flex', 
    flexDirection: 'column' as FlexDirectionProperty, 
    alignItems: 'flex-start', 
    gap: '5px'
  },
  shiftSortOptionDiv:{
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    borderBottom: "1px solid #f2f2f2",
    '&:first-of-type': {
      marginTop: "5px"
    },
    '&:last-of-type': {
      borderBottom: "none !important",
      marginBottom: "5px"
    }
  },
  checkbox: {
    padding: "5px 25px 5px 0px",
    color: "white !important",
    "& .MuiCheckbox-colorSecondary .Mui-checked":{
      color: "white !important",
    },
    transform: "translateX(-10px)"
  },
  customDateInputName: {
    width: "187px",
    borderRadius: "8px",
    "& input": {
      color: "#808080",
      background: `url(${DownArrow}) left / contain no-repeat`,
      backgroundPosition: "calc(100% - 20px)",
      backgroundSize: "22px",
      fontSize: "18px",
      width: "187px",
      height: "58px",
      border: "1px solid #808080",
      display: "flex",
      margin: "auto",
      borderRadius: "8px",
      fontFamily: "SF Pro Text Regular",
      caretColor: "transparent",
      "&::placeholder": {
        opacity: 1,
        color: "#808080 !important",
        fontSize: "18px",
        fontFamily: "SF Pro Text Regular",
      },
      '@media(max-width:600px)':{
        width:"100%"
      }
    },
    "& ::-webkit-input-placeholder": {
      color: "#565454",
    },
    "&:focus": {
      border: "1px solid blue",
    },
    '@media(max-width:600px)':{
      width:"100%"
    }
  },
  customDateFieldName: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "8px",
    padding: "15px 60px",
  },
  datepickerClasses:{
    "& .react-datetime-picker__wrapper":{ border: "none"},
    "& .react-datepickerClass-wrapper":{display: "flex !important",},
    "& .react-datepickerClass__month":{ margin: "0px !important"},
    "& .react-datepickerClass-popper":{transform: "translate(90px, 209px) !important"},
    "& .react-datepickerClass__day":{
      height: "1rem !important", width: "1.3rem !important",
    },
    "& .react-datepickerClass__day-name":{
      width: "1.3rem !important", height: "1rem !important"
    },
    "& .react-datepickerClass__month-container":{
      margin: "0px 0px 5px !important"
    },
    "& .react-datepickerClass__day-names":{
      marginBottom: "-1px !important", caretColor: "transparent"
    },
    "& .react-datepickerClass__navigation":{
      margin: "5px -2px !important",
      caretColor: "transparent",
      height: "22px !important",
      width: "22px !important",
    },
    "& .react-datepickerClass__day--keyboard-selected":{
      color: "#000 !important",
      caretColor: "transparent",
      backgroundColor: "white !important",
    },
    "& .react-datepickerClass__day--today":{
      color: "#1d5d90 !important",
      fontWeight: "bold !important",
      caretColor: "transparent",
      backgroundColor: "white !important",
    },
    "& .react-datepickerClass__day:hover":{
      color: "#ccc !important",
      caretColor: "transparent",
      backgroundColor: "white !important",
    },
  },
  dateField: {
    display: "flex",
    borderRadius: "8px",
    cursor: "pointer",
    padding: "15px",
    border: "1px solid #808080",
    justifyContent: "center",
    "&:focus-visible":{
      outline: "none",
      border: "1px solid #808080",
    },
    "&:focus":{
      outline: "none",
      border: "1px solid #808080",
    },
    "&:hover":{
      outline: "none",border: "1px solid #808080",
    },
    color: "#808080",
    "& input": {
      color: "#808080",
      "&::placeholder": {
        fontSize: "18px",
        opacity: 1,
        color: "#808080 !important",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .react-datepicker-ignore-onclickoutside":{
      border: "none !important",
      borderColor: "transparent !important"
    },
  },
};

export default withStyles(webStyles)(Reservations2DoctorBooking);
// Customizable Area End

