import React from "react";
// Customizable Area Start
import Reservation2Controller, { Props } from "./Reservations2Controller";
import { withStyles, CardContent, Typography, Grid, Box, Avatar, Button, ThemeProvider, createTheme, Dialog, Card } from "@material-ui/core";
import { FlexDirectionProperty } from "./types";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { paymentSuccess, UserProfile } from "./assets";
import StarIcon from '@material-ui/icons/Star';
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";
import CommonMessage from "../../../components/src/CommonMessage";
import CustomStyledButton from "../../../components/src/CustomStyledButton.web";


const theme = createTheme({
    palette: {
      primary: {
        contrastText: "#fff",
        main: "#0000ff",
      }
    }
  });

  const reviewers = [
    {
      image: UserProfile,
      name: 'Lucas Simoes',
      description: 'Conan Matusav is a Registered Charity under the Charities Acts.',
      rating: 4.2,
    },
  ];

// Customizable Area End
export class Reservations2NurseProfile extends Reservation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

    async componentDidMount() {
        await this.getProfileHPData();
        const queryParams = new URLSearchParams(window.location.search);
        const success = queryParams.get('success')
        if (success === "true") {
            this.setState({ paymentSuccess: true, },()=>{
                this.handleShowMessage("success","Appointment booked successfully")
            })
        } else {
            this.setState({ paymentSuccess: false })
        }
    }

    RenderProfileInfo = (data: any) => {
        const {classes} = this.props;
        return (
            <Box style={webStyles.profileInfo}>
                <Avatar alt="Lucas Simoes" src={data?.profile_image_url?.url || UserProfile} className={classes.avatar} />
                <Box>
                    <Typography className={classes.nurseProfileName} variant="h5">{data?.name || "N/A"}</Typography>
                    <Typography style={webStyles.nurseProfileDesc} color="textSecondary">{data?.address?.data?.attributes?.city || "N/A"}</Typography>
                </Box>
            </Box>
        )
    }

    RenderDescription = () => {
    const descData = this?.state?.nurseData?.attributes
    let title = "Ms."
    let pronoun = "She"
    if(descData?.gender === "Other"){
        title = "Misc.";
        pronoun = "Ze"
    }else if(descData?.gender === "Male"){
        title = "Mr."
        pronoun = "He"
    }
        if (descData?.profile_type === 'ayah') {
            return (
              <>
                {title} {descData?.name || "Chinaza Akachi"} is a verified ayah. 
                {pronoun} has been tirelessly delivering unmatched care and comfort to patients since the last {descData?.experience || 0} years.
              </>
            );
          }
        if (descData?.profile_type === 'doctor' || descData?.profile_type === 'physiotherapist' || descData?.profile_type === 'dietician') {
        return (
            <>
            {title} {descData?.name} is a verified {descData?.profile_type}. {pronoun} has passed {descData?.educational_degrees?.[descData?.educational_degrees?.length - 1]?.degree} from {descData?.educational_degrees?.[descData?.educational_degrees.length - 1]?.college}. {pronoun} has {descData?.experience} years of experience working as a {descData?.profile_type}.
            </>
        );
        }
        return (
            <>
               {this.renderNurseDescription(descData,title,pronoun)}
            </>
          );
    }

    renderNurseDescription = (descData: any, title: string,pronoun:string) => {
        return (
            <>
                {title} {descData?.name || "Lucas Simoes"} is a verified nurse. {" "}
                {descData?.educational_degrees && descData.educational_degrees.some((degree: any) => degree.degree.trim() !== "") && (
                    <>
                        {descData?.educational_degrees.filter((degree: any) => degree.degree.trim() !== "").map((degree: any, index: number) => (
                            <React.Fragment key={degree.id}>
                                {index > 0 && ', '}
                                {index === 0 && `${pronoun} has passed `}{degree.degree} {degree.college ? ` from ${degree.college}` : ""}
                            </React.Fragment>
                        ))}
                        .
                    </>
                )}
                {pronoun} has {descData?.experience || "0"} years of experience working as a nurse.
            </>
        )
    }
  // Customizable Area End

  render() {
    const {classes} = this.props;
    return (
      // Customizable Area Start
        <>
            <ThemeProvider theme={theme}>
                <Box  style={webStyles.profileContainer}>
                    <SidebarNotificationWrapper {...this.props}>
                        <Box>
                            <Box sx={webStyles.availbleProfileContainer}>
                                <Box style={webStyles.ProfileHeaderContainer}>
                                    <Box style={webStyles.ProfileHeading}>
                                        <ArrowBackIosIcon
                                            style={webStyles.ProfileBackButton}
                                            data-test-id="goToViewNursesElem"
                                            onClick={() => this.goToViewNurses(this.state.nurseData.attributes?.profile_type)}
                                        />
                                        <Typography variant="h6" className={classes.ProfileTitle}>
                                            Profile
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box style={webStyles.nurseProfileMainBox}>
                                    {this.RenderProfileInfo(this?.state?.nurseData?.attributes)}
                                    <Box>
                                        <CustomStyledButton
                                            data-test-id={`bookNow`}
                                            className={classes.BookNowBtn}
                                            onClick={() => {
                                                this.goToBookNurseNow(this.props.navigation.getParam("navigationBarTitleText"),this?.state?.nurseData?.attributes?.profile_type)}
                                            }
                                            label="Book Now"
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography className={classes.descriptionTitle}>Description</Typography>
                                    <Typography style={webStyles.descriptionContent}>
                                        {this.RenderDescription()}
                                    </Typography>
                                    <Box style={{...webStyles.nurseProfileDivider,marginBottom:'0px'}}></Box>

                                    <Box  style={webStyles.detailContainer} >
                                        <Box style={webStyles.detailItemStyle}>
                                            <div style={webStyles.labelStyle}>
                                                <Typography variant="body2" className={classes.labelTextStyle}>Age</Typography>
                                                <Typography variant="body2" style={webStyles.colonStyle}>:</Typography>
                                            <Typography className={classes.detailsValue} variant="body2">{this?.state?.nurseData?.attributes?.age ? `${this?.state?.nurseData?.attributes?.age} y/o` : "N/A"}</Typography>
                                            </div>
                                        </Box>
                                        <Box style={webStyles.detailItemStyle}>
                                            <div style={webStyles.labelStyle}>
                                                <Typography variant="body2" className={classes.labelTextStyle}>Gender</Typography>
                                                <Typography variant="body2" style={webStyles.colonStyle}>:</Typography>
                                            <Typography className={classes.detailsValue} variant="body2">{this?.state?.nurseData?.attributes?.gender || "N/A"}</Typography>
                                            </div>
                                        </Box>
                                        <Box style={webStyles.detailItemStyle}>
                                            <div style={webStyles.labelStyle}>
                                                <Typography variant="body2" className={classes.labelTextStyle}>{this?.state?.nurseData?.attributes?.profile_type === "doctor"|| this?.state?.nurseData?.attributes?.profile_type === "physiotherapist" || this?.state?.nurseData?.attributes?.profile_type === "dietician"  ? "Department" : "Working Shifts"}</Typography>
                                                <Typography variant="body2" style={webStyles.colonStyle}>:</Typography>
                                            
                                            {this?.state?.nurseData?.attributes?.profile_type === "doctor" || this?.state?.nurseData?.attributes?.profile_type === "physiotherapist" || this?.state?.nurseData?.attributes?.profile_type === "dietician" ? (
                                                <>
                                                    <Typography className={classes.detailsValue} variant="body2">{this.state?.nurseData?.attributes?.profile_type_specific_data?.department?.name || "N/A"}</Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography className={classes.detailsValue} variant="body2">
                                                        {this.formatShiftOutput(this?.state?.nurseData?.attributes?.working_shifts, this?.state?.nurseData?.attributes?.profile_type) || "N/A"}
                                                    </Typography>
                                                </>
                                            )}
                                            </div>
                                        </Box>
                                        <Box style={webStyles.detailItemStyle}>
                                            <div style={webStyles.labelStyle}>
                                                <Typography variant="body2" className={classes.labelTextStyle}>Experience</Typography>
                                                <Typography variant="body2" style={webStyles.colonStyle}>:</Typography>
                                            
                                            <Typography className={classes.detailsValue} variant="body2">{this?.state?.nurseData?.attributes?.experience ? `${this?.state?.nurseData?.attributes?.experience} Years` : "N/A"}</Typography>
                                            </div>
                                        </Box>
                                        <Box style={webStyles.detailItemStyle}>
                                            <div style={webStyles.labelStyle}>
                                                <Typography variant="body2" className={classes.labelTextStyle}>Language Known</Typography>
                                                <Typography variant="body2" style={webStyles.colonStyle}>:</Typography>
                                            
                                            <Typography className={classes.detailsValue} variant="body2">
                                                {this?.state?.nurseData?.attributes?.language_attributes?.length > 0 ?
                                                this?.state?.nurseData?.attributes?.language_attributes?.map((elem: any) => elem.name).join(", ")
                                                : "N/A"}
                                            </Typography>
                                            </div>
                                        </Box>
                                        <Box style={webStyles.detailItemStyle}>
                                            <div style={webStyles.labelStyle}>
                                                <Typography variant="body2" className={classes.labelTextStyle}>Email Address</Typography>
                                                <Typography variant="body2" style={webStyles.colonStyle}>:</Typography>
                                            
                                            <Typography className={classes.detailsValue} variant="body2">{this?.state?.nurseData?.attributes?.email || "N/A"}</Typography>
                                            </div>
                                        </Box>
                                    </Box>

                                    <Box style={webStyles.nurseProfileDivider}></Box>
                                    <Box className={classes.reviewContainer}>
                                        <Box style={webStyles.reviewHeader}>
                                            <Typography variant="h6" style={webStyles.reviewTitle}>
                                                Reviews
                                            </Typography>
                                            <Typography data-test-id="navigateToNurseReviewsSpan" onClick={() => this.navigateToNurseReviews(this.props.navigation.getParam("navigationBarTitleText"))} variant="h6" style={webStyles.seeAllReview}>
                                                See All (402)
                                            </Typography>
                                        </Box>
                                        {reviewers?.length > 0 ? reviewers.map((user: any, index: string | number) => (
                                            <Box data-test-id="reviewerInfoCardDiv" key={index} className={classes.reviewerFlexBox}>
                                                <img style={webStyles.reviewerCover} src={user.image} title={user.name} />
                                                <CardContent style={webStyles.reviewerContent}>
                                                    <div>
                                                        <Typography style={webStyles.reviewerName} component="h5" variant="h5">
                                                            {user.name}
                                                        </Typography>
                                                        <Typography style={webStyles.reviewerDescription} component="h5" variant="h5">
                                                            {user.description}
                                                        </Typography>
                                                    </div>
                                                    <div style={webStyles.reviewerOverallRating}>
                                                        <StarIcon />
                                                        <Typography variant="body2" style={{ marginLeft: '4px' }}>
                                                            <span style={webStyles.reviewerRating}>{user.rating || "4.2"}</span><span style={webStyles.reviewerRatingOutOf}> / 5 </span>
                                                        </Typography>
                                                    </div>
                                                </CardContent>
                                            </Box>
                                        )) : (
                                            <Box id="noReviewsFound" style={webStyles.noReviewsFound}>No reviewers data available.</Box>
                                        )}
                                        <Box style={webStyles.reviewDivider}></Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <CommonMessage 
                            open={this.state.openMessage} 
                            type={this.state.messageType} 
                            hideImage={this.state.hideMessageImage}
                            onClose={this.handelCloseSuccessPopup} 
                            title={this.state.messageTitle} 
                            subTitle={this.state.messageSubTitle} 
                            buttonLabel="Done"
                        />
                    </SidebarNotificationWrapper>
                </Box>
            </ThemeProvider>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
    profileContainer: {
        display: "flex",
    },
    availbleProfileContainer: {
        padding: "40px 50px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column" as FlexDirectionProperty,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "20px",
        '@media(max-width:600px)':{
            padding:'24px'
        }
    },
    noReviewsFound: {
        fontSize: "20px",
        fontFamily: "SF Pro Text Medium",
        fontWeight: 500,
        color: "#808080",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: "60px auto 30px",
    },
    ProfileHeaderContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "25px",
        justifyContent: "space-between",
    },
    ProfileHeading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "25px",
    },
    ProfileBackButton: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
        padding: "12px 8px 12px 16px",
    },
    ProfileTitle: {
        fontFamily: "SF Pro Text Bold",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "33.41px",
        '@media(max-width:600px)':{
            fontSize:'18px'
        }
    },
    avatar: {
        width: "138px",
        height: "138px",
        margin: 'auto',
        '@media(max-width:600px)':{
            width:'80px',
            height:'80px'
        }
    },
    nurseProfileMainBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: "100%",
        gap:'20px',
        flexWrap:'wrap' as const
    },
    nurseProfileName: {
        fontFamily: 'SF Pro Text Bold',
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '28.64px',
        color: '#292929',
        textTransform: 'capitalize' as 'capitalize',
        '@media(max-width:600px)':{
            fontSize:'18px'
        }
    },
    nurseProfileDesc: {
        fontFamily: 'SF Pro Text Regular',
        fontSize: '16px',
        fontWeight: 400,
        color: '#808080',
        marginTop: "4px",
        textTransform: "capitalize" as "capitalize",
    },
    descriptionTitle: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '23.87px',
        color: '#000000',
        marginBottom: "15px",
        '@media(max-width:600px)':{
            fontSize:'18px'
        }
    },
    descriptionContent: {
        fontFamily: 'SF Pro Text Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'justify' as 'justify',
        color: '#717171',
        marginBottom: "45px"
    },
    nurseProfileDivider: {
        maxWidth: '370px',
        width:'100%',
        height: '1px',
        opacity: 0.38,
        borderBottom: '1px solid #C2C2C2',
        margin: '20px 0px',
    },
    profileInfo: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '20px'
    },
    BookNowBtn: {
        caretColor: "transparent",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        borderRadius: "8px",
        width: "200px",
        height: "56px",
        cursor: "pointer",
        fontSize: "18px",
        fontFamily: "SF Pro Text Bold",
        fontWeight: 600,
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        },
        textTransform: "capitalize" as "capitalize",
        float: "right" as "right",
        '@media (max-width:1250px)': {
            width: "160px !important",
        },
        '@media(max-width:600px)':{
            width:'auto !important',
            height:'auto'
        }
    },
    detailContainer: {
        maxWidth: "575px",
        padding: "15px 0px 5px"
    },
    detailsValue: {
        textAlign: 'left' as 'left',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '21.48px',
        width:'100%',
        color: '#808080',
        overflow:'hidden',
        textOverflow:'ellipsis',
        '&:hover':{
            overflow:'visible',
            whiteSpace:'normal'
        },
        '@media(max-width:600px)':{
            fontSize:'16px'
        }
    },
    detailItemStyle: {
        display: 'flex',
        gap: "40px",
        paddingTop:'22px',
        width:'100%'
    },
    labelStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        width:'100%'
    },
    labelTextStyle: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '21.48px',
        color: '#292929',
        width:'100%',
        textAlign: 'left' as 'left',
        overflow:'hidden',
        
        textOverflow:'ellipsis',
        '&:hover':{
            overflow:'visible',
            whiteSpace:'normal'
        },
        '@media(max-width:600px)':{
            minWidth:'auto',
            fontSize:'16px'
        }
    },
    colonStyle: {
        width:'8%',
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        color: "#808080",
    },
    reviewContainer: {
        maxWidth:'657px',
        '@media(max-width:600px)':{
            width:'100%'
        }
    },
    reviewDivider: {
        width: '100%',
        height: '1px',
        opacity: 0.38,
        borderBottom: '1px solid #C2C2C2',
        margin: '20px 0px',
    },
    reviewHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    reviewTitle: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '23.87px',
        color: '#292929',
    },
    seeAllReview: {
        color: '#3780E0',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.71px',
        cursor: "pointer"
    },
    reviewerFlexBox: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: "20px",
        margin: "30px 0px",
        '@media(max-width:600px)':{
            flexWrap:'wrap' as const,
        }
    },
    reviewerCover: {
        width: "65px",
        height: "65px",
        borderRadius: '50%',
    },
    reviewerName: {
        fontFamily: 'SF Pro Text Bold',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '21.48px',
        letterSpacing: '-0.4341176450252533px',
        color: '#292929',
        marginBottom: '5px'
    },
    reviewerDescription: {
        fontFamily: 'SF Pro Text Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '-0.38588234782218933px',
        color: '#808080',
        // width: '277px'
    },
    reviewerContent: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        padding: 0,
    },
    reviewerRating: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.71px',
        letterSpacing: '-0.02800000086426735px',
        color: '#014866',
        marginLeft: '5px',
    },
    reviewerRatingOutOf: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.71px',
        letterSpacing: '-0.02800000086426735px',
        color: '#3780E0',
    },
    reviewerOverallRating: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "flex-end",
        color: '#4CAF50',
        fontWeight: 600,
        whiteSpace:'nowrap' as const
    },
    ptmDialogBoxNew: {
        "& .MuiDialog-paperWidthSm": {
            borderRadius: "20px !important",
        }
    },
    ptmNewTransparentCard: {
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
        width: "380px",
        borderRadius: "25px",
        padding: "15px 35px",
        '@media(max-width:600px)':{
            width:'auto'
        }
    },
    ptmCardContentNew: {
        display: "flex"
    },
    ptmSuccessLogo: {
        width: "85px",
        margin: "20px auto",
        caretColor: "transparent"
    },
    ptmSuccessMessage: {
        fontSize: "20px",
        color: "#292929",
        margin: "30px auto",
        fontWeight: 700,
        caretColor: "transparent",
        fontFamily: "SF Pro Text bold",
        '@media(max-width:600px)':{
            fontSize:'18px'
        }
    },
    successPtmBtn: {
        width: "100%",
        caretColor: "transparent",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        borderRadius: "10px",
        padding: "16px",
        cursor: "pointer",
        fontWeight: 600,
        fontSize: "18px",
        margin: "10px auto",
        fontFamily: "SF Pro Text Bold",
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        }
    },
    PtmSuccessLogo: {
        objectFit: "contain" as "contain",
        width: "150px", height: "150px",
        margin: "20px auto auto",
        caretColor:"transparent",
        '@media(max-width:600px)':{
            width:'90px',

        }
    }
};

export default withStyles(webStyles)(Reservations2NurseProfile);
// Customizable Area End