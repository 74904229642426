import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Card,
  withStyles,
  Grid,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  DialogTitle,
  SvgIcon,
  FormHelperText,
  Chip,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { homecureLogoBlue } from "./assets";
import { styles } from "./styles/PhoneNumberRegistration";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
import Select from "react-select";
import { PatientRegisterDetails } from "./PatientRegisterDetails.web";
import CloseIcon from '@material-ui/icons/Close';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Fileattachment from "../../fileattachment/src/Fileattachment.web"
import Loader from "../../../components/src/Loader.web";
import CommonMessage from "../../../components/src/CommonMessage";
import CustomStyledButton from "../../../components/src/CustomStyledButton.web";

const medecine = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" }
];

const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const monthsOptions = months.map((month, index) => {
  return { value: index + 1, label: month };
});

const startYear = 1950;
const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => (startYear + index).toString());

const yearsOptions = years.map((year) => {
  return { value: year, label: year };
});

const CustomsCheckboxIconChecked = () => (
  <SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
    <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </SvgIcon>
);

const CustomsCheckboxIconUnChecked = () => (
  <SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/>
  </SvgIcon>
);

const commonComponents = {
  IndicatorSeparator: () => null
};
// Customizable Area End

export class PatientHealthDetails extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if(this.props.userLogin) {
      await this.getHealthDetails();
    }
  }

  CustomSelectHandelChange = (event: any, onChange: (value: any) => void) => {
    onChange(event.value);
  };

  CustomSelectField = (index: number, className: any, options: any, placeholder: string, testId: string) => {
    let selectedValue = options.find((option: any) => option.value == this.state.medicines_attributes[index].days);
    return (
      <Box style={{marginTop: this.getMarginTop(index)}}>
        <Select
          className={className}
          data-test-id={testId}
          value={selectedValue}
          options={options}
          placeholder={placeholder}
          onChange={(event) =>
            this.handleMedicineChange(index, 'days', event?.value)
          }
          components={commonComponents}
          openMenuOnFocus styles={this.customStyle}
          isSearchable={false}
        />
      </Box>
    );
  };

  enableYear = (type:string,option:any) => {
    return type === "surgery_years" ? option.value >= new Date().getFullYear() : option.value > new Date().getMonth() + 1;
  }

  
  SurgeryCustomSelectField= (index: number, className: any, options: any, placeholder: string, testId: string, type: string) => {
    let selectedValue;
    let isOptionDisabled=false;
    if (type === "surgery_months") {
      let value = months.indexOf(this.state.surgeries_attributes[index][type]);
      selectedValue = options.find((option: any) => option.value === value + 1);
      isOptionDisabled=this.state.surgeries_attributes[index].surgery_years == new Date().getFullYear() ;
    } 
    if (type === "surgery_years") {
      selectedValue = options.find((option: any) => option.value === this.state.surgeries_attributes[index][type]);
      if(this.state.surgeries_attributes[index]?.surgery_months)
      isOptionDisabled=this.state.surgeries_attributes[index]?.surgery_months > new Date().getMonth() + 1;
    }
    return (
      <Box>
        <Select
          className={className}
          data-test-id={testId}
          value={selectedValue}
          options={options}
          placeholder={placeholder}
          onChange={(event) =>
            this.handleSurgeryChange(index, type, event?.value)
          }
          components={commonComponents}
          openMenuOnFocus styles={this.customStyle}
          isSearchable={false}
          isOptionDisabled={(option) => this.callIsOptionDisabled(option,isOptionDisabled,type)}
        />
      </Box>
    );
  };

  callIsOptionDisabled = (option:any,isOptionDisabled:boolean,type:string) => {
    return  isOptionDisabled ? this.enableYear(type,option) : false
  }

  selectOtherDiseasesClass = (index:number,state:any,classes:any,showAddButton:boolean) => {
    const lastIndex = state.length-1 === index;
    if(showAddButton){
      return lastIndex?classes.lastOtherSelectBoxDiseases:classes.patientOtherSelectBoxDiseases;
    }else{
      return classes.patientOtherSelectBoxDiseasesError;
    }
  }
  otherDiseasesTextFile = (healthDetailsError:any,index:number,classes:any) => {
    if(this.state.othersDiseases.length > 1) {
      return `${healthDetailsError[`otherDiseases${index}`]? classes.lastDiseasesTextBoxError : classes.otherDiseasesTextBox}`
    }else{
      return `${healthDetailsError[`otherDiseases${index}`]? classes.otherDiseasesTextBoxError : classes.otherDiseasesTextBox}`
    }
  }

  enableDiseasesYear = (type:any,option:any) => {
    return type === "disease_years" ? option.value >= new Date().getFullYear() : option.value > new Date().getMonth() + 1;
  }

  OthersDiseasesCustomSelectField = (index: number, className: any, options: any, placeholder: string, testId: string, type: string) => {
    let selectedValue;
    let isOptionDisabled=false;
    if (type === "disease_months") {
      selectedValue = options.find((option: any) => option.value == this.state.othersDiseases[index]?.disease_months);
      isOptionDisabled=this.state.othersDiseases[index]?.disease_years == new Date().getFullYear();
    } else {
      selectedValue = options.find((option: any) => option.value == this.state.othersDiseases[index]?.disease_years);
      isOptionDisabled=this.state.othersDiseases[index]?.disease_months > new Date().getMonth() + 1;
    }
    return (
      <Box>
        <Select
          className={className}
          data-test-id={testId}
          options={options}
          value={selectedValue}
          placeholder={placeholder}
          onChange={(event) =>
            this.handleOthersDiseasesChange(index, type, event?.value)
          }
          components={commonComponents}
          openMenuOnFocus styles={this.customStyle}
          isSearchable={false}
          isOptionDisabled={(option) =>  this.callIsOptionOthersDiseasesYearDisabled(option,isOptionDisabled,type)}
        />
      </Box>
    );
  };

  callIsOptionOthersDiseasesYearDisabled = (option:any,isOptionDisabled:boolean,type:string) => {
    return isOptionDisabled ? this.enableDiseasesYear(type,option) : false
  }

  fetchDiseasesYear =(placeholder:string,option:any)=>{
    return placeholder === "Year" ? option.value >= new Date().getFullYear() : option.value > new Date().getMonth() + 1
  }

  CustomSelectFieldDiseases = (className: any, options: any, placeholder: string, testId: string, diseaseId: any, setDiseaseMonthsOrYears: (value: any, diseaseId: any) => void, index: number) => {
    let selectedValue;
    const foundDisease = this.state.patient_diseases_attribute.find((item:any )=> item.disease_id === diseaseId);
    let isOptionDisabled=false;
    if (foundDisease) {
      if (placeholder === "Month") {
        selectedValue = options.find((option: any) => option.value == foundDisease.disease_months);
        isOptionDisabled=foundDisease.disease_years == new Date().getFullYear(); 
      } else {
        selectedValue = options.find((option: any) => option.value == foundDisease.disease_years);
        isOptionDisabled=foundDisease.disease_months > new Date().getMonth() + 1;
      }
    }
    return (
      <Box>
        <Select
          className={className}
          data-test-id={testId}
          options={options}
          value={selectedValue}
          placeholder={placeholder}
          onChange={(event) => setDiseaseMonthsOrYears(event?.value, diseaseId)}
          components={commonComponents}
          openMenuOnFocus styles={this.customStyle}
          isSearchable={false}
          isOptionDisabled={(option) =>this.callIsOptionDiseasesYearDisabled(option,isOptionDisabled,placeholder)}
        />
      </Box>
    );
  };

  callIsOptionDiseasesYearDisabled = (option:any,isOptionDisabled:boolean,placeholder:string) => {
    return isOptionDisabled ? this.fetchDiseasesYear(placeholder,option) : false
  }


  CustomSelectFieldMonth = (className: any, options: any, placeholder: string, testId: string, onChange: (value: any) => void, state: number) => {
    let selectedValue = options.find((option: any) => option.value === state);
    return (
      <Box>
        <Select
          className={className}
          data-test-id={testId}
          options={options}
          value={selectedValue}
          placeholder={placeholder}
          onChange={(event) => this.CustomSelectHandelChange(event, onChange)}
          components={commonComponents}
          openMenuOnFocus styles={this.customStyle}
          isSearchable={false}
          isOptionDisabled={(option) =>  ((this.state.drink_years == new Date().getFullYear()) || (this.state.smoke_years == new Date().getFullYear())  ? option.value > new Date().getMonth()+1 : false)}
        />
      </Box>
    );
  }

  CustomSelectFieldYear = (className: any, options: any, placeholder: string, testId: string, onChange: (value: any) => void, state: number) => {
    let selectedValue = options.find((option: any) => option.value == state);
    return (
      <Box>
        <Select
          className={className}
          data-test-id={testId}
          options={options}
          value={selectedValue}
          placeholder={placeholder}
          onChange={(event) => this.CustomSelectHandelChange(event, onChange)}
          components={commonComponents}
          openMenuOnFocus styles={this.customStyle}
          isSearchable={false}
          isOptionDisabled={(option) =>  ((this.state.drink_months > new Date().getMonth()+1) || (this.state.smoke_months > new Date().getMonth()+1)  ? option.value >= new Date().getFullYear() : false)}
        />
      </Box>
    );
  }

  renderCheckboxes = (diseasesList: any, classes: any) => {
    return diseasesList.length > 0 && diseasesList.map((disease: any, index: number) => (
      <>
        <Box key={index}>
          <FormControlLabel
            className={classes.boxWidth}
            control={
              <Checkbox
                data-test-id="selectDiseases"
                className={classes.checkboxWhite}
                checked={this.state.selectedDiseases.includes(disease.id)}
                onChange={() => this.handleDiseaseCheckboxChange(disease.id,disease.name)}
                icon={<CustomsCheckboxIconUnChecked />}
                checkedIcon={<CustomsCheckboxIconChecked />}
              />
            }
            label={
              <Typography data-test-id="diseasesName" style={{ caretColor:"transparent", fontFamily: this.state.selectedDiseases.includes(disease.id) ? "SF Pro Text Medium" : "SF PRo Text Regular", fontSize: "16px", color:"#292929" }}>
                {disease.name}
              </Typography>
            }
          />
          {this.state.selectedDiseases.includes(disease.id) && this.diseaseDuration(disease.id, classes, index)}
        </Box>
        {!this.state.selectedDiseases.includes(disease.id) && <Divider style={{ margin: "5px 0px" }} />}
      </>
    ));
  };

  diseaseDuration = (diseaseId: number, classes: any, index: number) => {
    return (
      <Box>
        <Typography variant="h6" className={classes.patientTypo}>
          Since
        </Typography>
        <Box className={classes.patientSelectBoxDiseases}>
          <Box style={{ width: "45%" }}>{this.CustomSelectFieldDiseases(classes.customSelectFieldDiseases, monthsOptions, "Month", "diseasesMonths", diseaseId, this.setDiseaseMonths, index)}</Box>
          <Box style={{ width: "45%" }}>{this.CustomSelectFieldDiseases(classes.customSelectFieldDiseases, yearsOptions, "Year", "diseasesYears", diseaseId, this.setDiseaseYears, index)}</Box>
        </Box>
      </Box>
    );
  };

  getSmokeClassNames = (condition: any) => {
    const { classes } = this.props;
    return condition ? classes.selectedField : classes.unselectedField;
  };

  getDrinkClassNames = (condition: any) => {
    const { classes } = this.props;
    return condition ? classes.selectedField : classes.unselectedField;
  };

  errorMessage = (error: string | undefined, inputError: any) => {
    return (error ? <FormHelperText className={inputError}>{error}</FormHelperText> : null)
  };

  isErrorCondition = (index: number) => {
    const { healthDetailsError, medicines_attributes } = this.state;
    return healthDetailsError.medicine && medicines_attributes.length === index + 1;
  };

  otherOptions = (classes: any) => {
    return (
      <>
        <FormControlLabel
          className={classes.boxWidth}
          data-test-id="otherDiseases"
          onChange={this.handleDiseasesOthers}
          control={
            <Checkbox
              className={classes.checkboxWhite}
              checked={this.state.others}
              icon={<CustomsCheckboxIconUnChecked />}
              checkedIcon={<CustomsCheckboxIconChecked />}
            />
          }
          label={
            <Typography data-test-id="diseasesName" style={{ fontWeight: this.state.others ? 600 : 500, fontSize: "16px",caretColor:"transparent" }}>
              Others
            </Typography>
          }
        />
        {this.state.others &&
          <>
            {this.state.othersDiseases?.map((othersDiseases: { disease_name: string; disease_months: string, disease_years: string }, index: number) => {
              return (
                <>
                  <TextField
                    data-test-id="otherName"
                    variant="outlined"
                    placeholder="Name of the diseases"
                    className={this.otherDiseasesTextFile(this.state.healthDetailsError,index,classes)}
                    value={othersDiseases.disease_name}
                    onChange={(event) => this.handleOthersDiseasesChange(index, "disease_name", event.target.value)}
                  />
                  <Box key={index}>
                    <Typography variant="h6" className={classes.patientTypo}>
                      Since
                    </Typography>
                    <Box className={this.selectOtherDiseasesClass(index,this.state.othersDiseases,classes,this.state.showAddButton)}>
                      <Box style={{ width: "48%" }}>{this.OthersDiseasesCustomSelectField(index, classes.customSelectFieldDiseases, monthsOptions, "Month", "diseasesMonths", "disease_months")}</Box>
                      <Box style={{ width: "48%" }}>{this.OthersDiseasesCustomSelectField(index, classes.customSelectFieldDiseases, yearsOptions, "Year", "diseasesYears", "disease_years")}</Box>
                    </Box>
                  </Box>
                  {this.errorMessage(this.state.healthDetailsError[`otherDiseases${index}`], classes.inputOthersErrors)}
                </>
              );
            })
            }
            <Box className={classes.othersLink}>
              <div className={classes.link} style={{ textDecoration: "none", display: this.state.showAddButton ? "flex" : "none" }} onClick={this.handleAddDiseases}>
                + Add
              </div>
            </Box>
          </>
        }
      </>
    )
  }

  medicineContainer = (classes: any, medicine: any, index: number) => {
    const medicineError = this.state.healthDetailsError[`medicine${index}`]
    return (
      <>
        <Box className={classes.selectPatientField}>
          <TextField
            data-test-id="medecineName"
            variant="outlined"
            style={{marginTop: this.getMarginTop(index)}}
            placeholder="Medicine name"
            className={`${medicineError ? classes.inputError : classes.boxWidth}`}
            value={medicine.name}
            onChange={(event) => this.handleMedicineChange(index, "name", event.target.value.trimStart())}
          />
        </Box>
        <Box className={classes.selectPatientField}>
          {this.CustomSelectField(
            index,
            `${medicineError ? classes.customErrorSelectField : classes.customSelectField}`,
            medecine,
            "0 times / day",
            "medecineTime"
          )}
        </Box>
      </>
    )
  }

  allergyConatiner = (classes: any, allergy: any, index: number) => {
    const error = this.state.healthDetailsError[`allergy${index}`]
    const lastIndex = this.state.allergies_attributes.length - 1;
    const isLastItem = index === lastIndex;
    let margin = isLastItem ? "10px" : "28px"
    if(error){margin="12px"}
    return (
      <Box key={index} className={classes.patientAllergyBox} style={{marginBottom:margin}}>
        <TextField
          data-test-id={`allergyName${index}`}
          variant="outlined"
          placeholder="Allergy name"
          className={`${error ? classes.inputError : classes.boxWidth}`}
          value={allergy.name}
          onChange={(event) => this.handleAllergyChange(index, "name", event.target.value.trimStart())}
        />
      </Box>
    )
  }

  drinkDropdown = (drinkState: boolean, classes: any) => {
    return (
      <>
        {drinkState &&
          <>
            <Typography variant="h6" className={classes.patientTypo}>
              Since
            </Typography>
            <Box className={classes.patientSelectBoxDiseases}>
              <Box style={{ width: "48%" }}>{this.CustomSelectFieldMonth(classes.customSelectField, monthsOptions, "Month", "selectDrinkMonths", this.handleDrinkMonthChange, this.state.drink_months)}</Box>
              <Box style={{ width: "48%" }}>{this.CustomSelectFieldYear(classes.customSelectField, yearsOptions, "Year", "selectDrinkYears", this.handleDrinkYearChange, this.state.drink_years)}</Box>
            </Box>
          </>
        }
      </>
    )
  }

  renderSelectedAttachment = ()=>{
    const isUpload = this.state.docType === "upload";
    let meta = this.state.scan;
    if(isUpload){
      meta = this.state.prescription
    }
    return meta && <Chip
      data-test-id='chip'
      size="medium"
      style={{
        marginTop:'14px', 
        color: "#014866",
        backgroundColor: "#c2e0ef"
      }}
      label={meta.filename.slice(0,30)}
      onDelete={this.handleRemoveSelectedFile}
    />
  }

  uploadDocPopup = (classes: any) => {
    const prevScans = !this.props.userLogin ? "View Scans and Report" :"View previous Scans and Report"
    const prevDesc =  !this.props.userLogin ? "View Prescription" : "View Previous Prescription";
    const renderUploadedScanLabel = this.state.docType === "upload" ? prevDesc : prevScans
    return (
      <>
        <Dialog open={this.state.isPresciptionDialogOpen} className={classes.uploadDocContainer}>
        <Loader loading={this.state.loader} />
          {this.successMessage(this.state.uploadFileSuccess, classes.uploadInputSuccess)}
          {this.errorMessage(this.state.uploadFileError, classes.uploadImageInputErrors)}
          <Box className={classes.titleContainer}>
            <p className={this.state.docType === "upload"?classes.uploadTitle:classes.uploadTitleReports}>
              {this.state.docType === "upload" ? "Upload Prescription" : "Upload Scans & Reports"}
            </p>
            <CloseIcon data-test-id="closeDocDialog" style={{ cursor: "pointed" }} onClick={() => this.handelPrescriptionDialog(this.state.docType)} />
          </Box>
          <DialogContent style={{ margin: "0px auto" }}>
            <Typography variant="h6" className={classes.uploadLabel} data-test-id="uploadText">{this.state.docType === "upload" ? "Date of Prescription" : "Date of Report"}</Typography>
            <Box className={classes.customDatePickerContainer}>
              <span className={classes.dateInput}>
                <DatePicker
                  data-test-id="date"
                  popperPlacement="bottom-start"
                  popperModifiers={[
                    {
                      name: "offset",
                      options: {
                        offset: [60, 0],
                      },
                    },
                    {
                      name: "preventOverflow",
                      options: {
                        rootBoundary: "viewport",
                        tether: false,
                        altAxis: true,
                      },
                    },
                  ]}
                  calendarClassName={classes.datepickerClass}
                  onChange={this.handleDateChange}
                  className={this.state.dateError ? classes.dateFieldError : classes.dateField}
                  dateFormat="dd/MM/yyyy"
                  value={this.state.date}
                  placeholderText="DD/MM/YYYY"
                  maxDate={new Date()}
                />
              </span>
            </Box>
            {this.errorMessage(this.state.dateError, classes.uploadInputErrors)}
            <Button data-test-id="uplodaDocFromDialog" className={classes.uploadBtn} style={{ textTransform: "capitalize" }} onClick={this.handleButtonClick}>Upload from Device</Button>
            <input
              type="file"
              data-test-id='upload-input'
              ref={this.fileInputRef}
              style={{ display: "none" }}
              onChange={this.handleUploadPrescription}
            />
            {this.errorMessage(this.state.UploadDocError, classes.uploadInputErrors)}
              
            {this.renderSelectedAttachment()}
              
            <Button data-test-id="viewPreviousPrescription" className={classes.uploadBtn} style={{ textTransform: "capitalize" }} onClick={this.openFileattachment}>
            {renderUploadedScanLabel}
             </Button>
            <CustomStyledButton
              data-test-id="submit-doc"
              className={classes.previewBtn}
              style={{ textTransform: "capitalize" }}
              onClick={() => this.uploadDocument(this.state.isPresciption ? this.state.prescription : this.state.scan)}
              label="Submit"
            />
          </DialogContent>
        </Dialog>
        {this.state.openFile && (<Fileattachment navigation={undefined} id={""} docType={this.state.docType} openDialog={this.state.openDialog} handelDialog={this.handleDocDialog} />)}
      </>)
  }

  successMessage = (message: string | undefined, success: any) => {
    return (message ? <FormHelperText className={success}>{message}</FormHelperText> : null)
  };

  surgeryAddBtn = (classes: any) => {
    return (
      <>
        <div className={classes.link} style={{ textDecoration: "none", display: this.state.showSurgeryAddButton ? "flex" : "none" }} onClick={this.handelAddSurgery}>
          + Add
        </div>
      </>
    )
  }

  getMarginTop = (index: number): string | undefined => {
    return index > 0 ? "15px" : undefined;
  };

  checkError = (error:any,classes:any) =>{
    return error ? classes.boxWidthError:classes.boxWidth;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, diseasesList } = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        {this.props.step === 2 && this.state.step !== 3 ? (
          <Box className={classes.patientHeathDtailsComponent}>
            <Box className={classes.homeCureContainer}>
              <img src={homecureLogoBlue} alt="HomeCure Logo" className={classes.healthDetailHomecureLogos} style={{ objectFit: "contain" }} />
            </Box>
            <Box className={classes.healthDetailConatiner}>
              <Box className={classes.healthDetailsSubContainer}>
                <Box className={classes.healthDetailsBackButtons} onClick={() => this.checkIfUserLogin(this.props.userLogin)}>
                  <ArrowBackIosIcon data-test-id="backBtn" className={classes.arrowIcon} onClick={() => this.checkIfUserLogin(this.props.userLogin)} />
                </Box>
                <Box>
                  <Typography variant='h6' className={classes.healthDetailsHeadings}>
                    Health Details
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.uploadForm}>
                <Box className={classes.healthDetailsLinks}>
                  <Box data-test-id="uploadPrescription" className={classes.uploadLink} style={{ textDecoration: "none" }} onClick={() => this.handelPrescriptionDialog('upload')}>
                    <Typography style={{ caretColor:"transparent"}}>Upload Prescriptions</Typography>
                  </Box>
                </Box>
                <Box className={classes.healthDetailsLinks} >
                  <div data-test-id="scanReport" className={classes.uploadLink} style={{ textDecoration: "none" }} onClick={() => this.handelPrescriptionDialog('scan')}>
                    <Typography>Upload Reports & Scans</Typography>
                  </div>
                </Box>
              </Box>
            </Box>
            <Box className={classes.diseaseContaier}>
              <Grid container  spacing={8}>
                <Grid item xl={4} md={4} xs={12}>
                  <Typography variant="h6" className={classes.patientTypo} data-test-id="diseasesText">Do you have any of the commonly occurring diseases?</Typography>
                  <Card className={classes.cardContainer}>
                    <Box className={classes.BoxContainer}>
                      <Box style={{padding:"0px 15px 0px 0px"}}>
                        {this.renderCheckboxes(diseasesList, classes)}
                        {this.otherOptions(classes)}
                      </Box>
                    </Box>
                  </Card>
                  <Box className={classes.patientButtonContainer}>
                    <Box className={classes.btnContain}>
                      <CustomStyledButton
                        data-test-id="saveHealthDetailsDesktop"
                        className={classes.healthDetalSaveBtn}
                        style={{ textTransform: "capitalize" }}
                        onClick={this.submitPatientDetails}
                        label="Save"
                      />
                    </Box>
                    <Box className={classes.skipBtn}>
                      <Button
                        data-test-id="skipPatientHealthDetails"
                        className={classes.skipBtns}
                        style={{ textTransform: "capitalize" }}
                        onClick={this.skipHeathDetailsRegistration}>
                        Skip
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xl={4} md={4} xs={12}>
                  <Typography variant="h6" className={classes.patientTypo}>
                    Medicines you are currently taking?
                  </Typography>
                  {
                    this.state.medicines_attributes.map((medicine: { name: string; days: string }, index: number) => {
                      return (
                        <>
                        <Box key={index} style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                          {this.medicineContainer(classes, medicine, index)}
                        </Box>
                        {this.errorMessage(this.state.healthDetailsError[`medicine${index}`], classes.inputMedecineErrors)}
                        </>
                      );
                    })
                  }
                  <Box className={classes.medicineLink}>
                    <div className={classes.link} style={{ textDecoration: "none", display: this.state.showMedicineAddButton ? "flex" : "none" }} onClick={this.handleAddMedicine}>
                      + Add
                    </div>
                  </Box>
                  <Box className={classes.patientSelectBox}>
                    <Box className={classes.selectPatientField}>
                      <Typography variant="h6" className={classes.patientSize}>
                        Height (cm)
                      </Typography>
                      <TextField
                        data-test-id="height"
                        variant="outlined"
                        placeholder="Enter height"
                        className={this.checkError(this.state.healthDetailsError.height,classes)}
                        value={this.state.height}
                        onChange={this.changeHeight}
                      />
                      {this.errorMessage(this.state.healthDetailsError.height, classes.surgeryinputErrors)}
                    </Box>

                    <Box className={classes.selectPatientField}>
                      <Typography variant="h6" className={classes.patientSize}>
                        Weight (kg)
                      </Typography>
                      <TextField
                        data-test-id="weight"
                        variant="outlined"
                        placeholder="Enter weight"
                        className={this.checkError(this.state.healthDetailsError.weight,classes)}
                        value={this.state.weight}
                        onChange={this.changeWeight}
                      />
                      {this.errorMessage(this.state.healthDetailsError.weight, classes.surgeryinputErrors)}
                    </Box>
                  </Box>
                  <Typography variant="h6" className={classes.typeWrapper}>
                    Do you smoke?
                  </Typography>
                  <Box className={classes.patientSelectBox}>
                    <FormControlLabel
                      data-test-id="smokeYes"
                      className={this.getSmokeClassNames(this.state.smoke)}
                      control={<Checkbox checked={this.state.smoke} className={classes.checkboxWhite} onChange={this.handleSmokeChange} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                      label={"Yes"}
                    />
                    <FormControlLabel
                      data-test-id="smokeNo"
                      className={this.getSmokeClassNames(!this.state.smoke)}
                      control={<Checkbox checked={!this.state.smoke} className={classes.checkboxWhite} onChange={this.handleSmokeChange} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                      label={"No"}
                    />
                  </Box>
                  {this.state.smoke &&
                    <>
                      <Typography variant="h6" className={classes.patientTypo}>
                        Since
                      </Typography>
                      <Box className={classes.patientSelectBoxDiseases}>
                        <Box style={{ width: "48%" }}>{this.CustomSelectFieldMonth(classes.customSelectField, monthsOptions, "Month", "selectSmokeMonths", this.handleSmokeMonthChange, this.state.smoke_months)}</Box>
                        <Box style={{ width: "48%" }}>{this.CustomSelectFieldYear(classes.customSelectField, yearsOptions, "Year", "selectSmokeYears", this.handleSmokeYearChange, this.state.smoke_years)}</Box>
                      </Box>
                    </>
                  }
                  <Typography variant="h6" className={classes.typeWrapper}>
                    Do you drink?
                  </Typography>
                  <Box className={classes.patientSelectBox}>
                    <FormControlLabel
                      data-test-id="drinkYes"
                      className={this.getDrinkClassNames(this.state.drink)}
                      control={<Checkbox checked={this.state.drink} className={classes.checkboxWhite} onChange={this.handleDrinkChange} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                      label={"Yes"}
                    />
                    <FormControlLabel
                      data-test-id="drinkNo"
                      className={this.getDrinkClassNames(!this.state.drink)}
                      control={<Checkbox checked={!this.state.drink} className={classes.checkboxWhite} onChange={this.handleDrinkChange} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                      label={"No"}
                    />
                  </Box>
                  {this.drinkDropdown(this.state.drink, classes)}
                </Grid>
                <Grid item xl={4} md={4} xs={12}>
                  <Typography variant="h6" className={classes.patientTypo}>Any allergies?</Typography>
                  {this.state.allergies_attributes.map((allergy: { name: string }, index: number) => (
                    <>
                      {this.allergyConatiner(classes, allergy, index)}
                      {this.errorMessage(this.state.healthDetailsError[`allergy${index}`], classes.inputAllergyErrors)}
                    </>
                  ))}
                  <Box className={classes.allergyLink}>
                    <div className={classes.link} style={{ textDecoration: "none", display: this.state.showAllergyAddButton ? "flex" : "none" }} onClick={this.handleAddAllergy}>
                      + Add
                    </div>
                  </Box>
                  <Typography variant="h6" className={classes.patientTypo}>Any previous surgery?</Typography>
                  {this.state.surgeries_attributes.map((surgery: { name: string, surgery_months: string, surgery_years: string }, index: number) => (
                    <Box key={index} className={classes.surgeryContainer}>
                      <Box style={{display:"flex",flexDirection:'column'}} className={classes.patientSelectBox}>
                        <TextField
                          data-test-id={`surgeryName${index}`}
                          variant="outlined"
                          placeholder="Surgery name"
                          style={{marginTop: this.getMarginTop(index)}}
                          className={this.state.healthDetailsError[`surgery${index}`]? classes.inputError : classes.boxWidth}
                          value={surgery.name}
                          onChange={(event) => this.handleSurgeryChange(index, "name", event.target.value.trimStart())}
                        />
                      {this.errorMessage(this.state.healthDetailsError[`surgery${index}`], classes.surgeryinputErrors)}
                      </Box>
                      <Typography variant="h6" className={classes.patientTypo}>
                        In
                      </Typography>
                      <Box className={classes.patientSelectBoxSurgery}>
                        <Box style={{ width: "48%" }}>{this.SurgeryCustomSelectField(index, classes.customSelectField, monthsOptions, "Month", `surgeryMonths${index}`, "surgery_months")}</Box>
                        <Box style={{ width: "48%" }}>{this.SurgeryCustomSelectField(index, classes.customSelectField, yearsOptions, "Year", `surgeryYears${index}`,"surgery_years")}</Box>
                      </Box>
                    </Box>
                  ))}
                  <Box className={classes.surgeryLink}>
                    {this.surgeryAddBtn(classes)}
                  </Box>
                  <Typography variant="h6" className={classes.patientTypo} style={{marginTop:"5px"}}>
                    Any previous hospital admission?
                  </Typography>
                  <Box className={classes.patientSelectBox}>
                    <FormControlLabel
                      data-test-id="hospitalAdmissionYes"
                      className={this.state.previous_hospital ? classes.selectedField : classes.unselectedField}
                      control={<Checkbox data-teitalAdmist-id="hospssionYesCheckbox" className={classes.checkboxWhite} checked={this.state.previous_hospital} onChange={this.handlePreviousHospitalChange} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                      label={"Yes"}
                    />
                    <FormControlLabel
                      data-test-id="hospitalAdmissionNo"
                      className={!this.state.previous_hospital ? classes.selectedField : classes.unselectedField}
                      control={<Checkbox data-test-id="hospitalAdmissionNoCheckbox" className={classes.checkboxWhite} checked={!this.state.previous_hospital} onChange={this.handlePreviousHospitalChange} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                      label={"No"}
                    />
                  </Box>
                </Grid>
                <Box className={classes.patientMobileButtonContainer}>
                  <Box className={classes.btnContain}>
                    <CustomStyledButton
                      data-test-id="saveHealthDetailsMobile"
                      className={classes.healthDetalSaveBtn}
                      style={{ textTransform: "capitalize" }}
                      onClick={this.submitPatientDetails}
                      label="Save"
                    />
                  </Box>
                  <Box className={classes.skipBtn}>
                  <Button
                        data-test-id="skipPatientHealthDetails"
                        className={classes.link}
                        style={{ textTransform: "capitalize" }}
                        onClick={this.skipHeathDetailsRegistration}>
                        Skip
                  </Button>
                  </Box>
                </Box>
                
              </Grid>
            </Box>
            <Dialog open={this.state.showDialog} classes={{ paper: classes.paper }} style={{ textAlign: "center" }}>
              <DialogContent className={classes.dialogContent} >
                <p className={classes.dialogText}>
                  Your health details are safely stored.               
                  They are for health personnel and hospitals 
                  from whom you choose to get treated.
                </p>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <CustomStyledButton
                  data-test-id="closePopUp"
                  onClick={this.handleDialogClose}
                  className={classes.dialogButton}
                  style={{ textTransform: "capitalize" }}
                  label={"Okay"}
                />
                 
              </DialogActions>
            </Dialog>
            <CommonMessage 
              data-test-id='message'
              onClose={this.handleCloseMessage}
              open={this.state.openMessage}
              type={this.state.type} 
              title={this.state.messageTitle}
              subTitle={this.state.messageSubTitle}
              />
            {this.uploadDocPopup(classes)}
          </Box>
        ) : (
          <PatientRegisterDetails step={undefined} classes={classes} navigation={this.props.navigation} id={""} google={undefined} />
        )
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(PatientHealthDetails);
// Customizable Area End
