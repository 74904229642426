import * as React from "react";
// Customizable Area Start
import { withStyles, Typography, Box, Button, ThemeProvider, createTheme, Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DashboardController, { Props } from "./DashboardController";
import { styles } from "./styles/styles";
import { OffIcon, OnImage, RightIcon, GreenRightIcon, BackIcon, HomeVisitIcon, TeleconsultationIcon, CallingIcon, HomeIcon, NextArrow, PrevArrow, DownArrow, SOSImage, NoData, UserDefaultIcon } from "./assets";
import { Bar } from "react-chartjs-2";


const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

// Customizable Area End

export class DashboardView extends DashboardController {
    constructor(props: Props) {
        super(props);
    }

    // Customizable Area Start

    async componentDidMount() {
        this.fetchRole()
        this.fetchAyahDashboardDetails()
        this.fetchAyahDashboardTotalBookingDetails()
        this.callGraph()
    }

    months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    callCardDetails = (classes: any, id: number, userDetails?: any) => {
        const date = userDetails?.start_date !== undefined ? userDetails.start_date : userDetails?.date;
        if (!userDetails) return null;
        return (
            <Box className={classes.ayahDetailsContainer} component="div">
                <Box className={classes.ayahDetails} component="div">
                    <Typography variant="h6" className={classes.nameLabel}>
                        {userDetails.patient_name ? userDetails.patient_name : userDetails.patient_profile_name}
                    </Typography>
                    <Typography data-test-id={`showHealthDetailsDialog${id}`} variant="h6" className={classes.HealthDetailsLabel} onClick={() => this.fetchAyahDashboardPaitentHealthDetails(userDetails.id)}>Health Details</Typography>
                </Box>
                <Box className={classes.ayahDetails} component="div">
                    <Typography variant="h6" className={classes.ayahTimingLabel}>{userDetails.start_time} - {userDetails.end_time}</Typography>
                    <Typography variant="h6" className={classes.ayahWeekdayLabel}>{id == 5 ? this.formatDate(date) : this.getWeekdayName(date)}</Typography>
                </Box>
            </Box>
        )
    }

    headerBackButtonProvider = (classes: any, heading: string) => {
        return (
            <Box className={classes.backBtnContainer} component="div">
                <Box className={classes.backBtn} component="div">
                    <img
                        data-test-id="backToPreviousPage"
                        src={BackIcon}
                        className={classes.backIcon}
                        width={"50px"}
                        height={"50px"}
                        onClick={() => this.openDashboard(heading)}
                    />
                </Box>
                <Box className={classes.backBtnTitleContainer} component="div">
                    <Typography variant="h6" className={classes.pageTitle} style={{ textAlign: "center" }}>
                        {heading}
                    </Typography>
                </Box>
            </Box>
        )
    }

    transacationFiltersProvider = (classes: any,) => {
        return (
            <Box className={classes.transactionFiltersContainer} component="div">
                <Box className={classes.allFilterSection} style={{ background: this.state.paymentType === "" ? "#3780E04D" : "transparent", border: this.state.paymentType === "" ? "1px solid #014866" : "1px solid #BABABA" }} component="div">
                    <Typography variant="h6" className={classes.allFilterlabel} onClick={this.setPaymentTypeNull}>
                        All
                    </Typography>
                </Box>
                {this.state.role && (
                    <>
                        <Box className={classes.filterSection} component="div" style={{ background: this.state.paymentType === "offline" ? "#3780E04D" : "transparent", border: this.state.paymentType === "offline" ? "1px solid #014866" : "1px solid #BABABA" }}>
                            <Box className={classes.subFilterSection} component="div" onClick={this.setPaymentTypeOffline}>
                                <img
                                    data-test-id="earingIconImage"
                                    src={HomeIcon}
                                    className={classes.filterBtnIcon}
                                />
                                <Typography variant="h6" className={classes.filterLabel}>
                                    Home Visit
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.filterSection} component="div" style={{ background: this.state.paymentType === "online" ? "#3780E04D" : "transparent", border: this.state.paymentType === "online" ? "1px solid #014866" : "1px solid #BABABA" }}>
                            <Box className={classes.subFilterSection} component="div" onClick={this.setPaymentTypeOnline}>
                                <img
                                    data-test-id="earingIconImage"
                                    src={CallingIcon}
                                    className={classes.filterBtnIcon}
                                />
                                <Typography variant="h6" className={classes.filterLabel}>
                                    Teleconsultation
                                </Typography>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        )
    }

    recentTransaction = (classes: any, index: number, totalBooking: any) => {
        const {
            formatedDateAndTime,
            FormatedDate,
        } = this.cardDateFormat(totalBooking.attributes.created_at)
        return (
            <>
                <Box className={classes.ayahImageContainer} component="div">
                    <img
                        data-test-id="ayahOval"
                        src={this.getPatientProfileImage(totalBooking.attributes.patient_profile_image)}
                        className={classes.ayahProfile}
                    />
                </Box>
                <Box className={classes.transitionInfoSection} component="div">
                    <Box className={classes.ayahDetails} component="div">
                        <Typography variant="h6" className={classes.nameLabel}>
                            {totalBooking.attributes.patient_name ? totalBooking.attributes.patient_name : totalBooking.attributes.patient_profile_name}
                        </Typography>
                        <Typography variant="h6" className={classes.transactionTiming}>{index === 1 ? formatedDateAndTime : FormatedDate}</Typography>
                    </Box>
                    <Typography variant="h6" className={classes.transactionAmt}>
                        ₹{totalBooking.attributes.amount}
                    </Typography>
                </Box>
            </>
        )
    }

    showCard = (classes: any, totalBooking: any, index: number) => {
        const date = totalBooking.attributes?.start_date !== undefined ? totalBooking.attributes.start_date : totalBooking.attributes?.date;
        return (
            <Box className={classes.bookingWrapper} key={index}>
                <Box className={classes.ayahImageContainer}>
                    <img data-test-id="ayahOval0" src={this.getPatientProfileImage(totalBooking.attributes.patient_profile_image)} className={classes.ayahProfile} />
                </Box>
                <Box className={classes.bookingsInfoSection}>
                    <Box className={classes.ayahDetails}>
                        <Typography variant="h6" data-test-id="name" className={classes.nameLabel}>{totalBooking.attributes.patient_name ? totalBooking.attributes.patient_name : totalBooking.attributes.patient_profile_name}</Typography>
                        <Typography variant="h6" data-test-id={`showHealthDetails${index}`} className={classes.BookingHealthDetailsLabel} onClick={() => this.fetchAyahDashboardPaitentHealthDetails(totalBooking.id)}>Health Details</Typography>
                        <Typography variant="h6" data-test-id="time" className={classes.bookingTiming}>{totalBooking.attributes.start_time} - {totalBooking.attributes.end_time} | {this.formatDate(date)}</Typography>
                    </Box>
                    <Typography variant="h6" className={classes.homeVisitLabel}>{totalBooking.attributes?.payment_type === undefined || totalBooking.attributes?.payment_type === "offline" ? "Home Visit" : "Teleconsultation"}</Typography>
                </Box>
            </Box>
        )
    }

    noDataFound = (classes: any) => {
        return (
            <>
                {
                    this.state.loading ? (
                        <>
                            <Box className={classes.noData}>Loading...</Box>
                        </>) : (
                        <>
                            <Box className={classes.noDataDashboardContainer}>
                                <img
                                    src={NoData}
                                    width={60}
                                    height={60}
                                    className={classes.closeIcon}
                                />
                                <Box className={classes.noData}>No results found</Box>
                            </Box>
                        </>)
                }
            </>
        )
    }

    ayahShift = (classes: any, index: number) => {
        const isShiftStarted = this.state.updateShiftStart[index] || false;
        return (
            <>
                <Box className={classes.ayahShiftAction}>
                    <Typography variant="h6" className={classes.shiftText} style={{ textAlign: "center" }}>Start</Typography>
                    {isShiftStarted ? (
                        <span>
                            <img className={classes.icons} data-test-id={`updateIsShiftStartOn${index}`} src={OnImage} width={44} height={22} onClick={() => this.handleUpdateShift(index, 'start')} />
                        </span>
                    ) : (
                        <span>
                            <img data-test-id={`updateIsShiftStartOff${index}`} src={OffIcon} width={44} height={22} className={classes.icons} onClick={() => this.handleUpdateShift(index, 'start')} />
                        </span>
                    )}
                </Box>
                <Box className={classes.ayahShiftAction}>
                    <Typography variant="h6" className={classes.shiftText} style={{ textAlign: "center" }}>End</Typography>
                    {(!isShiftStarted && this.state.updateShiftStart[index] !== undefined) ? (
                        <span>
                            <img className={classes.icons} data-test-id={`updateIsShiftEndOn${index}`} src={OnImage} width={44} height={22} onClick={() => this.handleUpdateShift(index, 'end')} />
                        </span>
                    ) : (
                        <span>
                            <img data-test-id={`updateIsShiftEndOff${index}`} src={OffIcon} width={44} height={22} className={classes.icons} onClick={() => this.handleUpdateShift(index, 'end')} />
                        </span>
                    )}
                </Box>
            </>
        );

    };

    handelBookingButton = (newBooking: any, classes: any, index: number) => {
        return (
            <>
                {this.state.bookingId === newBooking.data.attributes.id ? (
                    <Button
                        data-test-id="otpVerified"
                        variant="contained"
                        color="primary"
                        className={classes.acceptedBtn}
                        style={{ textTransform: "capitalize" }}
                    >
                        <span>
                            <img
                                src={RightIcon}
                                className={classes.icons}
                                width={18}
                                height={13}
                            />
                        </span>
                        <Typography
                            variant="h6"
                            className={classes.acceptedBtnText}
                            style={{ textAlign: "center" }}
                        >
                            Accepted
                        </Typography>
                    </Button>
                ) : (
                    <>
                        <Button
                            data-test-id={`Rejected${index}`}
                            variant="contained"
                            className={classes.declineBtn}
                            style={{ textTransform: "capitalize" }}
                            onClick={() => {
                                this.updateBookingStatus(
                                    newBooking.data.id,
                                    "rejected",
                                    newBooking.data.attributes.id
                                );
                            }}
                        >
                            Decline
                        </Button>
                        <Button
                            data-test-id={`Accepted${index}`}
                            variant="contained"
                            className={classes.acceptBtn}
                            style={{ textTransform: "capitalize" }}
                            onClick={() => {
                                this.updateBookingStatus(
                                    newBooking.data.id,
                                    "accepted",
                                    newBooking.data.attributes.id
                                );
                            }}
                        >
                            Accept
                        </Button>
                    </>
                )}
            </>
        )
    }

    renderBookingList(classes: any) {
        return (
            <>
                {this.state.allBooking?.length > 0 ? (
                    this.state.allBooking.map((bookingData: any, index: number) => (
                        <Box className={classes.upcomingBookingListContainer} component="div" key={index}>
                            <Box className={classes.bookingContainer} component="div">
                                <Box className={classes.ayahInfoContainer} component="div">
                                    <Box className={classes.ayahImageContainer} component="div">
                                        <img data-test-id="ayah" src={this.getPatientProfileImage(bookingData.attributes.patient_profile_image)} className={classes.ayahProfile} />
                                    </Box>
                                    <Box className={classes.ayahInfoSection} component="div">
                                        {this.callCardDetails(classes, 5, bookingData.attributes)}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ))
                ) : (
                    this.noDataFound(classes)
                )
                }
            </>
        )
    }

    healthDetailsContainer = (classes: any) => {
        const medicineCount = this.state.healthDetails?.attributes?.medicines?.length || 0;
        const allergyCount = this.state.healthDetails?.attributes?.allergies?.length || 0;
        const maxCount = Math.max(medicineCount, allergyCount);
        return (
            <DialogContent>
                <Box style={{display:"flex"}}>
                    <Box style={{width:"50%"}}>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Disease Name</Typography>

                            {this.state.healthDetails?.attributes?.diseases?.length > 0 ? (
                                this.state.healthDetails?.attributes.diseases.map((disease: any, index: number) => (
                                    <Typography variant="body2" key={index} className={classes.valueField}>{disease.disease_name}</Typography>
                                ))
                            ) : (
                                <Typography variant="body2" className={classes.valueField}>-</Typography>
                            )}
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Height</Typography>
                            <Typography variant="body2" className={classes.valueField}>{this.state.healthDetails?.attributes?.patient_info.height} cm</Typography>
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Medicine</Typography>
                            {this.state.healthDetails?.attributes?.medicines?.length > 0 ? ([...Array(maxCount)].map((_, index) => (
                                <Box key={index} className={classes.DialogContentSpace}>
                                    <Typography variant="body2" className={classes.valueField} style={{ height: "36px" }}>
                                        {index < medicineCount ? `${index + 1}. ${this.state.healthDetails.attributes.medicines[index].name}` : '-'}
                                    </Typography>
                                </Box>
                            ))
                            ) : (
                                maxCount > 0 ? ([...Array(maxCount)].map((_, index) => (
                                    <Box key={index} className={classes.DialogContentSpace} data-test-id="medicineNotFound"><Typography variant="body2" className={classes.valueField} style={{ height: "36px" }}>-</Typography></Box>
                                ))) : (
                                    <Box className={classes.DialogContentSpace}>
                                        <Typography variant="body2" data-test-id="medicines" className={classes.valueField} style={{ height: "36px" }}>-</Typography>
                                    </Box>
                                )
                            )}
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Previous Surgery</Typography>
                            <Typography variant="body2" className={classes.valueField}>{this.state.healthDetails?.attributes?.surgeries.length > 0 ? "Yes" : "No"}</Typography>
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Smoke</Typography>
                            <Typography variant="body2" className={classes.valueField}>No</Typography>
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Prescription</Typography>
                            <Typography variant="body2" className={classes.valueField}>Not available</Typography>
                        </Box>
                    </Box>
                    <Box style={{width:"50%"}}>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>MM / YYYY</Typography>
                            {this.state.healthDetails?.attributes?.diseases?.length > 0 ? (
                                this.state.healthDetails?.attributes.diseases.map((disease: any, index: number) => (
                                    <Typography variant="body2" className={classes.valueField}>{this.getMonthNumber(disease.disease_months)} / {disease.disease_years}</Typography>
                                ))
                            ) : (
                                <Typography variant="body2" className={classes.valueField}>-</Typography>
                            )}
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Weight</Typography>
                            <Typography variant="body2" className={classes.valueField}>{this.state.healthDetails?.attributes?.patient_info.weight} Kg</Typography>
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Allergies</Typography>
                            {this.state.healthDetails?.attributes?.allergies?.length > 0 ? (
                                [...Array(maxCount)].map((_, index) => (
                                    <Box key={index} className={classes.DialogContentSpace} >
                                        <Typography variant="body2" className={classes.valueField} style={{ height: "36px" }}>
                                            {index < allergyCount ? `${index + 1}. ${this.state.healthDetails.attributes.allergies[index].name}` : '-'}
                                        </Typography>
                                    </Box>
                                ))
                            ) : (
                                maxCount > 0 ? ([...Array(maxCount)].map((_, index) => (
                                    <Box key={index} className={classes.DialogContentSpace} data-test-id="allergiesNotFound">
                                        <Typography variant="body2" className={classes.valueField} style={{ height: "36px" }}>-</Typography>
                                    </Box>
                                ))) : (
                                    <Box className={classes.DialogContentSpace}  data-test-id="allergie">
                                        <Typography variant="body2" className={classes.valueField} style={{ height: "36px" }}>-</Typography>
                                    </Box>
                                )
                            )}
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Previous Hospital</Typography>
                            <Typography variant="body2" className={classes.valueField}>{this.state.healthDetails?.attributes?.patient_info.previous_hospital ? "Yes" : "No"}</Typography>
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Drink</Typography>
                            <Typography variant="body2" className={classes.valueField}>Yes</Typography>
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Reports</Typography>
                            <Typography variant="body2" className={classes.valueField}>Not available</Typography>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        )
    }

    seeAllData = (dataFound: any) => {
        return {
            textAlign: "center" as "center",
            color: dataFound?.length > 0 ? "#3780E0" : "#808080",
            cursor: dataFound?.length > 0 ? "pointer" : "unset" as "unset" | "pointer",
        }
    }

    showHorizontalLine = (classes: any, index: number) => {
        return (<>
            {index < Math.min(this.state.totalBookingDetails.length, 10) - 1 && (
                <Box className={classes.horizontalLine}></Box>
            )}</>)
    }

    renderHorizontalLine = (index:number)=>{
        const {classes} = this.props;
        return index === 0 && this.showHorizontalLine(classes, index)
    }

    recentTransactionSelection = (classes: any) => {
        return (<>
            <Box className={classes.monthFilterContainer} component="div">
                <Box className={classes.changeMonthBtn} component="div">
                    {this.state.selectedMonth > 0 && (
                        <img
                            data-test-id="backwardIcon"
                            src={PrevArrow}
                            className={classes.backIcon}
                            width={8}
                            height={13}
                            onClick={() => this.selectedPreviousMonth()}
                        />
                    )}
                </Box>
                <Typography variant="h6" className={classes.selectFilter}>
                    {this.months[this.state.selectedMonth]}
                </Typography>
                <Box className={classes.changeMonthBtn} component="div">
                    {this.state.selectedMonth < 11 && (
                        <img
                            data-test-id="forwardIcon"
                            src={NextArrow}
                            className={classes.backIcon}
                            height={13}
                            width={8}
                            onClick={() => this.selectedNextMonth()}
                        />
                    )}
                </Box>
            </Box>
            <Box className={classes.yearFilterContainer} component="div">
                <Box data-test-id="cars" className={classes.selectFilter}>
                    {this.state.selectedYear}
                </Box>
                {this.state.showYear && (
                    <Box
                        className={classes.yearBox}
                    >
                        {Array.from({ length: 41 }, (_, index) => 2010 + index).map((year, index) => (
                            <Box
                                key={year}
                                style={{
                                    padding: "5px 0",
                                    textAlign: "center",
                                    cursor: "pointer",
                                }}
                                data-test-id={`selectedYear${index}`}
                                onClick={() => this.selectedYear(year)}
                            >
                                {year}
                            </Box>
                        ))}
                    </Box>
                )}

                <Box className={classes.changeMonthBtn} component="div">
                    <img
                        data-test-id="selectYear"
                        src={DownArrow}
                        className={classes.dropDown}
                        width={13}
                        height={10}
                        onClick={() => { this.setState({ showYear: !this.state.showYear }) }}
                    />
                </Box>
            </Box></>)
    }

    getPatientProfileImage(patient_profile_image: string | undefined) {
        return patient_profile_image || UserDefaultIcon
    }

    callTeleconsultation = (classes: any) => {
        return (
            <>
                {this.state.role &&
                    (
                        <Box className={classes.earningSection} component="div">
                            <Box className={classes.earningSubSection} component="div">
                                <>
                                    <Box className={classes.earningTypeImageContainer} component="div">
                                        <img
                                            data-test-id="earingIconImage"
                                            src={TeleconsultationIcon}
                                            className={classes.earningTypeImage}
                                            width={"70px"}
                                        />
                                    </Box>
                                    <Box className={classes.earningTypeSection} component="div">
                                        <Typography variant="h6" className={classes.earnings}>
                                            ₹{this.state.onlineTotalMoney !== null ? this.state.onlineTotalMoney : 0}
                                        </Typography>
                                        <Typography variant="h6" className={classes.type}>
                                            Teleconsultation
                                        </Typography>
                                    </Box>
                                </>
                            </Box>
                        </Box>
                    )}
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const options = {
            responsive: true,
            legend: {
                display: false,
            },
            tooltips: {
                enabled: true, 
                callbacks: {
                  label: this.tooltipItem,
                },
              },
            type: "bar",
            scales: {
                xAxes: [
                    {
                        BoxLines: {
                            display: false,
                        },
                        ticks: {
                            fontSize: 14,
                            fontFamily: "SF Pro Text Regular",
                            color: "#808080",
                            padding: -10,
                        },
                    },
                ],
                yAxes: [
                    {
                        BoxLines: {
                            drawBorder: false,
                            color: "#D8D8D8",
                            borderDash: [5, 5],
                        },
                        ticks: {
                            callback: this.formatYAxisTicks,
                            fontSize: 14,
                            fontFamily: "SF Pro Text Regular",
                            color: "#808080",
                            padding: 20,
                            min: 0, 
                            max: 5000,
                            stepSize: 1000, 
                          },
                        beginAtZero: true,
                        offset: true,
                    },
                ],

            }
        };

        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>

                    {
                        this.state.currentPage == "Dashboard" &&
                        <>
                            <Box className={classes.hidescroll}>
                                <Box>
                                    <Box className={classes.ayahDashboardMainContainer} component="div">
                                        <Box className={classes.topTotalMainContainer} component="div">
                                        <Box className={classes.cardWrapper}>
                                            <Box data-test-id="showTotalBooking" className={classes.totalBookingContainer} component="div" onClick={() => this.showTotalBooking()}>
                                                <Box className={classes.totalBookingContent} component="div">
                                                    <Typography variant="h6" className={classes.titleNumbersBooking} style={{ textAlign: "center" }}>
                                                        {this.state.ayahDashboardDetails.total_booking}
                                                    </Typography>
                                                    <Typography variant="h6" className={classes.titleTotalBooking}>
                                                        Total Bookings
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box data-test-id="showTotalMoney" className={classes.totalBookingContainer} component="div" onClick={() => this.showTotalMoney()}>
                                                <Box className={classes.totalBookingContent} component="div">
                                                    <Typography variant="h6" className={classes.titleNumbersBooking} style={{ textAlign: "center" }}>
                                                        ₹{parseInt(this.state.ayahDashboardDetails.total_money)}
                                                    </Typography>
                                                    <Typography variant="h6" className={classes.titleTotalBooking}>
                                                        Total Money
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            </Box>
                                            <Box className={classes.sosImageContainer} component="div">
                                                <img
                                                    data-test-id="ayahOval"
                                                    src={SOSImage}
                                                    className={classes.SOS}
                                                    width={"85px"} />
                                            </Box>
                                        </Box>
                                        <Box className={classes.cardContainer}>
                                            <Box className={classes.newBookingMainContainer} component="div">
                                                <Box className={classes.newBookingHeadingContainer} component="div">
                                                    <Typography variant="h6" className={classes.titleBookingType} style={{ textAlign: "center" }}>
                                                        New Bookings
                                                    </Typography>
                                                    <Typography variant="h6" className={classes.titleSeeAll} style={this.seeAllData(this.state.ayahDashboardDetails?.new_bookings)}>
                                                        See All
                                                    </Typography>
                                                </Box>
                                                {this.state.ayahDashboardDetails?.new_bookings?.length > 0 ? (
                                                    this.state.ayahDashboardDetails.new_bookings.map((newBooking: any, index: number) => (
                                                        <Box key={index} className={classes.allBookingContainer}>
                                                            <Box className={classes.bookingContainer}>
                                                                <Box className={classes.ayahInfoContainer}>
                                                                    <Box className={classes.ayahImageContainer}>
                                                                        <img
                                                                            data-test-id="ayahOval"
                                                                            src={this.getPatientProfileImage(newBooking.data.attributes.patient_profile_image)}
                                                                            className={classes.ayahProfile}
                                                                        />
                                                                    </Box>
                                                                    <Box className={classes.ayahInfoSection}>
                                                                        {this.callCardDetails(classes, 1, newBooking.data.attributes)}
                                                                    </Box>
                                                                </Box>
                                                                <Box className={classes.ayahActionButtonsContainer} component="div">
                                                                    <Box className={classes.ayahtBtnContainer} component="div">
                                                                        {this.handelBookingButton(newBooking, classes, index)}
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    ))
                                                ) : (
                                                    this.noDataFound(classes)
                                                )}
                                            </Box>
                                            <Box className={classes.newBookingMainContainer}>
                                                <Box className={classes.newBookingHeadingContainer}>
                                                    <Typography variant="h6" className={classes.titleBookingType} style={{ textAlign: "center" }} >Upcoming Bookings</Typography>
                                                    <Typography variant="h6" className={classes.titleSeeAll} style={this.seeAllData(this.state.ayahDashboardDetails?.upcoming_bookings)} data-test-id="showUpcomingBooking" onClick={() => this.showAllBooking("Upcoming Bookings")}>See All</Typography>
                                                </Box>
                                                {this.state.ayahDashboardDetails?.upcoming_bookings?.length > 0 ? (
                                                    this.state.ayahDashboardDetails.upcoming_bookings.slice(0, 2).map((upcomingBooking: any, index: number) => (
                                                        <Box key={index} className={classes.allBookingContainer}>
                                                            <Box className={classes.bookingContainer}>
                                                                <Box className={classes.ayahInfoContainer}>
                                                                    <Box className={classes.ayahImageContainer}>
                                                                        <img src={this.getPatientProfileImage(upcomingBooking.data.attributes.patient_profile_image)} className={classes.ayahProfile} />
                                                                    </Box>
                                                                    <Box className={classes.ayahInfoSection}>
                                                                        <>{this.callCardDetails(classes, 3, upcomingBooking.data.attributes)}</>
                                                                        <Box className={classes.ayahShiftContainer}>
                                                                            {this.ayahShift(classes, index)}
                                                                        </Box>
                                                                        <Box className={classes.ayahContactBtnContainer} component="div">
                                                                            <Button data-test-id={`patientContactBtn${index}`} variant="contained" className={classes.contactBtn} style={{ textTransform: "capitalize", width: "100%" }}
                                                                                onClick={() => this.showEmergencyContact()}>Patient’s Emergency Contact</Button>
                                                                        </Box>
                                                                    </Box>

                                                                </Box>
                                                                <Box data-test-id="" className={classes.ayahActionButtonsContainer} >
                                                                    <Box className={classes.ayahtBtnContainer} >
                                                                        <Button data-test-id="otpVerified" variant="contained" color="primary" className={classes.acceptedBtn} style={{ textTransform: "capitalize" }}>
                                                                            <span> <img src={RightIcon} className={classes.icons} width={18} height={13} /></span>
                                                                            <Typography variant="h6" className={classes.acceptedBtnText} style={{ textAlign: "center" }}>Accepted</Typography>
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    ))
                                                ) : (
                                                    this.noDataFound(classes)
                                                )}
                                            </Box>
                                            <Box className={classes.newBookingMainContainer}>
                                                <Box className={classes.newBookingHeadingContainer}>
                                                    <Typography variant="h6" className={classes.titleBookingType} style={{ textAlign: "center" }} data-test-id="showUpPastBooking">Past Bookings</Typography>
                                                    <Typography variant="h6" className={classes.titleSeeAll} style={this.seeAllData(this.state.ayahDashboardDetails?.past_booking)} data-test-id="showPastBooking" onClick={() => this.showAllBooking("Past Bookings")}>See All</Typography>
                                                </Box>
                                                {this.state.ayahDashboardDetails?.past_booking?.length > 0 ? (
                                                    this.state.ayahDashboardDetails.past_booking.slice(0, 2).map((pastBooking: any, index: number) => (
                                                        <Box key={index} className={classes.allBookingContainer}>
                                                            <Box className={classes.bookingContainer}>
                                                                <Box data-test-id="pastbooking" className={classes.ayahInfoContainer}>
                                                                    <Box className={classes.ayahImageContainer}>
                                                                        <img src={this.getPatientProfileImage(pastBooking.data.attributes.patient_profile_image)} className={classes.ayahProfile} />
                                                                    </Box>
                                                                    <Box data-test-id="pastbooking" className={classes.ayahInfoSection}>
                                                                        {this.callCardDetails(classes, 4, pastBooking.data.attributes)}
                                                                    </Box>
                                                                </Box>
                                                                <Box className={classes.ayahActionButtonsContainer} component="div">
                                                                    <Box className={classes.ayahtBtnContainer} component="div">
                                                                        <Button data-test-id="otpVerified" variant="contained" color="primary" className={classes.completedBtn} style={{ textTransform: "capitalize" }}>
                                                                            <span>
                                                                                <img
                                                                                    data-test-id="ayahOval"
                                                                                    src={GreenRightIcon}
                                                                                    className={classes.icons}
                                                                                    width={"18px"}
                                                                                    height={"13px"} />
                                                                            </span>
                                                                            <Typography variant="h6" className={classes.completedBtnText} style={{ textAlign: "center" }}>
                                                                                Completed
                                                                            </Typography>
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    ))
                                                ) : (
                                                    this.noDataFound(classes)
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Dialog
                                open={this.state.openDialog}
                                className={classes.dialogBoxWeb}
                                PaperProps={{
                                    style: {
                                        borderRadius: "10px",
                                        maxWidth: "420px",
                                        width: "420px",
                                        height: "263px",
                                    },
                                }}
                                disableScrollLock
                            >
                                <DialogContent className={classes.dialogBoxContentMainContainer}>
                                    <Box className={classes.dialogBoxContentContainer}>
                                        <Typography variant="h6" className={classes.dialogContentText} style={{ textAlign: "center" }}>
                                            Contact Patient’s
                                        </Typography>
                                        <Typography variant="h6" className={classes.dialogContentText} style={{ textAlign: "center" }}>
                                            Emergency phone Number
                                        </Typography>
                                        <Typography variant="h6" className={classes.dialogConfirmText} style={{ textAlign: "center" }}>
                                            Are you Sure?
                                        </Typography>
                                    </Box>
                                    <Box className={classes.capturedBtnContainer}>
                                        <Button data-test-id="patientDialogNoBtn" variant="contained" color="primary" className={classes.dialogNoBtn} style={{ textTransform: "capitalize" }} onClick={() => this.showEmergencyContact()}>No</Button>
                                        <Button data-test-id="patientDialogYesBtn" variant="contained" color="primary" className={classes.dialogYesBtn} style={{ textTransform: "capitalize" }}>Yes</Button>
                                    </Box>
                                </DialogContent>
                            </Dialog>
                        </>
                    }
                    {
                        this.state.currentPage == "Total Money"
                        &&
                        <Box className={classes.upcomingBookingContainer}>
                            <Box >
                                <Box className={classes.earningDashboardMainContainer} component="div">
                                    <>{this.headerBackButtonProvider(classes, "Total Money")}</>
                                    <Box className={classes.totalEarningsContainer} component="div">
                                        <Box className={classes.earningSection} component="div">
                                            <Box className={classes.earningSubSection} component="div">
                                                <Box className={classes.earningTypeImageContainer} component="div">
                                                    <img
                                                        data-test-id="earingIconImage"
                                                        src={HomeVisitIcon}
                                                        className={classes.earningTypeImage}
                                                        width={"72px"}
                                                    />
                                                </Box>
                                                <Box className={classes.earningTypeSection} component="div">
                                                    <Typography variant="h6" className={classes.earnings}>
                                                        ₹{this.state.offlineTotalMoney !== null ? this.state.offlineTotalMoney : 0}
                                                    </Typography>
                                                    <Typography variant="h6" className={classes.type}>
                                                        Home Visit
                                                    </Typography>
                                                </Box>
                                            </Box>

                                        </Box>
                                        {this.callTeleconsultation(classes)}
                                    </Box>

                                    <Box className={classes.monthEarningMainContainer} component="div">
                                        <Typography variant="h6" className={classes.monthEarningTitle}>
                                            Monthly Earnings
                                        </Typography>
                                        <Box className={classes.graphContainer} component="div">
                                            <Bar
                                                data={this.state.graphData}
                                                width={undefined}
                                                height={undefined}
                                                options={options}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className={classes.recentTransactionsMainContainer} component="div">
                                        <Box className={classes.newBookingHeadingContainer} component="div">
                                            <Typography variant="h6" className={classes.titleBookingType} style={{ textAlign: "center" }} data-test-id="updateBooking">Recent Transactions</Typography>
                                            <Typography
                                                variant="h6"
                                                className={classes.titleSeeAll}
                                                style={this.seeAllData(this.state.totalBookingDetails)}
                                                data-test-id="seeAllTransaction"
                                                onClick={() => {
                                                    this.showRecentTransaction()
                                                }}
                                            >
                                                See All
                                            </Typography>
                                        </Box>
                                        <Box className={classes.allTransactionContainer} component="div">
                                            {this.state.totalBookingDetails?.length > 0 ? (
                                                this.state.totalBookingDetails.slice(0, 2).map((totalBooking: any, index: number) => (
                                                    <Box key={index}>
                                                        <Box data-test-id={`transaction${index}`} className={classes.transactionContainer}>
                                                            {this.recentTransaction(classes, 1, totalBooking)}
                                                        </Box>
                                                        {this.renderHorizontalLine(index)}
                                                    </Box>
                                                ))
                                            ) : (
                                                this.noDataFound(classes)
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    }
                    {
                        this.state.currentPage == "Recent Transactions"
                        &&
                        <Box className={classes.recentTransaction}>
                            <Box >
                                <Box className={classes.earningDashboardMainContainer} component="div">
                                    <>{this.headerBackButtonProvider(classes, "Recent Transactions")}</>
                                    <>{this.transacationFiltersProvider(classes,)}</>

                                    <Box className={classes.timeFilterMainContainer} component="div">
                                        <Box className={classes.timeFilterContainer} component="div">
                                            {this.recentTransactionSelection(classes)}
                                        </Box>
                                        <Typography variant="h6" className={classes.filterBottomText}>
                                            Last update few minutes ago
                                        </Typography>
                                    </Box>
                                    {this.state.totalBookingDetails?.length > 0 && (() => {
                                        const groupedBookings: { [key: string]: any[] } = {};
                                        this.state.totalBookingDetails.forEach((totalBooking: any) => {
                                            const createdDate = this.formatBookingDate(totalBooking.attributes.created_at);

                                            if (!groupedBookings[createdDate]) {
                                                groupedBookings[createdDate] = [];
                                            }
                                            groupedBookings[createdDate].push(totalBooking);
                                        })
                                        return Object.keys(groupedBookings).map((date, index) => (
                                            <Box key={index} className={classes.dayTransactionsListContainer} component="div">
                                                <Typography variant="h6" className={classes.dayTransactionsListTitle} data-test-id="updateBooking">
                                                    {date}
                                                </Typography>
                                                <Box className={classes.allTransactionContainer} component="div">
                                                    {groupedBookings[date].map((totalBooking, subIndex) => (
                                                        <Box key={subIndex}>
                                                            <Box data-test-id={`allTransaction${subIndex}`} className={classes.transactionContainer}>
                                                                {this.recentTransaction(classes, 2, totalBooking)}
                                                            </Box>
                                                            <Box className={classes.horizontalLine}></Box>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        ));
                                    })()}
                                </Box>
                            </Box>
                        </Box>
                    }
                    {
                        this.state.currentPage == "Total Bookings"
                        &&
                        <Box className={classes.reservation}>
                            <Box>
                                <Box className={classes.earningDashboardMainContainer}>
                                    {this.headerBackButtonProvider(classes, "Total Bookings")}
                                    {this.transacationFiltersProvider(classes)}
                                    <Box className={classes.totalBookingListContainer}>
                                        {this.state.totalBookingDetails?.length > 0 ? (
                                            this.state.totalBookingDetails.map((totalBooking: any, index: number) => (
                                                this.showCard(classes, totalBooking, index)
                                            ))
                                        ) : (
                                            this.noDataFound(classes)
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    }
                    {
                        this.bookingTypeStatus() && (
                            <>
                                <Box className={classes.upcomingBookingContainer}>
                                    <Box>
                                        <Box className={classes.earningDashboardMainContainer} component="div">
                                            {this.headerBackButtonProvider(classes, this.state.currentPage)}
                                            {this.renderBookingList(classes)}
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        )
                    }
                    <Dialog open={this.state.isHealthDetailsDialogOpen} PaperProps={{ style: { borderRadius: "15px", maxWidth: "480px", width: "480px", height: "656px", overflowY: "auto" } }}>
                        <Box className={classes.dialogWrapper}>

                            <Box className={classes.healthDetailsDialogTitleConatiner}>
                                <Typography className={classes.dialogTitle}>
                                    Health Details
                                </Typography>
                                <CloseIcon data-test-id="closeHealthDetailsAyahDialog" onClick={this.handleCloseHealthDetailsDialog} />
                            </Box>
                            {this.healthDetailsContainer(classes)}
                        </Box>
                    </Dialog>

                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(DashboardView);
// Customizable Area End