// Customizable Area Start
import * as React from "react";
import { Grid, Box, withStyles, Typography, TextField, Button, FormHelperText, InputAdornment, Popover, Checkbox, SvgIcon, Chip, Slider, Dialog, ClickAwayListener, DialogContent, Card, CardContent } from "@material-ui/core";
import { styles } from "./styles/PhoneNumberRegistration";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
import { homecureTransparent, pexelsKarolina, MiscGreen, MiscGray, DownArrow, UpwardArrow, ImageIcons, Edit, camera, gallery, EmailIcon } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import CountDownTimer from "../../../components/src/CountDownTimer.web";
import { otp_success, verifiedEmail } from "../../customisableuserprofiles/src/assets";
import LocationView from "../../location/src/LocationView.web"
import FeeEngine from "../../pricingengine/src/FeeEngine.web";
import CommonMessage from "../../../components/src/CommonMessage";
import CustomStyledButton from "../../../components/src/CustomStyledButton.web";

const HPCustomsCheckboxIconChecked = () => (
  <SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
    <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </SvgIcon>
);

const HPCustomsCheckboxIconUnChecked = () => (
  <SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/>
  </SvgIcon>
);


const CustomSlider = withStyles({
  root: {
    color: "#bcb9b9;",
  },
  track: {
    height: 4,
    borderRadius: 2,
    color: "#02A405",
  },
  thumb: {
    color: "#02A405"
  },
})(Slider);

const genderOptions = [
  { key: 1, value: "Male" },
  { key: 2, value: "Female" },
  { key: 3, value: "Other" },
];

// Customizable Area End

export class HealthCarePersonalRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    try {
    await Promise.all([this.getHospitalList(), this.getLanguagesList(), this.getSpecializationsList(), this.getDepartmentsList()]);
      if(this.props.userLogin){
        await this.fetchDoctorData();
      }else{
      const hpData = await this.getHpData();
      this.setHpDate(hpData)
      }
    }catch (errors) {
      this.handleShowMessage("error","Error fetching data:", "Something went wrong")
    }
  }

  showErrorField = (error: string | undefined, classes: any, keyIndex?: string) => {
    if (keyIndex == "language") {
      return (error ? classes.languageBoxError : classes.languageBox)
    }

    return (error ? classes.errorRegisterInputFields : classes.registerInputFields)
  };

  showSelectErrorField = (error: string | undefined, classes: any, keyIndex?: string) => {
    return (error ? classes.hpErrorRegisterInputFields : classes.hpRegisterInputFields)
  };

  errorMessage =(error:string|undefined, inputError:any) => {
    return (error ? <FormHelperText data-test-id="error" className={inputError}>{error}</FormHelperText> : null)
  };

  getHpClassName=(classes: any, error: string | undefined) => {
    const {isUploadDoc} = this.state;
    if (isUploadDoc) 
    return error ? classes.registrationTextFieldError : classes.registrationTextField
    return (error ? classes.hpUploadErrorRegisterInputFields : classes.hpUploadRegisterInputFields)
  }

  getHpPlaceholder=() => {
    const {isUploadDoc} = this.state;
    if (!isUploadDoc) 
    return "Upload registration certificate";
    return "";
  }

  getHpInputProps=(classes: any) => {
    const {isUploadDoc, image} = this.state;
    if (isUploadDoc) {
      return (
        <img
          className={classes.uploadImage}
          style={{ objectFit: "cover" }}
          src={image}
        />
      );
    }
    return null;
  };

  callDegreeCollgeDetails = (index: number, classes: any, eduDetail: { degree: string, college: string, degreeCursor: boolean, collegeCursor: boolean }) => {
    const educationDetail = this.state.educationDetails;
      const newEducationDetails = { degree:"", college:"", degreeCursor:false, collegeCursor: false };
      const lastEducationDetails = educationDetail.length - 1;
      let degreeConcate = classes.degreeConcate
    const noErrors = this.state.hpDocDetailsErr.degree === '' && this.state.hpDocDetailsErr.college === ''  
    if(noErrors && eduDetail.degreeCursor && eduDetail.collegeCursor && lastEducationDetails === index && eduDetail.degree !== "" && eduDetail.college !== "" ){
      const updatedDetail = [...educationDetail, newEducationDetails];
        this.setState({
          degree:"",
          college:"",
          educationDetails: updatedDetail,
        });
    }

    if(educationDetail.length - 2 === index){
      degreeConcate = classes.degreeConcateLast
    }

    return (
      <>
        {eduDetail.degree !== "" && eduDetail.college !== "" && eduDetail.degreeCursor && eduDetail.collegeCursor && (
          <Box className={classes.hpSelectCheckbox}>
            <Box
              data-test-id={`hpDegrees${index}`}
              className={degreeConcate}
              onClick={() => this.openEditable(index)}
            >
            {eduDetail.degree.trim()}&nbsp;<span style={{ color: "#808080 !important", fontFamily:"SF Pro Text Regular !important" }}>from</span>&nbsp;{eduDetail.college.trim()}
            </Box>
          </Box>
        )}
      </>
    );
  };

  getFontWeightStyle = (selectedValue: any, targetValue: string, keyIndex?:string) => {
    if(keyIndex==="language") {
      const matchedLanguage = selectedValue.some((language: string) => language === targetValue);
      return { fontFamily: matchedLanguage ? 'SF Pro Text Medium' : 'SF Pro Text Regular' };
    }  
    return { fontFamily: selectedValue === targetValue ?  'SF Pro Text Medium' : 'SF Pro Text Regular' };
  };

  getConnectHospitalProps = (connectHospital: boolean, classes: any) => {
    const connectHospitalClass = connectHospital
      ? classes.hpConnectHospitalFields
      : classes.hpConnectHospitalFieldsGray;
    const connectHospitalSrc = connectHospital ? MiscGreen : MiscGray;

    return { connectHospitalClass, connectHospitalSrc };
  };

  selectLanguageText = (selectedLanguages: any, classes: any) => {
    return (
      <>
        {selectedLanguages.length === 0 && (
          <span className={classes.selectLanguagePlaceholder}>Enter languages</span>
        )}
      </>
    )
  }

  heading = this.props.userLogin ? "Edit Profile" : "Register";
  showProfile = this.props.userLogin ? "block" : "none";
  showText = !this.props.userLogin ? "block" : "none";
  renderAddEducationLink = (state:any,classes:any) => {
    if (!state.hpDocDetailsErr.degree && !state.hpDocDetailsErr.college) {
      return (
        <Box className={classes.addDegreeField}>
          <div data-test-id="clickAdd" className={classes.link} style={{ textDecoration: "none", display: "flex" }} onClick={this.addEducationDetails}>
            + Add
          </div>
        </Box>
      );
    } else {
      return null; 
    }
  }

  isUpload = (isUploadDoc:any) => {
    return !isUploadDoc;
  }

  hpDoctorLanguageContainer = (selectedLanguages:any,classes:any) => {
    return selectedLanguages.length>0 ? classes: "";
  }

  enableDialogBox = (enableWeb:any,classes:any) => {
    const showDialog =  enableWeb ? classes.webDialogBox : classes.dialogBox;
    return showDialog;
  } 

  callPreviousData = (state:any, index:number) => {
   const isTrue = state.isEditing && state.editableIndex === JSON.stringify(index) && index !== state.educationDetails.length - 1 ;
   return isTrue;
  } 

  hospitalData = (state:any) => {
    const value = state.hospitalList
    .find((hospital: any) => hospital.id === state.selectedHospitals)?.attributes.name || "";
    return value;
  }

  specializationsData = (state:any) => {
    const value = state.specializationsList
    .find((specialization: any) => specialization.id === state.selectedSpecializations)
    ?.attributes.name || ""
    return value;
  }

  languageFieldValidation = (error:any,state:any,classes:any) => {
    return(
      <>
      {!error && !state.openLanguageDropdown && (
        <p data-test-id="languageText" className={classes.languageText}>
          (Please select a maximum of 4 languages)
        </p>)}
      </>
    )
  }

  generateDoctorResendComponent = (text: string, testId: string) => (
    <Typography
      variant="h6"
      style={{color:
          this.state.resendCounter === 5  || !this.state.isResendActive 
            ? "#292929" : "#014866",
        fontSize: "14px",fontFamily: "SF Pro Text Medium",textTransform: "none", fontWeight: 500,
        cursor:
          this.state.resendCounter === 5 || !this.state.isResendActive
            ? "not-allowed" : "pointer",
        opacity:
          this.state.resendCounter === 5 || !this.state.isResendActive
            ? 0.5 : 1,
        caretColor: "transparent"
      }}
      data-test-id={testId}
    > {text}
    </Typography>
  );


  updateOtpError = (isError:any,classes:any) => {
    return isError ? classes.inputErrorFieldVerifyOtp: classes.inputFieldOtpVerify
  }
  getDepartmentName = (departmentsList: any[], selectedDepartments: any): string => {
    const department = departmentsList.find((dept: any) => dept.id === selectedDepartments);
    return department ? department.attributes.name : "";
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { hpDocDetailsErr } = this.state;
    const { connectHospitalClass, connectHospitalSrc } = this.getConnectHospitalProps(this.state.connectHospital, classes);
    // Customizable Area End

    return (
     // Customizable Area Start
     <>
     {
      !this.state.showGoogleMap ?
      (
     <Grid container >
       <>
         <Grid item xl={4} md={4} sm={12} xs={12} className={`${classes.gridContainer} ${classes.fixedGrid}`}>
           <Box
             component="div"
             data-test-id="hpLogoContainer"
             sx={{
               height: "100%"
             }}
             className={classes.container}
             style={{
               backgroundSize: "cover",
               backgroundRepeat: "no-repeat",
               backgroundImage: `url(${pexelsKarolina})`,
               backgroundPosition: "center",
             }}
           >
             <Box className={classes.homeCureLogosContainer}>
               <img
                 data-test-id="logo"
                 src={homecureTransparent}
                 alt="HomeCure Logo"
                 className={classes.homecureLogos}
                 style={{ objectFit: "contain" }}
               />
             </Box>
           </Box>
         </Grid>
         <Grid item xl={8} md={8} sm={12} xs={12} className={classes.gridContainer}>
           <Box className={classes.patientRegistrationContainer}>
             <Box className={classes.patientLabelContainer}>
               <p className={classes.registrationHeading}>
                 {this.heading}
               </p>
               <Box data-test-id="setLocation" className={classes.setLocationContainer}
                 onClick={this.openSetLocationPage}
               >
                 <p data-test-id="setLocation" className={classes.setLocation} onClick={this.openSetLocationPage}>
                   Set Location
                 </p>
               </Box>
             </Box>
             <Box style={{display:this.showProfile}}>
             <Box className={classes.editIconsConatiners}>
                 <Box>
                     <img data-test-id="doctorProfile" className={classes.avatarEdit} src={this.state.profilePicture} onClick={() => this.backToProfileView()}/>
                     <img data-test-id="editProfile" className={classes.avatarEditIcons} src={Edit} onClick={this.handleOpenDialogs}/>
                 </Box>
             </Box>
             </Box>
             <Box style={{display:this.showText}}>
             <Typography variant="h6" className={classes.patientHeading}>
               Let's get you all set up so you can verify and manage your account.
             </Typography>
             </Box>
             <Grid container spacing={4}>
               <Grid item xl={6} md={6} sm={6} xs={12} className={classes.degreeContainerSpacing}>
                 <Box>
                   <TextField
                     variant="outlined"
                     data-test-id="connectHospital"
                     className={connectHospitalClass}
                     placeholder="Connected with a Hospital ?"
                     onClick={this.hpConnectHospitalChange}
                     InputProps={{
                       endAdornment: (<InputAdornment position="end">
                         <img
                           src={connectHospitalSrc}
                           alt="Misc"
                           className={classes.connectIcon}
                         />
                       </InputAdornment>),
                       readOnly: true
                     }}
                   />
                 </Box>
               </Grid>
               <Grid item xl={6} md={6} sm={6} xs={12} className={classes.degreeContainerSpacing}>
                 <Box>
                   <TextField
                     variant="outlined"
                     data-test-id="SOS"
                     className={classes.hpConnectHospitalFieldsGray}
                     placeholder="Available for SOS video call"
                     InputProps={{
                       endAdornment: (<InputAdornment position="end">
                         <img
                           src={MiscGray}
                           alt="MiscGray"
                           className={classes.connectIcon}
                         />
                       </InputAdornment>),
                       readOnly: true
                     }}
                   />
                 </Box>
               </Grid>
               {!this.state.connectHospital &&
                 (<Grid item xl={6} md={6} sm={6} xs={12} className={classes.locationGridContainer}>
                   <Box className={classes.sliderBox}>
                     <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                       Location Limit<span className={classes.required}>*</span> <span className={classes.locationLimit}>Patient distance from your home</span>
                     </p>
                     <Box className={classes.locationLimitBox}>
                       <Box className={classes.locationSpan}><span>2km</span><span className={classes.selectedLocation}>{this.state.locationLimit}km</span><span>10km</span></Box>
                       <CustomSlider
                         data-test-id="limitLocation"
                         valueLabelDisplay="auto"
                         defaultValue={parseInt(this.state.locationLimit)}
                         max={10}
                         min={2}
                         onChange={this.handleSliderChange} />
                     </Box>
                   </Box>
                 </Grid>
                 )}
               <Grid item xl={6} md={6} sm={6} xs={12} className={classes.hospitalContainerSpacing}>
                 {this.state.connectHospital && (
                   <ClickAwayListener data-test-id="closeHospitalDropdown"
                   onClickAway={this.closeHospitalDropdown}>
                     <Box id="hospitalContainer">
                     <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                       Select the Hospital<span className={classes.required}>*</span>
                     </p>
                     <Box className={classes.hpSelectCheckbox}>
                       <TextField
                         variant="outlined"
                         data-test-id="hpHospital"
                         className={this.showSelectErrorField(hpDocDetailsErr.selectedHospitals, classes)}
                         placeholder="Select the hospital"
                         value={this.hospitalData(this.state)}
                         onClick={this.openHospitalDropdown}
                         InputProps={{
                           endAdornment: (
                             <InputAdornment position="end">
                               <img
                                 src={DownArrow}
                                 alt="DownArrow"
                                 className={classes.hpDownArrowIcon}
                               />
                             </InputAdornment>
                           ),
                           readOnly: true
                         }}
                       />
                       <>
                         {this.state.openHospitalDropdown && (
                           <Box className={classes.hpSelectFieldDropdown}>
                             <Box className={classes.hpDropdownContent}>
                               <Box className={classes.dropdownText}>
                                 Select the hospital
                                 <img
                                   data-test-id="hideHospitalDropdown"
                                   src={UpwardArrow}
                                   alt="UpwardArrow"
                                   className={classes.hpUpArrowIcon}
                                   onClick={this.openHospitalDropdown}
                                 />
                               </Box>
                               <Box className={classes.hpSelectFieldSize}>
                                 {this.state.hospitalList.map((hospital: any, index: number) => (
                                   <Box className={classes.hpSelectOption} key={hospital.id} style={this.getFontWeightStyle(this.state.selectedHospitals, hospital.id)}>
                                     <Checkbox
                                       data-test-id={`selectHospital${index}`}
                                       className={classes.checkbox}
                                       checked={this.state.selectedHospitals === hospital.id}
                                       onChange={() => this.handleHospitalsCheckboxChange(hospital.id)}
                                       icon={<HPCustomsCheckboxIconUnChecked />}
                                       checkedIcon={<HPCustomsCheckboxIconChecked />}
                                     />
                                     {hospital.attributes.name}
                                   </Box>
                                 ))}
                               </Box>
                             </Box>
                           </Box>
                         )}
                       </>
                       {this.errorMessage(hpDocDetailsErr.selectedHospitals, classes.inputErrors)}
                     </Box>
                     </Box>
                   </ClickAwayListener>)}
                   <ClickAwayListener data-test-id="closeSpecilizatioDropown"
                   onClickAway={this.closeSpecializationeDropdown}>
                     <Box id="specilizationContainer">
                   <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                     Specialization<span className={classes.required}>*</span>
                   </p>
                   <Box className={classes.hpSelectCheckbox}>
                     <TextField
                       variant="outlined"
                       data-test-id="hpSpecialization"
                       className={this.showSelectErrorField(hpDocDetailsErr.selectedSpecializations, classes)}
                       placeholder="Select the specialization"
                       value={this.specializationsData(this.state)}
                       onClick={this.openSpecializationeDropdown}
                       InputProps={{
                         endAdornment: (
                           <InputAdornment position="end">
                             <img
                               src={DownArrow}
                               alt="DownArrow"
                               className={classes.hpDownArrowIcon}
                             />
                           </InputAdornment>
                         ),
                         readOnly: true
                       }}
                     />
                     <>
                       {this.state.openSpecializationeDropdown && (
                         <Box className={classes.hpSelectFieldDropdown}>
                           <Box className={classes.hpDropdownContent}>
                             <Box className={classes.dropdownText}>
                               Select the specilization
                               <img
                                 data-test-id="hideSpecializationDropdown"
                                 src={UpwardArrow}
                                 alt="UpwardArrow"
                                 className={classes.hpUpArrowIcon}
                                 onClick={this.openSpecializationeDropdown}
                               />
                             </Box>
                             <Box className={classes.hpSelectFieldSize}>
                               {this.state.specializationsList.map((specialization: any, index: number) => (
                                 <Box className={classes.hpSelectOption} key={specialization.id} style={this.getFontWeightStyle(this.state.selectedSpecializations, specialization.id)}>
                                   <Checkbox
                                     data-test-id={`selectSpecilization${index}`}
                                     className={classes.checkbox}
                                     onChange={() => this.handleSpecializationsCheckboxChange(specialization.id)}
                                     checked={this.state.selectedSpecializations === specialization.id}
                                     icon={<HPCustomsCheckboxIconUnChecked />}
                                     checkedIcon={<HPCustomsCheckboxIconChecked />}
                                   />
                                   {specialization.attributes.name}
                                 </Box>
                               ))}
                             </Box>
                           </Box>
                         </Box>
                       )}
                     </>
                     {this.errorMessage(hpDocDetailsErr.selectedSpecializations, classes.inputErrors)}
                   </Box>
                     </Box>
                 </ClickAwayListener>
               </Grid>
               <Grid item xl={6} md={6} sm={6} xs={12} className={classes.mobileHospitalContainerSpacing}>
                 {this.state.connectHospital && (
                   <ClickAwayListener  data-test-id="closeDepartmentDropdown"
                   onClickAway={this.closeDepartmentDropdown}>
                     <Box id="departmentContainer">
                     <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                       Select the Department<span className={classes.required}>*</span>
                     </p>
                     <Box className={classes.hpSelectCheckbox}>
                       <TextField
                         variant="outlined"
                         data-test-id="hpDepartment"
                         className={this.showSelectErrorField(hpDocDetailsErr.selectedDepartments, classes)}
                         placeholder="Select the department"
                         value={this.getDepartmentName(this.state.departmentsList,this.state.selectedDepartments)
                         }
                         onClick={this.openDepartmentsDropdown}
                         InputProps={{
                           endAdornment: (
                             <InputAdornment position="end">
                               <img
                                 src={DownArrow}
                                 alt="DownArrow"
                                 className={classes.hpDownArrowIcon}
                                 onClick={this.openDepartmentsDropdown}
                               />
                             </InputAdornment>
                           ),
                           readOnly: true
                         }}
                       />
                       <>
                         {this.state.openDepartmentsDropdown && (
                           <Box className={classes.hpSelectFieldDropdown}>
                             <Box className={classes.hpDropdownContent}>
                               <Box className={classes.dropdownText}>
                                 Select the department
                                 <img
                                   data-test-id="hideDepartmentDropdown"
                                   src={UpwardArrow}
                                   alt="UpwardArrow"
                                   className={classes.hpUpArrowIcon}
                                   onClick={this.openDepartmentsDropdown}
                                 />
                               </Box>
                               <Box className={classes.hpSelectFieldSize}>
                                 {this.state.departmentsList.map((department: any, index: number) => (
                                   <Box className={classes.hpSelectOption} key={department.id} style={this.getFontWeightStyle(this.state.selectedDepartments, department.id)}>
                                     <Checkbox
                                       data-test-id={`selectDepartment${index}`}
                                       className={classes.checkbox}
                                       checked={this.state.selectedDepartments === department.id}
                                       onChange={() => this.handleHpDepartmentsCheckboxChange(department.id)}
                                       icon={<HPCustomsCheckboxIconUnChecked />}
                                       checkedIcon={<HPCustomsCheckboxIconChecked />}
                                     />{department.attributes.name}
                                   </Box>
                                 ))}
                               </Box>
                             </Box>
                           </Box>
                         )}
                       </>
                       {this.errorMessage(hpDocDetailsErr.selectedDepartments, classes.inputErrors)}
                     </Box>
                     </Box>
                   </ClickAwayListener>)}
                   <ClickAwayListener data-test-id="closeLaguageDropdown"
                      onClickAway={this.closeLanguageDropdown}>
                     <Box id="languageContainer">
                   <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                     Languages Known<span className={classes.required}>*</span>
                   </p>
                   <Box className={classes.hpSelectCheckbox}>
                     <Box
                       data-test-id="hpLanguage"
                       className={this.showErrorField(hpDocDetailsErr.language, classes, "language")}
                       onClick={this.openLanguageDropdown}
                     >
                       <Box className={this.hpDoctorLanguageContainer(this.state.selectedLanguages,classes.patientLanguageBox)}>
                         {this.state.selectedLanguages.map((languageId: any, index: number) => {
                         const languageList = this.state.languagesList.find((language: any) => language.id === languageId);

                         return (
                           <Chip
                             data-test-id={`removeLanguage${index}`}
                             label={languageList?.attributes.name}
                             onDelete={() => this.removeSelectedLanguage(languageList?.id)}
                             deleteIcon={<CloseIcon fontSize="small" />}
                             className={classes.langIcon}
                           />
                         );
                       })}
                      </Box>
                       {this.selectLanguageText(this.state.selectedLanguages, classes)}
                       <img
                         src={DownArrow}
                         alt="DownArrow"
                         className={classes.hpLangDownArrowIcon}
                         style={{ marginLeft: 'auto'}}
                       />
                     </Box>

                     {this.languageFieldValidation(hpDocDetailsErr.language,this.state,classes)}
                     <>
                       {this.state.openLanguageDropdown && (
                         <Box data-test-id="languageContainer" className={classes.hplangSelectFieldDropdown}>
                           <Box className={classes.hpLangDropdownContent}>
                             <Box className={classes.langdropdownText}>
                               <img
                                 data-test-id="hideLanguageDropdown"
                                 src={UpwardArrow}
                                 alt="UpwardArrow"
                                 className={classes.hpUpArrowIcon}
                                 onClick={this.openLanguageDropdown}
                               />
                             </Box>
                             <Box className={classes.hpLangSelectFieldSize}>
                               {this.state.languagesList.map((language: any, index: number) => (
                                 <Box className={classes.hpSelectOption} key={language.id} style={this.getFontWeightStyle(this.state.selectedLanguages, language.id,"language")}>
                                   <Checkbox
                                     data-test-id={`selectLanguage${index}`}
                                     className={classes.checkbox}
                                     checked={this.state.selectedLanguages.includes(language.id)}
                                     onChange={() => this.handleLanguagesCheckboxChange(language.id)}
                                     icon={<HPCustomsCheckboxIconUnChecked />}
                                     checkedIcon={<HPCustomsCheckboxIconChecked />}
                                   />{language.attributes.name}
                                 </Box>
                               ))}
                             </Box>
                           </Box>
                         </Box>
                       )}
                     </>
                     {this.errorMessage(hpDocDetailsErr.language, classes.inputErrors)}
                   </Box>
                     </Box>
                   </ClickAwayListener>
               </Grid>
               <Grid item xl={12} md={12} sm={12} xs={12} className={classes.degreeContainerSpacing}>
                 <Box data-test-id="DpDegree">
                   <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                     Degree<span className={classes.required}>*</span>
                   </p>
                 </Box>
               </Grid>
               <Grid item xl={12} md={12} sm={12} xs={12} className={classes.degreeContainerSpacing}>
                 <>
                   {this.state.educationDetails.map((eduDetail: any, index: number) => (
                     <Box key={index}>
                       {this.callDegreeCollgeDetails(index, classes, eduDetail)}
                     </Box>
                   ))}
                   {this.state.isEditing &&  (
                       <>
                       <Box className={classes.degClgBox}>
                           <Box className={ classes.degColTextField }>
                             <TextField
                               data-test-id={`hpDegreeupdate${parseInt(this.state.editableIndex)}`}
                               variant="outlined"
                               placeholder="Enter degree"
                               className={this.showErrorField(hpDocDetailsErr.degree, classes)}
                               value={this.state.educationDetails[parseInt(this.state.editableIndex)].degree}
                               onChange={(event) =>  this.handleEducationDegreeChange(parseInt(this.state.editableIndex), event.target.value.trimStart()) }
                               onBlur={() => this.onBlurDoctorDegree()}
                             />
                         </Box>
                         <Box data-test-id=" " className={classes.degColTextField} >
                           <TextField
                             variant="outlined"
                             data-test-id={`hpCollegeupdate${parseInt(this.state.editableIndex)}`}
                             placeholder="Enter college name"
                             value={this.state.educationDetails[parseInt(this.state.editableIndex)].college}
                             className={this.showErrorField(hpDocDetailsErr.college, classes)}
                             onChange={(event) => this.handleEducationCollegeChange(parseInt(this.state.editableIndex), event.target.value.trimStart())}
                             onBlur={() => this.onBlurDoctorCollege()}
                           />
                           </Box>
                       </Box>
                   </>)}
                   <Box className={classes.degClgBox}>
                           <Box className={classes.degColTextField} data-test-id="">
                             <TextField
                               variant="outlined"
                               data-test-id={`hpDegree${this.state.educationDetails.length-1}`}
                               className={this.showErrorField(hpDocDetailsErr.degree, classes)}
                               placeholder="Enter degree"
                               value={this.state.degree}
                               onChange={(event) => this.handleEducationDegreeChange(this.state.educationDetails.length-1,event.target.value.trimStart())}
                               onBlur={() => this.updateDoctorDegree()}
                             />
                           </Box>
                           <Box data-test-id="" className={classes.degColTextField} >
                             <TextField
                               data-test-id={`hpCollege${this.state.educationDetails.length-1}`}
                               variant="outlined"
                               placeholder="Enter college name"
                               value={this.state.college}
                               onChange={(event) => this.handleEducationCollegeChange(this.state.educationDetails.length-1,event.target.value.trimStart())}
                               className={this.showErrorField(hpDocDetailsErr.college, classes)}
                               onBlur={() => this.updateDoctorCollege()}
                             />
                           </Box>
                   </Box>
                 </>
               </Grid>
               <Grid item xl={6} md={6} sm={6} xs={6} className={classes.degreeContainerSpacing}>
                 <>
                   {this.errorMessage(hpDocDetailsErr.degree, classes.inputErrors)}
                 </>
               </Grid>
               <Grid item xl={6} md={6} sm={6} xs={6} className={classes.degreeContainerSpacing}>
                 <>
                   {this.errorMessage(hpDocDetailsErr.college, classes.inputErrors)}
                   {this.renderAddEducationLink(this.state,classes)}
                 </>
               </Grid>

               <Grid item xl={6} md={6} sm={6} xs={12} className={classes.hospitalBottomContainerSpacing}>
                 <Box>
                   <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                     Years of Experience<span className={classes.required}>*</span>
                   </p>
                   <TextField
                     variant="outlined"
                     value={this.state.yearofExp}
                     data-test-id="hpExperience"
                     placeholder="Enter experience"
                     className={this.showErrorField(hpDocDetailsErr.yearofExp, classes)}
                     onChange={(event) => this.handlehpChange("yearofExp", event.target.value.trimStart())}
                   />
                   {this.errorMessage(hpDocDetailsErr.yearofExp, classes.inputErrors)}
                 </Box>
                 <Box>
                   <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                     Pin Code<span className={classes.required}>*</span>
                   </p>
                   <TextField
                     variant="outlined"
                     data-test-id="hpPincode"
                     className={this.showErrorField(hpDocDetailsErr.pincode, classes)}
                     placeholder="6 digit Pin Code"
                     value={this.state.pincode}
                     InputProps={{ inputProps: { maxLength: 6 } }}
                     onChange={(event) => this.handlehpChange("pincode", event.target.value.trimStart())}
                   />
                   {this.errorMessage(hpDocDetailsErr.pincode, classes.inputErrors)}
                 </Box>
                 <Box>
                   <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                     Address<span className={classes.required}>*</span>
                   </p>
                   <TextField
                     variant="outlined"
                     data-test-id="hpAddress"
                     className={this.showErrorField(hpDocDetailsErr.address, classes)}
                     placeholder="Enter house no. , street name"
                     value={this.state.address}
                     onChange={(event) => this.handlehpChange("address", event.target.value.trimStart())}
                   />
                   {this.errorMessage(hpDocDetailsErr.address, classes.inputErrors)}
                 </Box>
                 {
                   this.props.userLogin && 
                   <>
                     <Box>
                       <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                         Email Address<span className={classes.required}>*</span>
                       </p>
                       <TextField
                         variant="outlined"
                         data-test-id="hpEmail"
                         className={this.showErrorField(hpDocDetailsErr.email, classes)}
                         placeholder="Enter Email"
                         value={this.state.email}
                         onChange={(event) => this.handlehpChange("email", event.target.value.trimStart())}
                       />
                       {this.errorMessage(hpDocDetailsErr.email, classes.inputErrors)}
                     </Box>
                   </>
                 }    
                 <Box data-test-id="DpRegistrationCertification">
                   <p data-test-id="certificationLabel" className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                     Registration Certificate<span className={classes.required}>*</span>
                   </p>
                   {this.isUpload(this.state.isUploadDoc) &&
                     <TextField
                       variant="outlined"
                       data-test-id="uploadDoc"
                       style={{caretColor:"white !important"}}
                       className={this.getHpClassName(classes, hpDocDetailsErr.uploadDoc)}
                       placeholder={this.getHpPlaceholder()}
                       value=""
                       InputProps={{
                         startAdornment: (<InputAdornment position="start">
                           <img
                             src={ImageIcons}
                             alt="Preview"
                             className={classes.hpUploadIcon}
                           />
                         </InputAdornment>),
                         readOnly: true
                       }}
                       onClick={this.handleButtonClick}
                     />}
                   <Box onClick={this.handleButtonClick}>
                     <Typography variant="h6" style={{ textAlign: "left" ,caretColor:"transparent"}}>
                       {this.getHpInputProps(classes)}
                     </Typography>
                   </Box>
                   {this.errorMessage(hpDocDetailsErr.uploadDoc, classes.inputErrors)}
                   <input
                     data-test-id="hpuploadDoc"
                     type="file"
                     ref={this.fileInputRef}
                     style={{ display: "none" }}
                     onChange={this.handleUploadDoc}
                   />
                 </Box>
                 <Box data-test-id="price">
                    <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                      Fees<span className={classes.required}>*</span>
                    </p>
                    <FeeEngine navigation={undefined}
                      id="" isValidNumber={this.isValidNumber}
                      hpDocDetailsErr={this.state.hpDocDetailsErr.price} showAyahErrorField={this.showErrorField}
                      handlehpChange={this.handlehpChange}
                      classes={classes} type="price"
                      price={this.state.price} placeholderText="/ Appointment"
                    />
                    {this.errorMessage(this.state.hpDocDetailsErr.price, classes.inputErrors)}
                  </Box>
                  <Box data-test-id="onlineConsultationPrice" style={{marginTop:'20px'}}>
                    <FeeEngine
                      navigation={undefined} id=""
                      isValidNumber={this.isValidNumber} hpDocDetailsErr={this.state.hpDocDetailsErr.onlineConsultationPrice}
                      showAyahErrorField={this.showErrorField} handlehpChange={this.handlehpChange}
                      classes={classes}
                      price={this.state.onlineConsultationPrice} placeholderText="/ Online Consultation"
                      type="onlineConsultationPrice"
                    />
                    {this.errorMessage(this.state.hpDocDetailsErr.onlineConsultationPrice, classes.inputErrors)}
                  </Box>
               
               </Grid>
               <Grid item xl={6} md={6} sm={6} xs={12} className={classes.mobileBottomHospitalContainerSpacing}>
                 <Box className={classes.hpPersonalDetials}>
                  <Grid container >
                    <Grid item spacing={2} container xl={12}>
                      <Grid item xs={12} sm={6} xl={6}>
                          <Box>
                            <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                              Age<span className={classes.required}>*</span>
                            </p>
                            <TextField
                              variant="outlined"
                              data-test-id="hpAge"
                              className={this.showErrorField(hpDocDetailsErr.age, classes)}
                              placeholder="Enter age"
                              value={this.state.age}
                              onChange={(event) => this.handlehpChange("age", event.target.value.trimStart())}
                            />
                            {this.errorMessage(hpDocDetailsErr.age, classes.inputErrors)}
                          </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} xl={6}>
                      <ClickAwayListener data-test-id="openGenderDropdown"
                            onClickAway={this.closeGenderDropdown}>
                            <Box id="genderContainer">
                            <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                              Gender<span className={classes.required}>*</span>
                            </p>
                            <Box className={classes.hpSelectCheckbox}>
                              <TextField
                                variant="outlined"
                                data-test-id="hpGender"
                                className={this.showSelectErrorField(hpDocDetailsErr.gender, classes)}
                                placeholder="Enter gender"
                                value={this.state.gender}
                                onClick={this.openDropdown}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <img
                                        src={DownArrow}
                                        alt="DownArrow"
                                        className={classes.hpDownArrowIcon}
                                      />
                                    </InputAdornment>
                                  ),
                                  readOnly: true
                                }}
                              />
                              <>
                                {this.state.showDropdown && (
                                  <Box className={classes.hpSelectFieldDropdown}>
                                    <Box className={classes.hpDropdownContent}>
                                      <Box className={classes.dropdownText}>
                                        Enter gender
                                        <img
                                          data-test-id="hideGenderDropdown"
                                          src={UpwardArrow}
                                          alt="UpwardArrow"
                                          className={classes.hpUpArrowIcon}
                                          onClick={this.openDropdown}
                                        />
                                      </Box>
                                      <Box>
                                        {genderOptions.map((data: any, index: number) => (
                                          <Box className={classes.hpSelectOption} key={index} style={this.getFontWeightStyle(this.state.gender, data.value)}>
                                            <Checkbox
                                              data-test-id={`selectGender${index}`}
                                              className={classes.checkbox}
                                              checked={this.state.gender === data.value}
                                              onChange={() => this.handleHpGenderCheckboxChange(data.value)}
                                              icon={<HPCustomsCheckboxIconUnChecked />}
                                              checkedIcon={<HPCustomsCheckboxIconChecked />}
                                            />{data.value}
                                          </Box>
                                        ))}
                                      </Box>
                                    </Box>
                                  </Box>
                                )}
                              </>
                              {this.errorMessage(hpDocDetailsErr.gender, classes.inputErrors)}
                            </Box>
                          </Box>
                        </ClickAwayListener>
                      </Grid>
                    </Grid>
                    <Grid item spacing={2} container xl={12}>
                      <Grid item xs={12} sm={6} xl={6}>
                        
                        <Box data-test-id="DpCity">
                          <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                            City<span className={classes.required}>*</span>
                          </p>
                          <TextField
                            variant="outlined"
                            placeholder="Enter city"
                            value={this.state.city}
                            data-test-id="hpCity"
                            className={this.showErrorField(hpDocDetailsErr.city, classes)}
                            onChange={(event) => this.handlehpChange("city", event.target.value.trimStart())}
                          />
                          {this.errorMessage(hpDocDetailsErr.city, classes.inputErrors)}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} xl={6}>
                        <Box data-test-id="DPDistrict">
                          <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                            District<span className={classes.required}>*</span>
                          </p>
                          <TextField
                            variant="outlined"
                            className={this.showErrorField(hpDocDetailsErr.district, classes)}
                            data-test-id="hpDistrict"
                            value={this.state.district}
                            placeholder="Enter district"
                            onChange={(event) => this.handlehpChange("district", event.target.value.trimStart())}
                          />
                          {this.errorMessage(hpDocDetailsErr.district, classes.inputErrors)}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                 </Box>
                 <Box>
                   <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                     Registration No.<span className={classes.required}>*</span>
                   </p>
                   <TextField
                     variant="outlined"
                     data-test-id="hpRegistrationNumber"
                     className={this.showErrorField(hpDocDetailsErr.registrationNumber, classes)}
                     placeholder="Enter registration no."
                     value={this.state.registrationNumber}
                     onChange={(event) => this.handlehpChange("registrationNumber", event.target.value.trimStart())}
                   />
                   {this.errorMessage(hpDocDetailsErr.registrationNumber, classes.inputErrors)}
                 </Box>
                 {
                   this.props.userLogin &&
                   <Box>
                     <p className={classes.registerHPLabel} style={{ textAlign: "left" }}>
                       Phone Number<span className={classes.required}>*</span>
                     </p>
                     <TextField
                       variant="outlined"
                       data-test-id="hpNumber"
                       className={this.showErrorField(hpDocDetailsErr.phoneNumber, classes)}
                       placeholder="Enter Phone Number"
                       value={this.state.phoneNumber}
                       onChange={(event) => this.handlehpChange("phoneNumber", event.target.value.trimStart())}
                       InputProps={{inputProps: { maxLength: 10 } }}
                     />
                     {this.errorMessage(hpDocDetailsErr.phoneNumber, classes.inputErrors)}
                   </Box>
                 }
               </Grid>
               <Grid item xs={12} style={{padding:'16px'}} >
               <CustomStyledButton
                   data-test-id="submit"
                   className={classes.registerDesktopButtons}
                   style={{ textTransform: "capitalize" }}
                   onClick={this.handleHpSubmit}
                   label="Submit"
                   />
                   
               </Grid>
             </Grid>
             <Popover
               className={classes.hpNewPaperPropsClass}
               data-test-id="hpPopover"
               open={this.state.ishpDialogAppear}
               anchorOrigin={{
                 vertical: 'center',
                 horizontal: 'center',
               }}
               transformOrigin={{
                 vertical: 'center',
                 horizontal: 'center',
               }}
             >
               <Box className={classes.hpPopoverContainer}>
                 <Box>
                   <p className={classes.hpPopoverText}>Only your name, age, gender, college,
                   languages known and registration number will
                   be shared with the patient.</p>
                 </Box>
                 <Box className={classes.selecthpContainer}>
                   <CustomStyledButton
                     data-test-id="closePopup"
                     className={classes.hpClosePopup}
                     onClick={this.closeHpPopup}
                     label="Okay"
                   />
                     
                 </Box>
               </Box>
             </Popover>
           </Box>
         </Grid>
         <Dialog
           className={classes.otpDialogBoxNew}
           open={this.state.otpSend}
         >
           <Card className={classes.otpNewTransparentCard}>
             <CardContent
               className={classes.otpCardContentNew}
               style={{ flexDirection: "column" }}
             >
               <Box className={classes.profileCaredWrapper}>
                 <p
                   className={classes.otpWelcomeTitle}
                   style={{ textAlign: "center" }}
                 >
                   <span className={classes.otpText}>OTP</span>{" "}
                   Verification
                 </p>
                 <CloseIcon
                   data-test-id="closeDialogtest"
                   style={{
                     cursor: "pointer",
                     caretColor: "transparent",
                   }}
                   onClick={() => this.closeOtpPopup("hospital", "phone")}
                 />
               </Box>
               <p
                 className={classes.cardSubtitle}
                 style={{ textAlign: "center" }}
               >
                 Enter OTP
               </p>
               <p
                 className={classes.cardInputLabel}
                 style={{ textAlign: "center" }}
               > An 4- Digit code has been sent to {"\n"}+91{""}
                  {`******${this.state.phoneNumber?.toString().slice(6, 10)}`}
               </p>
               <Box className={classes.otpUpdateContainer }>
                 {this.state.phoneOTP.map((value: any,index: any)=>(
                   <TextField
                    size="small"
                    data-test-id={`updateOtpInput${index}`}
                    id={`updateOtpInput${index}`}
                     className={this.updateOtpError(this.state.isError,classes)
                     }
                     key={index}
                     variant="outlined"
                     value={value}
                     onChange={ event =>
                       this.handleOTPChange( index, event )
                     }
                     style={{
                       width: "50px",
                       height: "45px",
                       margin: "5px auto 0px"
                     }}
                     inputProps={{
                       style: {
                         textAlign: "center" },
                       maxLength:1
                     }}
                   />
                 ))}
               </Box>
               <Box className={classes.errorMessages}>
               {this.errorMessage(this.state.errorMessage, classes.otpErrors)}
               </Box>
               <CountDownTimer
                 isResendActive={this.state.isResendActive}
                 times={this.state.resendCounter} 
                 toggleResend={this.toggleResend}
               />
               <Box
                 display="flex"
                 padding="8px 0px"
                 flexDirection="row"
                 justifyContent="space-between"
               >
                 {this.generateDoctorResendComponent(
                   "Resend OTP",
                   "resendOTP"
                 )}

                 {this.generateDoctorResendComponent(
                   "Get OTP by Call",
                   "resendOTPByCall"
                 )}
               </Box>
               <CustomStyledButton
                 data-test-id="updateVerifyOtpBtn"
                 className={ classes.verifyOTPNew}
                 onClick={() => 
                  this.verifyOTPData()}
                 style={{ 
                  textTransform: "capitalize"
                 }}
                 label="Verify"
               />
             </CardContent>
           </Card>
         </Dialog>
         <Dialog
           open={ this.state.emailSend }  className={classes.otpDialogBoxNew}
         >
           <Card className={classes.transparentOtpCards}>
             <CardContent
               style={{ flexDirection: "column" }}
               className={classes.cardOtpContents} >
               <Box className={classes.confimPopup}>
                 <CloseIcon
                   data-test-id="closeDialog"
                   style={{ cursor:"pointer", caretColor:"transparent"
                   }}
                   onClick={() => 
                    this.closeOtpPopup("hospital", "email")}
                 />
               </Box>
               <img
                 src={EmailIcon}
                 alt="emailVerification"
                 className={classes.emailConfirmOtpLogo}
                 style={{objectFit: "contain"}}
               />
               <p
                 style={{ textAlign: "center" }}
                 className={classes.confirmEmailOtpHeading}
               >
                 Confirm your email address </p>
               <p
                 className={classes.confirmOtpEmailText}
                 style={{textAlign: "center"}}
               >
                 We sent a confirmation email to {this.state.email}</p>
               <Box className={classes.hospitalSuccessMessageContainer}>
                 <p
                   data-test-id="textMessage1"
                   style={{ textAlign: "center" }}
                   className={ classes.confirmEmailOtpSubText}
                 >
                   Check your email and click on the
                 </p>
                 <p
                   data-test-id="textMessage2" style={{ textAlign: "center" }}
                   className={classes.confirmEmailOtpSubText}
                 >
                   confirmation link to continue.</p>
               </Box>
               <CustomStyledButton
                 onClick={this.confirmEmail}
                 style={{ textTransform: "capitalize" }}
                 data-test-id="emailSend"
                 className={classes.successOtpBtns}
                 label="Resend"
               />

             </CardContent>
           </Card>
         </Dialog>
         <Dialog open={this.state.confimEmail}
           className={classes.otpDialogBoxNew}>
           <Card className={classes.transparentOtpCards}>
             <CardContent
               style={{flexDirection: "column" }}
               className={ classes.cardOtpContents}
             >
               <img
                 src={verifiedEmail}
                 style={{ objectFit: "contain" }}
                 alt="email Verified"
                 className={classes.emailVerified}
               />
               <p
                 style={{ textAlign: "center" }}
                 className={classes.emailMessage}
               >
                 Your Email Address Verified</p>
               <p
                 className={classes.successEmailMessages} style={{ textAlign: "center" }}
               >
                 Successfully</p>
               <CustomStyledButton
                 onClick={() => this.otpSuccessEmail("hospital")}
                 data-test-id="confimEmail"
                 className={classes.successOtpBtns}
                 style={{ textTransform: "capitalize" }}
                 label="Done"
               />
             </CardContent>
           </Card>
         </Dialog>
         <Dialog  open={ !!this.state.otpVerified }
           className={ classes.otpDialogBoxNew}
         >
           <Card
             data-test-id="otpCardContainer" className={classes.otpNewTransparentCard }
           >
             <CardContent
               className={classes.otpCardContentNew } style={{flexDirection: "column" }}
             >
               <img
                 src={otp_success}
                 style={{ objectFit: "contain" }}
                 alt="PhoneVerified"
                 className={classes.OtpSuccessLogo}
               />
               <p
                 className={classes.OtpSuccessMessage} style={{ textAlign: "center" }}
               >
                 Phone Verified Successfully</p>
               <CustomStyledButton
                 data-test-id="otpVerified"
                 className={classes.successOtpBtn}
                 onClick={() => this.otpSuccess("hospital")}
                 style={{ textTransform: "capitalize" }}
                label="Done"
               />
             </CardContent>
           </Card>
         </Dialog>
         <Dialog
           open={this.state.openDialog}
           className={this.enableDialogBox(this.state.enableWeb,classes)}
           onClose={this.handleCloseDialog}
         >
           <Box className={classes.dialogTitleConatiner}>
             <p className={classes.dialogTitle} style={{caretColor:"transparent"}}>
               Upload Profile Image
             </p>
             <CloseIcon data-test-id="closeHpDocDialog" style={{ cursor: "pointer" }} onClick={this.handleCloseDialog} />
           </Box>
           <DialogContent data-test-id="doctorContainer">
             <Box data-test-id="enableweb">
                 <Box data-test-id="iconConatiner">
                   <Box data-test-id="customContainer" className={classes.dialogBoxIcons} >
                     <img data-test-id="openHospitalWebToUpload" className={classes.camera} src={camera}/>
                     <img data-test-id="openHospitalPopupToUpload" className={classes.gallery} src={gallery} onClick={this.handleHpUploadProfiles}/>
                     <input type="file" className={classes.imageInput} ref={this.webCameraRef} onChange={this.handleUploadImage}/>
                   </Box>
                   <Box className={classes.dialogBoxText}>
                     <Typography data-test-id="takePhoto" className={classes.uploadCameraText}>Take Photo</Typography>
                     <Typography data-test-id="gallery" className={classes.uploadText}>Gallery</Typography>
                   </Box>
                 </Box>
               {this.errorMessage(this.state.UploadImageError, classes.profileError)}
             </Box>
           </DialogContent>
         </Dialog>
       </>
       <CommonMessage 
          open={this.state.openMessage}
          onClose={this.handleCloseMessage}
          type={this.state.type} 
          title={this.state.messageTitle}
          subTitle={this.state.messageSubTitle}
          />
       
     </Grid>
      )
      :
      (
        <>
          <LocationView id="" navigation="undefined" setAddress={this.setAddressFromChild} closeSetLocationPage={this.closeSetLocationPage} setAddressFieldsByMap={this.setAddressFieldsByMap} showGoogleMap={this.state.showGoogleMap} longitude={this.state.longitude} latitude={this.state.latitude} />
        </>
      )
     }
     </>
   // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(HealthCarePersonalRegistration);
// Customizable Area End
