import React from 'react';
import CustomisableUserProfilesController, { configJSON } from "./CustomisableUserProfilesController";
import { Box, withStyles } from '@material-ui/core';
import HospitalProfileWrapper from './HospitalProfileWrapper.web';
import CustomStyledButton from '../../../components/src/CustomStyledButton.web';
import parse, { domToReact, HTMLReactParserOptions, Element as DOMElement } from 'html-react-parser';
import Loader from '../../../components/src/Loader.web';
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";
import CommonMessage from '../../../components/src/CommonMessage';

export class HomeCurePrivacyPolicy extends CustomisableUserProfilesController {

    async componentDidMount() {
        this.getPrivacyPolicy()
    }

    parseOptions: HTMLReactParserOptions = {
        replace: (DomNode: unknown) => {
            if (DomNode instanceof DOMElement) {
                if (DomNode.name === 'p') {
                    return (
                        <Box>
                            <p className={this.props.classes.apiContentWrapper} >
                                {domToReact(DomNode.children, this.parseOptions)}
                            </p>
                            <br />
                        </Box>
                    );
                } else if (DomNode.name === 'a') {
                    return <span style={webStyle.contentLink} onClick={()=>this.openLink(DomNode.attribs.href)} >
                        {domToReact(DomNode.children)}
                    </span>
                }
            }
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <Box sx={webStyle.container}>
                <SidebarNotificationWrapper {...this.props} hideNotification>
                    <Box sx={webStyle.rightWrapper}>
                        <HospitalProfileWrapper
                            data-test-id="profile-wrapper"
                            {...this.props}
                            profileBackBtn
                            profilePageTitle={configJSON.PageTitle.PrivacyPolicy}
                        >
                            <Box sx={webStyle.contentWrapper} data-test-id='privacy-policy'>
                                <p className={classes.headerText}>HomeCure</p>
                                <p className={classes.titleText}>WELCOME TO HOMECURE</p>
                                <p className={classes.subtitleText}>The Best Medical Service of Lucknow City.</p>
                                <p className={classes.featureText}>Check Our All Feature</p>
                                {parse(this.state.privacyPolicy, this.parseOptions)}

                                <Box sx={webStyle.actionButtonWrapper}>
                                    <CustomStyledButton data-test-id='update-preference' onClick={this.updatePreferanceOfPrivacy} style={{ width: '250px' }} label={configJSON.ButtonLabel.Done} />
                                </Box>
                            </Box>
                            <Loader loading={this.state.isLoading} />
                        </HospitalProfileWrapper>
                    </Box>
                     <CommonMessage 
                        data-test-id="message"
                        open={this.state.openMessage}
                        onClose={this.handleCloseMessage}
                        title={this.state.messageTitle}
                        type={this.state.messageType} 
                        subTitle={this.state.messageSubTitle}
                        />
                </SidebarNotificationWrapper>
            </Box>
        );
    }
}

const webStyle = {
    headerText: {
        color: '#014866',
        fontFamily: 'SF Pro Text Heavy',
        fontSize: '30px',
        lineHeight: '35px',
        marginBottom: '20px',
        '@media(max-width:600px)':{
            marginTop:'0',
            marginBottom:'12px',
            fontSize:'22px'
        }
    },
    apiContentWrapper: {
        textAlign: 'justify' as const,
        lineHeight: '24px',
        fontSize: '18px',
        color: '#292929',
        fontFamily: 'SF Pro Text Regular',
        margin:'0',
        '@media(max-width:600px)':{
            fontSize:'16px'
        }
    },
    contentLink: {
        color: '#014866',
        fontSize: '16px',
        textDecoration: 'underline',
        fontFamily: 'SF Pro Text Regular',
        lineHeight: '24px',
        cursor:'pointer'
    },
    actionButtonWrapper: {
        display: 'flex',
        gridRowGap: "24px",
        flexDirection: "column" as const,
        marginTop: '67px',
        '@media(max-width:600px)':{
            marginTop:'20px'
        }
    },
    sidebarWrapper: {
        width: '132px',
        minHeight: 'max(100vh, 700px)',
    },
    container: {
        height: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: "100vh",
        overflowX: "hidden" as const,
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%',
    },
    contentWrapper: {
        padding: '94px 140px 72px 60px',
        '@media(max-width:991px)':{
            padding:'36px 48px 72px 48px'
        },
        '@media(max-width:600px)':{
            padding:'24px'   
        }
    },
    titleText: {
        color: '#292929',
        fontSize: '24px',
        fontFamily: 'SF Pro Text Medium',
        textTransform: 'uppercase' as const,
        marginBottom: '14px',
        '@media(max-width:600px)':{
            marginTop:'0',
            fontSize:'15px'
        }
    },
    subtitleText: {
        color: '#808080',
        fontSize: '20px',
        fontFamily: 'SF Pro Text Regular',
        marginBottom: '10px',
        '@media(max-width:600px)':{
            marginTop:'0',
            fontSize:'16px'
        },
    },
    featureText: {
        color: '#014866',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Medium',
        marginBottom: '20px',
        '@media(max-width:600px)':{
            marginTop:'0',
            fontSize:'16px'
        }
    },
    descriptionText: {
        color: '#555555',
        textAlign: 'justify' as const,
        fontFamily: 'SF Pro Text Regular',
        fontSize: '18px',
        marginBottom: '10px',
    },
    additionalText: {
        fontFamily: 'SF Pro Text Regular',
        fontSize: '18px',
        color: '#555555',
        textAlign: 'justify' as const,
    },
};

export default withStyles(webStyle)(HomeCurePrivacyPolicy);