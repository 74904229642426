import React from 'react';
import CustomisableUserProfilesController, { configJSON } from "./CustomisableUserProfilesController";
import { Box, Checkbox, Dialog, FormControlLabel, styled, withStyles } from '@material-ui/core';
import HospitalProfileWrapper from './HospitalProfileWrapper.web';
import CustomStyledButton from '../../../components/src/CustomStyledButton.web';
import { checked, unCheckedIcon } from './assets';
import parse, { domToReact, HTMLReactParserOptions, Element as DOMElement } from 'html-react-parser';
import Loader from '../../../components/src/Loader.web';
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";
import CommonMessage from '../../../components/src/CommonMessage';

export class HomeCureTermsAndConditions extends CustomisableUserProfilesController {
    async componentDidMount() {
        this.getTermsAndCondition()
    }

    parseOptions: HTMLReactParserOptions = {
        replace: (domNode: unknown) => {
            if (domNode instanceof DOMElement) {
                if (domNode.name === 'p') {
                    return (
                        <Box>
                            <p className={this.props.classes.apiContentWrapper} >
                                {domToReact(domNode.children, this.parseOptions)}
                            </p>
                            <br />
                        </Box>
                    );
                } else if (domNode.name === 'a') {
                    return <span style={webStyle.contentLink} onClick={() => this.openLink(domNode.attribs.href)} >
                        {domToReact(domNode.children)}
                    </span>
                }
            }
        }
    };

    
    render() {
        const{classes} = this.props;
        return (
            <Box sx={webStyle.container}>
                <SidebarNotificationWrapper {...this.props} hideNotification >
                    <Box sx={webStyle.rightWrapper}>
                        <HospitalProfileWrapper
                            data-test-id="profile-wrapper"
                            {...this.props}
                            profilePageTitle={configJSON.PageTitle.TermsAndConditions}
                            profileBackBtn
                        >
                            <Box sx={webStyle.contentWrapper} data-test-id='terms-and-condition'>
                                <p className={classes.headerText}>HomeCure</p>
                                <p className={classes.titleText}>WELCOME TO HOMECURE</p>
                                <p className={classes.subtitleText}>The Best Medical Service of Lucknow City.</p>
                                <p className={classes.featureText}>Check Our All Feature</p>
                                {parse(this.state.termsAndCondition, this.parseOptions)}
                                <Box sx={webStyle.actionButtonWrapper}>
                                    <StyledFormControlLabel
                                        checked={this.state.acceptPrivacyPolicy}
                                        data-test-id="agreeTerms"
                                        onChange={this.handleChangePrivacyPolicy}
                                        control={<Checkbox disableRipple data-test-id='row-checkbox' checkedIcon={<img src={checked} alt="checked_icon" />} icon={<img src={unCheckedIcon} alt="unchecked_icon" />} />}
                                        label="I agree with the terms and conditions"
                                        labelPlacement="end"
                                    />
                                    <CustomStyledButton data-test-id='update-preference' onClick={this.updatePreferanceOfTermsAndCondition} style={{ width: '250px' }} label={configJSON.ButtonLabel.Done} />
                                </Box>
                            </Box>
                            <Loader loading={this.state.isLoading} />
                            <CommonMessage
                                open={this.state.acceptPrivacyPolicy && this.state.status}
                                onClose={this.handleCloseMessage}
                                title='You have successfully accepted the terms and conditions.'
                                type='success'
                            />
                        </HospitalProfileWrapper>
                    </Box>
                </SidebarNotificationWrapper>
            </Box >
        );
    }
}



const StyledFormControlLabel = styled(FormControlLabel)({
    '&.MuiFormControlLabel-root': {
        alignItems: 'center',
        gap: '22px',
        marginLeft: '0'
    },
    '& .MuiTypography-root': {
        color: '#292929',
        fontSize: '16px',
        fontFamily: 'SF Pro Text Medium',
        lineHeight: '18px'
    }
})

const webStyle = {
    headerText: {
        color: '#014866',
        fontFamily: 'SF Pro Text Heavy',
        fontSize: '30px',
        lineHeight: '35px',
        marginBottom: '20px',
        '@media(max-width:600px)':{
            marginTop:'0',
            marginBottom:'12px',
            fontSize:'22px'
        }
    },
    apiContentWrapper: {
        fontSize: '18px',
        textAlign: 'justify' as const,
        lineHeight: '26px',
        color: '#292929',
        fontFamily: 'SF Pro Text Regular',
        margin:'0',
        '@media(max-width:600px)':{
            fontSize:'16px'
        }
    },
    contentLink: {
        color: '#014866',
        textDecoration: 'underline' as const,
        lineHeight: '24px',
        fontSize: '16px',
        fontFamily: 'SF Pro Text Regular',
        cursor: 'pointer'
    },
    actionButtonWrapper: {
        flexDirection: "column" as const,
        display: 'flex',
        gridRowGap: "24px",
        marginTop: '67px',
        '@media(max-width:600px)':{
            marginTop:'18px'
        }
    },
    sidebarWrapper: {
        width: '132px',
        minHeight: 'max(100vh, 700px)',
    },
    container: {
        display: 'flex',
        height: "100%",
        minHeight: "100vh",
        justifyContent: 'space-between',
        overflowX: "hidden" as const,
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%',
    },
    contentWrapper: {
        padding: '94px 140px 72px 60px',
        '@media(max-width:991px)':{
            padding:'36px 48px 72px 48px'
        },
        '@media(max-width:600px)':{
            padding:'24px'   
        }
    },
    titleText: {
        fontSize: '24px',
        color: '#292929',
        textTransform: 'uppercase' as const,
        fontFamily: 'SF Pro Text Medium',
        marginBottom: '14px',
        '@media(max-width:600px)':{
            marginTop:'0',
            fontSize:'15px'
        }
    },
    subtitleText: {
        color: '#808080',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '20px',
        marginBottom: '10px',
        '@media(max-width:600px)':{
            marginTop:'0',
            fontSize:'16px'
        }
    },
    featureText: {
        color: '#014866',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Medium',
        marginBottom: '20px',
        '@media(max-width:600px)':{
            marginTop:'0',
            fontSize:'16px'
        }
    },
    descriptionText: {
        color: '#555555',
        textAlign: 'justify' as const,
        fontFamily: 'SF Pro Text Regular',
        fontSize: '18px',
        marginBottom: '10px',
    },
    additionalText: {
        fontFamily: 'SF Pro Text Regular',
        color: '#555555',
        fontSize: '18px',
        textAlign: 'justify' as const,
    },
    messageContainer: {
        display: "block",
        maxWidth: "550px",
        width: "500px",
        height: "auto",
        textAlign: "left" as const,
        borderRadius: "5px"
      },
    textheading: {
    width: "100%",
    color: "black",
    fontFamily: "SF Pro Text Regular",
    fontSize: "28px",
    fontWeight: 700,
    height: "8px",
    marginTop: "15px",
    caretColor: "white"
    },
    textparagraph: {
    width: "100%",
    color: "black",
    fontFamily: "SF Pro Text Regular",
    fontSize: "16px",
    height: "8px",
    margin: "30px 0px",
    caretColor: "white"
    },
    button: {
    backgroundColor: "rgb(33, 150, 243)",
    fontFamily: "SF Pro Text Regular",
    border: "none",
    color: "white",
    padding: "5px",
    marginBottom: "15px",
    borderRadius: "3px",
    fontWeight: 600,
    cursor: "pointer",
    caretColor: "transparent",
    "&.MuiButton-root:hover": {
        backgroundColor: "rgb(33, 150, 243)",
        color: "white"
    }
    }, 
};

export default withStyles(webStyle)(HomeCureTermsAndConditions);
