export const Bed = require("../assets/bed.png");
export const BedGray = require("../assets/bed-gray.png");
export const Notification = require("../assets/bell.png");
export const NotificationGray = require("../assets/bell-gray.png");
export const User = require("../assets/user.png");
export const UserGray = require("../assets/user-gray.png");
export const Note = require("../assets/note.png");
export const NoteGray = require("../assets/note-gray.png");
export const Home = require("../assets/home.png");
export const HomeGray = require("../assets/home-gray.png");
export const HomeCure = require("../assets/logo.png");
export const PersonalCard = require("../assets/personalcard.png");
export const PersonalCardGray = require("../assets/personalcard-gray.png");
export const Profile = require("../assets/profile.png");
export const Calendar = require("../assets/calendar.png");
export const CalendarActive = require("../assets/calendar-active.png");
export const Success = require("../assets/success.png");
export const Error = require("../assets/error.png");