import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, Grid, ThemeProvider, createTheme, Button, Dialog } from "@material-ui/core";
import PatientOrderManagementController, { Props } from "./PatientOrderManagementController";
import Sidebar from "../../../components/src/Sidebar.web";
import { ProfileIcon } from "./assets";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SidebarNotificationWrapper from  '../../notifications/src/Notifications.web'
import CommonMessage from "../../../components/src/CommonMessage";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});
// Customizable Area End

export class HPUserProfile extends PatientOrderManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.fetchHPData()
        this.checkIfProfileIsDoctor()
    }

    showPrescriptionBtn = () => {
        const classes = this.props.classes;
        return(
            <Button data-test-id="viewPrescription" className={this.state.hpStatus ? classes.prescriptionBooking :classes.prescriptionBtnHide} onClick={() => this.moveToPrescriptionScreen(this.props.navigation.getParam("navigationBarTitleText"))}>Prescription</Button>
        )
    }

    showTitle = () => {
        if(this.state.profileType === "doctor"){
            return "Dr."
        }else{
            return ""
        }
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { hpList ,hpStatus } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <Box className={classes.leftHPWrapper}>
                        <SidebarNotificationWrapper {...this.props} >
                            <Box className={classes.righHPtWrapper}>
                                <Box>
                                <Box className={classes.headerHPContainer}>
                                        <Box className={classes.orderHeading}>
                                            <ArrowBackIosIcon
                                                className={classes.calendarHPBackBtn}
                                                data-test-id="calendarHPBackBtn"
                                                onClick={this.backToOrderManagementScreen}
                                            />
                                            <Typography variant="h4" className={classes.profileHPHeading}>View Details</Typography>
                                        </Box>
                                    </Box>
                                    <Grid container>
                                        <Grid item xl={4} md={6} sm={6} xs={12}>
                                            <Box className={classes.topHPDetailsLeftContainer}>
                                                <Box className={classes.profileHPContainer}>
                                                    <img className={classes.avatarhp} src={hpList.profile_image ===  null ? ProfileIcon : hpList.profile_image}
                                                    />
                                                </Box>
                                                <Box className={classes.profileDetailsContainer}>
                                                    <Typography variant="h6" className={classes.userName}>{this.showTitle()} {hpList.name}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xl={8} md={6} sm={6} xs={12}></Grid>
                                        <Grid item xl={6} md={10} sm={10} xs={12}>
                                            <Box>
                                            <Box className={classes.btnHPContainer}>
                                                    {this.showPrescriptionBtn()}
                                                    <Button className={classes.prescriptionBooking}>
                                                        Video Call
                                                    </Button>
                                                </Box>
                                                <Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Age
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails} style={{textTransform:"lowercase"}}>
                                                        {hpList.age} y/o
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Gender
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                        {hpList.gender}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Service Booked
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                        {hpList.service_booked}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Date
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                        {hpList.date}
                                                        </Typography>
                                                    </Box>
                                                    {this.state.hpStatus &&
                                                        (<Box className={classes.subContainer}>
                                                            <Typography className={classes.userDetailsHPLabel}>
                                                                Time
                                                            </Typography>
                                                            <Typography className={classes.userDetailsColon}>
                                                                :
                                                            </Typography>
                                                            <Typography className={classes.userDetails}>
                                                                {hpList.time}
                                                            </Typography>
                                                        </Box>)}
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Consulation Fee
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                            Rs. {hpList.amount}.00
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className={classes.btnHPContainer}>
                                    <Button className={classes.cancelBooking} data-test-id="bookAgain" onClick={this.bookAgain}>
                                        Book Again
                                    </Button>
                                    <Button className={hpStatus ? classes.cancelBooking : classes.cancelDisableBooking} data-test-id="cancelBooking" onClick={this.cancelMedicsBooking}>
                                        Cancel Booking
                                    </Button>
                                </Box>
                                <CommonMessage 
                                    open={this.state.openMessage} 
                                    type={this.state.messageType} 
                                    onClose={this.closePopup} 
                                    title={this.state.messageTitle} 
                                    subTitle={this.state.messageSubTitle} 
                                />
                            </Box>
                        </SidebarNotificationWrapper>
                    </Box>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    leftHPWrapper: {
        overflowX: "hidden" as "hidden",
        height: "100%",
    },
    righHPtWrapper: {
        padding:"50px",
        scrollbarColor: "#fff #fff",
        overflowY: "scroll" as "scroll",
        scrollbarWidth: "thin" as "thin",
        "& ::-webkit-scrollbar-button": {
            display: "none"
        },
        '@media(max-width:600px)':{
            padding:'24px'
        }
    },
    profileHPHeading: {
        caretColor: "transparent",
        width: "100%",
        fontFamily: "SF Pro Text Bold",
        fontSize: "28px",
        color: "#292929",
        '@media(max-width:600px)':{
            fontSize:'18px'
        }
      },
    topHPDetailsLeftContainer:{
        display: "flex",
        alignItems: "center",
        padding: "0px 0px 0px 0px",
        fontFamily: "SF Pro Text Regular",
        gap:'24px'
    },
    profileHPContainer:{
    },
    avatarhp:{
        border: "1px solid #eeeaea",
        borderRadius: "50%",
        width: "155px",
        backgroundColor: "#eeeaea",
        height: "155px",
        '@media(max-width:600px)':{
            width:'90px',
            height:'90px'
        }
    },
    profileDetailsContainer:{
       
    },
    userName:{
        fontFamily: "SF Pro text Bold",
        color: "#292929",
        caretColor: "transparent",
        fontSize: "24px",
        textOverflow:'ellipsis',
        overflow:'hidden',
        whiteSpace:'nowrap' as const,
        '&:hover':{
            whiteSpace:'normal',
            overflow:'visible'
        },
        '@media(max-width:600px)':{
            fontSize:'18px'
        }
    }, 
    subContainer:{
        display: "flex",
        padding: "22px 0px 0px",
        fontFamily: "SF Pro Text Regular",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "start",
    },
    userDetailsHPLabel:{
        fontSize: "18px",
        "@media (max-width:1300px)": {
            width: "50% !important"
        },
        caretColor: "transparent",
        fontFamily: "SF Pro Text Medium",
        width: "45%",
        maxWidth:"210px",
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap' as const,
        '&:hover':{
            whiteSpace:'normal',
            overflow:'visible'
        },
        '@media(max-width:600px)':{
            fontSize:'16px'
        }
    },
    userDetailsColon:{
        caretColor: "transparent",
        width: "8%",
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        color: "#808080",
    },
    userDetails:{
        caretColor: "transparent",
        fontSize: "18px",
        fontFamily: "SF Pro Text Regular",
        color: "#717171",
        width: "75%",
        textTransform: "capitalize" as "capitalize",
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap' as const,
        '&:hover':{
            whiteSpace:'normal',
            overflow:'visible'
        },
        '@media(max-width:600px)':{
            fontSize:'16px'
        }
    },
    headerHPContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    orderHeading: {
        display: "flex",
        alignItems: "center",
        marginBottom:"40px",
        justifyContent: "flex-start",
        gap: "25px",
    },
    calendarHPBackBtn: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        padding: "13px 9px 13px 17px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
    },
    cancelBooking: {
        border: "none",
        color: "#FFFFFF",
        width:"200px",
        backgroundColor: "#014866",
        fontSize:"18px",
        fontFamily:"SF PRO Text Bold",
        height:"56px",
        alignItem:"center" as "center",
        alignContent:"center" as "center",
        marginBottom: "15px",
        borderRadius: "8px",
        caretColor: "transparent",
        ".MuiButtonBase-root.Mui-disabled":{
            color: "#FFFFFF",
        },
        "&.MuiButton-root:hover": {
            backgroundColor: "#014866",
            color: "#FFFFFF",
        },
        textTransform: "capitalize" as "capitalize",
        cursor: "pointer",
        '@media(max-width:600px)':{
            height:'auto',
            fontSize:'16px',
            fontFamily:"SF PRO Text Regular",
        }
    },
    prescriptionBooking: {
        border: "1px solid #014866",
        color: "#014866",
        caretColor: "transparent",
        backgroundColor: "#FFFFFF",
        fontSize:"18px",
        fontFamily:"SF PRO Text Bold",
        width:"200px",
        height:"56px",
        alignItem:"center" as "center",
        alignContent:"center" as "center",
        marginBottom: "15px",
        borderRadius: "8px",
        cursor: "pointer",
        "&.MuiButton-root:hover": {
            backgroundColor: "#FFFFFF",
            color: "#014866",
        },
        textTransform: "capitalize" as "capitalize",
        '@media(max-width:600px)':{
            height:'auto',
            fontSize:'16px',
            fontFamily:"SF PRO Text Regular",
        }
    },
    prescriptionBtnHide: {
        border: "1px solid #DFDFDF",
        alignItem:"center" as "center",
        alignContent:"center" as "center",
        marginBottom: "15px",color: "#FFFFFF",
        caretColor: "transparent",
        backgroundColor: "#DFDFDF",
        fontSize:"18px",
        fontFamily:"SF PRO Text Bold",
        width:"200px",
        height:"56px",
        borderRadius: "8px",
        cursor: "pointer",
        "&.MuiButton-root:hover": {
            backgroundColor: "#DFDFDF",
            color: "#FFFFFF",
        },
        textTransform: "capitalize" as "capitalize",
        '@media(max-width:600px)':{
            height:'auto',
            fontSize:'16px',
            fontFamily:"SF PRO Text Regular",
        }
    },
    btnContainer: {
        display: "flex",
        marginTop: "100px",
        gap: "25px",
    },
    btnHPContainer: {
        display: "flex",
        marginTop: "40px",
        gap: "25px",
    },
    cancelDisableBooking: {
        border: "none",
        color: "#FFFFFF",
        width:"200px",
        backgroundColor: "#dfdfdf",
        fontSize:"18px",
        fontFamily:"SF PRO Text Bold",
        height:"56px",
        alignItem:"center" as "center",
        alignContent:"center" as "center",
        marginBottom: "15px",
        borderRadius: "8px",
        caretColor: "transparent",
        "&.MuiButton-root:hover": {
            backgroundColor: "#dfdfdf",
            color: "#FFFFFF",
        },
        textTransform: "capitalize" as "capitalize",
        cursor: "pointer",
        '@media(max-width:600px)':{
            height:'auto',
            fontSize:'16px',
            fontFamily:"SF PRO Text Regular",
        }
    },
    popupMedicsBox: {
        display:"block",
        maxWidth:"550px",
        width:"500px",
        height:"auto",
        textAlign:"left" as "left",
        borderRadius:"5px"
      },
    popupMedicsHeading:{
        width:"100%",
        color:"black",
        fontFamily:"SF Pro Text Regular",
        fontSize:"28px",
        fontWeight:700,
        height:"8px",
        marginTop:"15px",
        caretColor:"white"
      },
    popupmedicsmessage:{
        width:"100%",
        color:"black",
        fontFamily:"SF Pro Text Regular",
        fontSize:"16px",
        height:"8px",
        margin:"30px 0px",
        caretColor:"white"
      },
      popupMedicsBtn:{
        borderRadius:"3px",
        backgroundColor:"rgb(33, 150, 243)",
        border:"none",
        color:"#fff",
        padding:"5px",
        marginBottom:"15px",
        marginTop:"10px",
        fontFamily:"SF Pro Text Regular"
    },
}

export default withStyles(styles)(HPUserProfile);
// Customizable Area End