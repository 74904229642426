import React from 'react';
import { Dialog, DialogActions, DialogContent, Typography, Box, styled } from "@material-ui/core";
import CustomStyledButton from './CustomStyledButton.web';
import { Error, Success } from './assets';

interface Props {
    classNameContent?: string;
    open: boolean;
    type?: string;
    title?: string;
    subTitle?: string;
    onClose: () => void;
    closeIcon?: boolean;
    buttonLabel?: string;
    hideImage?:boolean;
    actionComponent?: string;
    modalTitle?: string;
    contentBoxStyle?: React.CSSProperties;
    titleBoxStyle?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    dialogStyle?: React.CSSProperties | undefined
}

const CommonMessage = (props: Props) => {
    const { onClose, subTitle, title, type,hideImage=false, dialogStyle, classNameContent, contentBoxStyle = {}, open, buttonLabel = "Done" } = props;
    return open ? <StyledDialog data-test-id="messageWrapper" open={open} onClose={onClose} PaperProps={{ style: dialogStyle }} BackdropProps={{ style: webStyle.backDropStyle }} >
        <StyledDialogContent className={classNameContent} style={contentBoxStyle}>
            {!hideImage&&<StyledImageWrapper>
                <img src={type === "success" ? Success : Error} style={{ width: '100%' }} alt={type} />
            </StyledImageWrapper>}
            {title && <StyledTitle>{title}</StyledTitle>}
            {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
        </StyledDialogContent>
        <StyledDialogActions>
            <CustomStyledButton data-test-id="done" onClick={onClose} style={{ width: '100%' }} label={buttonLabel} />
        </StyledDialogActions>
    </StyledDialog > :<></>
}
export default CommonMessage;

const StyledDialogActions = styled(DialogActions)({
    padding: '0 49px 44px',
    '@media(max-width:600px)': {
        padding: '0 24px 24px 24px'
    }
})

const StyledDialogContent = styled(DialogContent)({
    '&.MuiDialogContent-root': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '30px',
        padding: '44px 49px 40px 49px',
        width: '450px',
        boxSizing: 'border-box',
        minHeight: 'fit-content',
        scrollbarColor: "#d9d5d5 #fff",
        scrollbarWidth: "thin" as const,
        '@media(max-width:600px)': {
            width: '100%',
            padding: '44px 24px 40px 24px',
        }
    }
})

const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        minHeight: "fit-content",
        borderRadius: '25px',
        '@media(max-width:600px)': {
            width: '70%'
        },
        '@media(max-width:500px)': {
            width: '100%'
        }
    },
    '& .MuiBackdrop-root': {
        '@media(max-width:991px)': {
            width: '100% !important'
        }
    },
})

const StyledTitle = styled(Typography)({
    '&.MuiTypography-root': {
        color: '#292929',
        fontSize: '20px',
        textAlign: 'center',
        fontFamily: 'SF Pro Text Semibold',
        '@media(max-width:600px)': {
            fontSize: '16px'
        }
    }
})

const StyledSubTitle = styled(Typography)({
    '&.MuiTypography-root': {
        color: '#383838',
        textAlign: 'center',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '18px',
        '@media(max-width:600px)': {
            fontSize: '14px'
        }
    }
})

const StyledImageWrapper =  styled(Box)({
    '&.MuiBox-root':{
        marginTop:"0",
        '@media(max-width:600px)': {
            width: '70px'
        }
    }
})

const webStyle = {
    subtitle: {
        color: '#64748B',
        fontSize: '16px',
        lineHeight: '26px',
        fontFamily: "SF Pro Text Regular",
    },
    modalTitle: {
        color: '#334155',
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '26px',
        fontFamily: "SF Pro Text Regular",
    },
    modalTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 16px 16px 40px',
        zIndex: 10000
    },
    backDropStyle: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        width: '100%',
        right: 0,
        left: 'auto',
        position: 'absolute'
    } as React.CSSProperties,
}