import React from 'react';
import CustomisableUserProfilesController, { configJSON } from "./CustomisableUserProfilesController";
import { Box, withStyles } from '@material-ui/core';
import HospitalProfileWrapper from './HospitalProfileWrapper.web';
import parse, { domToReact, HTMLReactParserOptions, Element as DOMElement } from 'html-react-parser';
import Loader from '../../../components/src/Loader.web';
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";
import CommonMessage from '../../../components/src/CommonMessage';

export class HomeCureAboutus extends CustomisableUserProfilesController {

    async componentDidMount() {
        this.getAboutUsData();
    }

    parseOptions: HTMLReactParserOptions = {
        replace: (domnode: unknown) => {
            if (domnode instanceof DOMElement) {
                if (domnode.name === 'p') {
                    return (
                        <Box>
                            <p className={this.props.classes.apiContentWrapper} >
                                {domToReact(domnode.children, this.parseOptions)}
                            </p>
                            <br />
                        </Box>
                    );
                } else if (domnode.name === 'a') {
                    return <span style={webStyle.contentLink} onClick={()=>this.openLink(domnode.attribs.href)} >
                        {domToReact(domnode.children)}
                    </span>
                }
            }
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <Box sx={webStyle.container}>
               <SidebarNotificationWrapper {...this.props} hideNotification >
                <Box sx={webStyle.rightWrapper}>
                    <HospitalProfileWrapper
                        {...this.props}
                        data-test-id="profile-wrapper"
                        profileBackBtn
                        profilePageTitle={configJSON.PageTitle.AboutUs}
                        >
                        <Box className={classes.contentWrapper} data-test-id='about-us' >
                            <p className={classes.headerText}>HomeCure</p>
                            <p className={classes.titleText}>WELCOME TO HOMECURE</p>
                            <p className={classes.subtitleText}>The Best Medical Service of Lucknow City.</p>
                            <p className={classes.featureText}>Check Our All Feature</p>
                            {parse(this.state.aboutUs, this.parseOptions)}
                        </Box>
                        <Loader loading={this.state.isLoading} />
                    </HospitalProfileWrapper>
                </Box>
                 <CommonMessage 
                    onClose={this.handleCloseMessage}
                    open={this.state.openMessage}
                    type={this.state.messageType} 
                    data-test-id="message"
                    title={this.state.messageTitle}
                    subTitle={this.state.messageSubTitle}
                    />
                </SidebarNotificationWrapper>
            </Box>
        );
    }
}

const webStyle = {
    headerText: {
        color: '#014866',
        fontFamily: 'SF Pro Text Heavy',
        fontSize: '30px',
        lineHeight: '35px',
        marginBottom: '20px',
        '@media(max-width:600px)':{
            marginTop:'0',
            marginBottom:'12px',
            fontSize:'22px'
        }
    },
    apiContentWrapper: {
        textAlign: 'justify' as const,
        fontSize: '18px',
        lineHeight: '24px',
        color: '#292929',
        fontFamily: 'SF Pro Text Regular',
        margin:'0',
        '@media(max-width:600px)':{
            fontSize:'16px'
        }
    },
    contentLink: {
        color: '#014866',
        textDecoration: 'underline',
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'SF Pro Text Regular',
        cursor:'pointer'
    },
    sidebarWrapper: {
        width: '132px',
        minHeight: 'max(100vh, 700px)',
    },
    container: {
        height: "100%",
        justifyContent: 'space-between',
        display: 'flex',
        minHeight: "100vh",
        overflowX: "hidden" as const,
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%',
    },
    contentWrapper: {
        padding: '94px 140px 72px 60px',
        '@media(max-width:991px)':{
            padding:'36px 48px 72px 48px'
        },
        '@media(max-width:600px)':{
            padding:'24px'   
        }
    },
    titleText: {
        color: '#292929',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '24px',
        textTransform: 'uppercase' as const,
        marginBottom: '14px',
        '@media(max-width:600px)':{
            marginTop:'0',
            fontSize:'15px'
        }
    },
    subtitleText: {
        color: '#808080',
        fontSize: '20px',
        fontFamily: 'SF Pro Text Regular',
        marginBottom: '10px',
        '@media(max-width:600px)':{
            marginTop:'0',
            fontSize:'16px'
        }
    },
    featureText: {
        color: '#014866',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '18px',
        marginBottom: '20px',
        '@media(max-width:600px)':{
            marginTop:'0',
            fontSize:'16px'
        }
    },
    descriptionText: {
        color: '#555555',
        textAlign: 'justify' as const,
        fontFamily: 'SF Pro Text Regular',
        fontSize: '18px',
        marginBottom: '10px',
    },
    additionalText: {
        fontFamily: 'SF Pro Text Regular',
        color: '#555555',
        fontSize: '18px',
        textAlign: 'justify' as const,
    },
};
export default withStyles(webStyle)(HomeCureAboutus);

