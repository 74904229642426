import moment from "moment"
import { removeStorageData } from "../../framework/src/Utilities"

export const formateTime = (date: string, formate: string) => {
    return moment(date).format(formate)
}

export const formatUTC = (date: string, format: string)=>{
    return moment.utc(date).format(format)
}

export const clearLocalStorageAndLogout = async () => {
    await removeStorageData('authToken');
    await removeStorageData('isLogin');
    await removeStorageData('userType')
    await removeStorageData('isSignUp')
    await removeStorageData('phoneNumber')
    await removeStorageData('doctorId')
    await removeStorageData('hpUserRole')
    await removeStorageData('priceMode')
    await removeStorageData('redirectFromDashboard')
    await removeStorageData('userName')
    await removeStorageData('serviceType')
    await removeStorageData('isConnectedHospital')
    await removeStorageData('profile');
    await removeStorageData('booking-list-view');
    await removeStorageData('manageView');
    await removeStorageData('email');
    await removeStorageData('healcareId');
    await removeStorageData('patientId');
    await removeStorageData('prescription');
    await removeStorageData('profileType');
    await removeStorageData('userNavigationId');
    await removeStorageData('medicsHPId');
    await removeStorageData('hpRole');
    await removeStorageData('hpFilters');
    await removeStorageData('healthcareId');
    await removeStorageData('user_id');
    await removeStorageData('prev-page');
  }