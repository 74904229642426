import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Grid, Button, createTheme, ThemeProvider, TextField, InputAdornment, TableContainer, Table, TableBody, TableRow, TableCell, Checkbox, SvgIcon, FormHelperText} from "@material-ui/core";
import CustomisableUserProfilesController, {
    Props
} from "./CustomisableUserProfilesController";
import { styles } from "./styles/CustomisableUserProfiles";
import { search_Icon } from "./assets"
import HospitalProfileWrapper from "./HospitalProfileWrapper.web";
import Loader from "../../../components/src/Loader.web";
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";
import CustomStyledButton from "../../../components/src/CustomStyledButton.web";
import CommonMessage from "../../../components/src/CommonMessage";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

const CustomCheckboxIconCheckeds = () => (
  <SvgIcon viewBox="0 0 18 18"  width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
    <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
   </SvgIcon>
);

const CustomCheckboxIconUnCheckeds = () => (
    <SvgIcon viewBox="0 0 18 18"  width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
    </SvgIcon>
);
// Customizable Area End

export class CustomisableViewHospitalProfile extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
    }

    // Customizable Area Start

    getRowStyles = (index: number, idSelected: boolean) => {
        return {
          padding: "5px 0px",
          borderBottom: "1px solid #ddd",
          fontSize: "15px",
          fontWeight: idSelected ? 600 : 500,
          width:"14px",
        };
      };
    
      getSelectedField = (index: number, idSelected: boolean) => {
        return {
          padding: "5px 0px",
          borderBottom: "1px solid #ddd",
          fontSize: "16px",
          fontWeight: idSelected ? 600 : 500,
          fontFamily: idSelected ? "SF Pro Text Medium" : "SF Pro Text Regular",
        };
      };
    
      showSuccessMessage = (message: string | undefined, success: any) => {
        return (message ? <FormHelperText className={success}>{message}</FormHelperText> : null)
      };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { district, postalCode, address, city } = this.state;
        const fullAddress = [address, city, district, postalCode];
        const hospitalAddress = fullAddress.filter(com => com).join(', ');
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <Box style={webStyle.container} className={classes.leftWrapper}>
                        <SidebarNotificationWrapper hideNotification {...this.props}>
                            <Box style={webStyle.rightWrapper} className={classes.rightWrapper}>
                                <HospitalProfileWrapper data-test-id='profile-wrapper' {...this.props} profilePicture={this.state.profilePicture} profilePageTitle="My Profile" >
                                <Box>
                                    <Grid container>
                                        <Grid container className={classes.hospitalViewContainer}>
                                            <Grid item xl={6} md={6} sm={12} xs={12}>
                                                    <Box className={classes.sideBorder}>
                                                        <Box className={classes.topLeftContainer}>
                                                            <Box className={classes.hospitalProfileDetailsContainer}>
                                                                <p className={classes.userName}>
                                                                    {this.state.user_name}
                                                                </p>
                                                                <p className={classes.hospitalPhoneNumber}>
                                                                {this.state.city} | {this.state.phoneNumber}
                                                                </p>
                                                            </Box>
                                                        </Box>
                                                        <Box className={classes.hospitalSubContainer}>
                                                            <p className={classes.hospitalHeading}>
                                                                Registration No
                                                            </p>
                                                            <p className={classes.hospitalDetails}>
                                                                {this.state.registrationNo}
                                                            </p>
                                                        </Box>
                                                        <Box className={classes.hospitalSubContainer}>
                                                            <p className={classes.hospitalHeading}>
                                                                Email Address
                                                            </p>
                                                            <p className={classes.hospitalDetails}>
                                                                {this.state.email}
                                                            </p>
                                                        </Box>
                                                        <Box className={classes.hospitalAddressContainer}>
                                                            <p className={classes.hospitalHeading}>
                                                                Address
                                                            </p>
                                                            <p className={classes.hospitalDetails}>
                                                            {`${hospitalAddress}`}
                                                            </p>
                                                        </Box>
                                                        
                                                    </Box>
                                            </Grid>
                                            <Grid item xl={6} md={6} sm={12} xs={12}>
                                                    {this.state.isInsurance && (<Box className={classes.listContainer}>
                                                        {this.showSuccessMessage(this.state.successMessage, classes.uploadInputSuccess)}
                                                        {this.showSuccessMessage(this.state.errorMessage, classes.uploadInputError)}
                                                        <Box className={classes.selectInsurance}>
                                                            <TextField
                                                                data-test-id="searchInsurance"
                                                                variant="outlined"
                                                                placeholder="Search insurance here.."
                                                                className={classes.insuranceSearchField}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <img
                                                                                src={search_Icon}
                                                                                className={classes.searchIcon}
                                                                            />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                value={this.state.search}
                                                                onChange={(event) => this.handleSearch(event.target.value.trimStart())}
                                                            />
                                                        </Box>
                                                        <Box className={classes.tablePopoverContainer}>
                                                            <TableContainer className={classes.tablecontainer}>
                                                                <Table>
                                                                    <TableBody>
                                                                        {this.state.insurancesList?.filter((insurance: any) => insurance.attributes.name.toLowerCase().includes(this.state.search.toLowerCase())).map((insurance: { id: string, attributes: { name: string }; }, index: number) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell style={this.getRowStyles(index, false)}>
                                                                                    <Checkbox
                                                                                        data-test-id={`selectInsurance${index}`}
                                                                                        className={classes.hospitalCheckbox}
                                                                                        checked={this.state.insurances?.includes(parseInt(insurance.id))} 
                                                                                        onChange={() => this.handleInsuranceCheckboxChange(parseInt(insurance.id))}
                                                                                        icon={<CustomCheckboxIconUnCheckeds />}
                                                                                        checkedIcon={<CustomCheckboxIconCheckeds />}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell style={this.getSelectedField(index, this.state.insurances.includes(parseInt(insurance.id)))}>{insurance?.attributes?.name}</TableCell>
                                                                            </TableRow>
                                                                        ))}

                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Box>
                                                        <Box className={classes.selectPopoverContainer}>
                                                            <CustomStyledButton
                                                                data-test-id="saveInsuranceDetails"
                                                                className={classes.selectButtons}
                                                                style={{ textTransform: "capitalize" }}
                                                                onClick={()=>this.isMatchFound(this.state.insurancesList)}
                                                                label="Save"
                                                            />
                                                             
                                                        </Box>
                                                    </Box>)}
                                                    {this.state.isDepartment&& (<Box className={classes.listContainer}>
                                                        {this.showSuccessMessage(this.state.successMessage, classes.uploadInputSuccess)}
                                                        {this.showSuccessMessage(this.state.errorMessage, classes.uploadInputError)}
                                                        <Box className={classes.selectInsurance}>
                                                            <TextField
                                                                data-test-id="searchDepartments"
                                                                variant="outlined"
                                                                placeholder="Search departments here.."
                                                                className={classes.insuranceSearchField}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <img
                                                                                src={search_Icon}
                                                                                className={classes.searchIcon}
                                                                            />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                value={this.state.search}
                                                                onChange={(event) => this.handleSearch(event.target.value.trimStart())}
                                                            />
                                                        </Box>
                                                        <Box className={classes.tablePopoverContainer}>
                                                            <TableContainer className={classes.tablecontainer}>
                                                                <Table>
                                                                    <TableBody>
                                                                        {this.state.departmentsList?.filter((department: any) => department.attributes.name.toLowerCase().includes(this.state.search.toLowerCase())).map((department: { id: string, attributes: { name: string }; }, index: number) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell style={this.getRowStyles(index, false)}>
                                                                                    <Checkbox
                                                                                        data-test-id={`selectDepartment${index}`}
                                                                                        className={classes.hospitalCheckbox}
                                                                                        checked={this.state.departments?.includes(parseInt(department.id))} 
                                                                                        onChange={() => this.handleDepartmentCheckboxChange(parseInt(department.id))}
                                                                                        icon={<CustomCheckboxIconUnCheckeds />}
                                                                                        checkedIcon={<CustomCheckboxIconCheckeds />}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell style={this.getSelectedField(index, this.state.departments.includes(parseInt(department.id)))}>{department?.attributes?.name}</TableCell>
                                                                            </TableRow>
                                                                        ))}

                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Box>
                                                        <Box className={classes.selectPopoverContainer}>
                                                            <CustomStyledButton
                                                                data-test-id="saveDepartements"
                                                                className={classes.selectButtons}
                                                                style={{ textTransform: "capitalize" }}
                                                                onClick={()=>this.isMatchFound(this.state.departmentsList)}
                                                                label="Save"
                                                            />
                                                        </Box>
                                                    </Box>
                                                    )}
                                            </Grid>
                                            <Grid item xl={6} md={6} sm={12} xs={12}>
                                            <Box className={classes.buttonContainer}>
                                                        <Box className={classes.hospitalContainer}>
                                                            <CustomStyledButton
                                                                data-test-id="availableDepart"
                                                                className={this.state.isDepartment? classes.hospitalDepartmentButtons:classes.hospitalInsuranceButtons}
                                                                onClick={this.openAvailableDepartmentView}
                                                                label="Available Departments"
                                                            />
                                                                
                                                            
                                                        </Box>
                                                        <Box className={classes.hospitalContainer}>
                                                            <CustomStyledButton
                                                                data-test-id="availableInsurance"
                                                                className={!this.state.isDepartment? classes.hospitalDepartmentButtons:classes.hospitalInsuranceButtons}
                                                                onClick={this.openInsuranceView}
                                                                label="Available Insurance Scheme"
                                                            />
                                                        </Box>
                                                    </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box >
                                <Loader loading={this.state.isLoading}/>
                                </HospitalProfileWrapper>
                                <CommonMessage 
                                    data-test-id="message"
                                    onClose={this.handleCloseMessage}
                                    open={this.state.openMessage}
                                    type={this.state.messageType} 
                                    title={this.state.messageTitle}
                                    subTitle={this.state.messageSubTitle}
                                />
                            </Box>
                        </SidebarNotificationWrapper>
                    </Box>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(CustomisableViewHospitalProfile);

const webStyle = {
    sidebarWrapper: {
        width: '132px',
        minHeight: 'max(100vh, 700px)',
    },
    container: {
        height: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: "100vh",
        overflowX: "hidden" as const,
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%',
    },
    contentWrapper: {
        padding: '94px 60px 72px 60px',
        maxWidth: '55%',
    },
}
// Customizable Area End