import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, Grid, ThemeProvider, createTheme, Button, CardContent, Dialog, Card } from "@material-ui/core";;
import CustomisableUserProfilesController, {
    Props
} from "./CustomisableUserProfilesController";
import { styles } from "./styles/CustomisableUserProfiles";
import { ProfileIcon, successLogo} from "./assets";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import StarIcon from '@material-ui/icons/Star';
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";
import CommonMessage from "../../../components/src/CommonMessage";
import CustomStyledButton from "../../../components/src/CustomStyledButton.web";
import { getStorageData } from "../../../framework/src/Utilities";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

const reviewers = [
    {
      image: ProfileIcon,
      name: 'Ainara Vergara',
      description: 'Conan Matusav is a Registered Charity under the Charities Acts.',
      rating: 4.2,
    },
    {
        image: ProfileIcon,
        name: 'Ainara Vergara',
        description: 'Conan Matusav is a Registered Charity under the Charities Acts.',
        rating: 4.2,
      },
  ];
// Customizable Area End

export class CustomisableHospitalManagementViewUserProfile extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        const userType = await getStorageData('userType');
        const userId  = await getStorageData("user_id");
        this.setState({userType,userId})
        this.getHpProfileData()
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const profileData = this.state.profileData?.attributes || {};
        const name = profileData?.name || "-"
        const city = profileData?.address?.data.attributes?.city || "-";
        const onlineFee = profileData?.profile_type_specific_data?.online_consultation_fee || "-";
        const offlineFee = profileData?.price || "-";
        const email = profileData?.email || "-";
        const department = profileData?.profile_type_specific_data?.department?.name || "-";
        const gender = profileData?.gender || "-";
        const age = profileData?.age || "-";
        let degrees = "-";
        if ( profileData?.educational_degrees && profileData?.educational_degrees?.length > 0) {
            degrees = profileData.educational_degrees.map((degree:any) => degree.degree).join(', ');
        }
        const isAuthorizedToRemove = this.state.profileData?.attributes?.profile_type_specific_data?.hospital_id === Number(this.state.userId);
        const isHospital = this.state.userType === 'hospital';
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                
                        
                            <SidebarNotificationWrapper  {...this.props}>
                                <Box className={classes.rightWrapper}>
                                    <Box className={classes.root}>
                                    <Box className={classes.healthDetailsSubContainer}>
                                        <Box className={classes.managementHPBackButtons} data-test-id="backToHPDetails" onClick={this.backToHospitalListPage}>
                                            <ArrowBackIosIcon data-test-id="hpBackBtn"className={classes.arrowIcon} onClick={this.backToHospitalListPage}/>
                                        </Box>
                                        <Box>
                                            <p className={classes.profileHeading}>
                                                Profile
                                            </p>
                                        </Box>
                                    </Box>
                                        <div>
                                            
                                                <div className={classes.topProfileWrapper}>
                                                    <div style={{width:'100%'}}>
                                                        <Box className={classes.topHPDetailsLeftContainer}>
                                                            <Box className={classes.profileContainer}>
                                                                <img
                                                                    className={classes.avatar}
                                                                    src={this.state.profilePicture}
                                                                />
                                                            </Box>
                                                            <Box className={classes.profileDetailsContainer}>
                                                                <p className={classes.userName}>
                                                                    Dr. {name}
                                                                </p>
                                                                <p className={classes.userPhoneNumber}>
                                                                        {city}
                                                                </p>
                                                            </Box> 
                                                        </Box>
                                                        
                                                    </div>
                                                    <div style={{width:'100%'}}>
                                                        <Box className={classes.btnContainer}>
                                                            <Button className={classes.availableTimeSlotBtn} style={{
                                                                
                                                            }} onClick={this.goToViewAppointment}>
                                                                Available Time Slots
                                                            </Button>
                                                            {isHospital&& isAuthorizedToRemove&&<Button style={{
                                                                
                                                            }} className={classes.cancelHpBtn}
                                                            onClick={this.removeProfileConfirmation}>
                                                                Remove Profile
                                                            </Button>}
                                                        </Box>
                                                    </div>
                                                </div>
                                            
                                            <Box className={classes.boxHpBottomBorder} />
                                            <div>
                                                <Box>
                                                    <Box className={classes.subContainer}>
                                                        <p className={classes.userDetailsHPLabel}>
                                                            Degree
                                                        </p>
                                                        <p className={classes.userDetailsColon}>
                                                            :
                                                        </p>
                                                        <p className={classes.userDetails}>
                                                            {degrees}
                                                        </p>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <p className={classes.userDetailsHPLabel}>
                                                            Age
                                                        </p>
                                                        <p className={classes.userDetailsColon}>
                                                            :
                                                        </p>
                                                        <p className={classes.userDetails}>
                                                            {age} y/o
                                                        </p>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <p className={classes.userDetailsHPLabel}>
                                                            Gender
                                                        </p>
                                                        <p className={classes.userDetailsColon}>
                                                            :
                                                        </p>
                                                        <p className={classes.userDetailsCaps}>
                                                            {gender}
                                                        </p>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <p className={classes.userDetailsHPLabel}>
                                                            Department
                                                        </p>
                                                        <p className={classes.userDetailsColon}>
                                                            :
                                                        </p>
                                                        <p className={classes.userDetails}>
                                                            {department}
                                                        </p>
                                                    </Box>
                                                    <Box className={classes.priceSubConatiner}>
                                                        <p className={classes.userDetailsHPLabel}>
                                                            Fee
                                                        </p>
                                                        <p className={classes.userDetailsColon}>
                                                            :
                                                        </p>
                                                        <p className={classes.userDetailsCaps}>
                                                            ₹{onlineFee}/ online consulataion
                                                        </p>
                                                    </Box>
                                                    <Box className={classes.subOfflineContainer}>
                                                        <p className={classes.userDetailsHPLabel}>
                                                            
                                                        </p>
                                                        <p className={classes.userDetailsColon}>
                                                        </p>
                                                        <p className={classes.userDetailsCaps}>
                                                        ₹{offlineFee}/ offline consulataion
                                                        </p>
                                                    </Box>
                                                    <Box className={classes.addressSubConatiner}>
                                                        <p className={classes.userDetailsHPLabel}>
                                                            Email Address
                                                        </p>
                                                        <p className={classes.userDetailsColon}>
                                                            :
                                                        </p>
                                                        <p className={classes.userDetailsCaps}>
                                                            {email}
                                                        </p>
                                                    </Box>
                                                </Box>
                                            </div>
                                        </div>
                                        <Box className={classes.nurseProfileDivider}></Box>
                                    <Box className={classes.reviewContainer}>
                                        <Box className={classes.reviewHeader}>
                                            <p className={classes.reviewTitle}>
                                                Reviews
                                            </p>
                                            <p className={classes.seeAllReview}>
                                                See All (402)
                                            </p>
                                        </Box>
                                        {reviewers?.length > 0 ? reviewers.map((user: any, index: string | number) => (
                                            <Box>
                                                <Box data-test-id="reviewerInfoCardDiv" key={index} className={classes.reviewerFlexBox}>
                                                    <img className={classes.reviewerCover} src={user.image} title={user.name} />
                                                    <CardContent className={classes.reviewerContent}>
                                                        <div>
                                                            <p className={classes.reviewerName} >
                                                                {user.name}
                                                            </p>
                                                            <p className={classes.reviewerDescription} >
                                                                {user.description}
                                                            </p>
                                                        </div>
                                                        <div className={classes.reviewerOverallRating}>
                                                            <StarIcon />
                                                            <Typography variant="body2" style={{ marginLeft: '4px',whiteSpace:'nowrap' }}>
                                                                <span className={classes.reviewerRating}>{user.rating || "4.2"}</span><span className={classes.reviewerRatingOutOf}> / 5 </span>
                                                            </Typography>
                                                        </div>
                                                    </CardContent>
                                                </Box>
                                                <Box className={classes.reviewDivider}></Box>
                                            </Box>
                                        )) : (
                                            <Box id="noReviewsFound" className={classes.noReviewsFound}>No reviewers data available.</Box>
                                        )}
                                    </Box>
                                    </Box>
                                </Box>
                            </SidebarNotificationWrapper>
                        
                    <Dialog open={this.state.removeProfileConfirmation} className={classes.removeProfileDialog}>
                        <Box className={classes.txtContainer}>
                            <p className={classes.removeProfileText}>
                                Are you sure you want to remove this profile?
                            </p>
                            <Box className={classes.removeBtnContainer}>
                                <CustomStyledButton className={classes.removeProfileBtn} onClick={this.removeProfileConfirmation} label="No" isSecondary />
                                <CustomStyledButton className={classes.cancelRemoveProfileBtn} onClick={this.removeHpFromHospital} label="Yes"/>
                            </Box>
                        </Box>
                    </Dialog>
                    <CommonMessage 
                        open={this.state.openMessage} 
                        type={this.state.messageType} 
                        onClose={this.handleCloseMessage} 
                        title={this.state.messageTitle} 
                        subTitle={this.state.messageSubTitle} 
                        buttonLabel="Okay"
                    />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(CustomisableHospitalManagementViewUserProfile);
// Customizable Area End