import React from 'react';
import { Button, styled } from "@material-ui/core"

interface IButton {
    label?: React.ReactNode|string;
    disabled?: boolean;
    onClick?: () => void;
    isSecondary?: boolean;
    style?: React.CSSProperties | undefined;
    className?:string;
}

const CustomStyledButton = (props: IButton) => {
    const { isSecondary, label, disabled, onClick, style,className } = props;
    return isSecondary ? <StyledSecondaryButton className={className} style={style} data-test-id={"primary"} disabled={disabled} onClick={onClick}>{label}</StyledSecondaryButton> :
        <StyledPrimaryButton className={className} style={style} data-test-id={"secondary"} disabled={disabled} onClick={onClick} >{label}</StyledPrimaryButton>
}

export default CustomStyledButton;

const StyledPrimaryButton = styled(Button)({
    backgroundColor: '#014866',
    color: 'white',
    textTransform: "capitalize",
    fontFamily: 'SF Pro Text Bold',
    height: '56px',
    fontWeight: 400,
    padding: '16px',
    borderRadius: '8px',
    fontSize: '16px',
    '&:hover': {
        backgroundColor: '#014866'
    },
    '&.MuiButton-root.Mui-disabled': {
        backgroundColor: "#0148667a",
        color: 'white',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        cursor:'not-allowed',
    },
    "@media(max-width:800px)": {
        fontSize: '14px',
    },
    "@media(max-width:600px)": {
        padding:'8px',
        height:'auto'
    }
})

const StyledSecondaryButton = styled(Button)({
    backgroundColor: 'white',
    color: '#014866',
    textTransform: "capitalize",
    height: '56px',
    fontWeight: 400,
    padding: '16px',
    fontFamily: 'SF Pro Text Bold',
    borderRadius: '8px',
    fontSize: '16px',
    border: '1px solid #014866',
    '&:hover': {
        backgroundColor: 'white'
    },
    '&.MuiButton-root.Mui-disabled': {
        backgroundColor: "white",
        color: '#0148667a',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        cursor:'not-allowed',
        border:'1px solid #0148667a',
    },
    "@media(max-width:800px)": {
        fontSize: '14px',
    },
    "@media(max-width:600px)": {
        padding:'8px 16px',
        height:'auto'
    }
})